//accueil.tsx

import React, {useEffect, useRef, useState} from "react";
import Contact from "../components/contact";

// imgs
import about from "../assets/images/about.jpg";
import sst from "../assets/images/sst.png";
import tarif from "../assets/images/tarif.png";
import facil from "../assets/images/facil.png";
import fich1 from "../assets/images/fich1.png";
import fich2 from "../assets/images/fich2.png";
import fich3 from "../assets/images/fich3.png";
import fich4 from "../assets/images/fich4.png";
import formule from "../assets/images/formule.png";
import Logo from "../assets/images/logo.png";
import LogoAT from "../assets/images/logoAT.png";
import Icone1 from "../assets/icons/iconea1.png";
import Icone2 from "../assets/icons/iconea2.png";
import Icone3 from "../assets/icons/iconea3.png";
import Icone4 from "../assets/icons/iconea4.png";

// icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ClientCaroussel from "../components/caroussels/clientCaroussel";
import PartCaroussel from "../components/caroussels/partCaroussel";
import SentencesCaroussel from "../components/caroussels/sentencesCaroussel";
import CookieConsent from "react-cookie-consent";

import "../assets/css/accueil/accueil.css";
import "../assets/css/accueil/accueilAboutUs.css";
import "../assets/css/accueil/accueilSection3.css";
import "../assets/css/accueil/accueilVideo.css";
import "../assets/css/accueil/accueilNavbar.css";
import "../assets/css/accueil/accueilFooter.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useMarqueBlanche } from "../context/MarqueBlanche";
import INRSNews from "./home/INRSNews";
import CloseIcon from "@mui/icons-material/Close"
import PlayCircle from "@mui/icons-material/PlayCircle"
import IconButton from "@mui/material/IconButton";

/**
 * Cette constante correspond à la page d'accueil en tant que script
 *
 * @returns les différentes sections de la page d'accueil
 */
const Home = () => {
  const [playVideo, setPlayVideo] = React.useState(false);

  /**
   * Cette méthode affiche et active la vidéo de la page d'accueil
   */
  const handlePlayVideo = () => {
    setPlayVideo(true);
  };

 


  const {isMarqueBlanche, title, navBarColor, logoHomePath} = useMarqueBlanche();






  return (
    <div>
      {/* <div className="App">
      <div className="Modal">
        <div className="ModalContent">
        <p>VOTRE SECURITE = NOTRE PRIORITE</p>
          <p>Nos services ont détecté sur nos serveurs une activité suspecte.</p>
          <p></p>
          <p>Afin d'assurer en priorité la sécurité des données de nos clients,</p>
          <p>l'accès à l'application Securiz est suspendu le temps que la menace soit contenue.</p>
        </div>
      </div>
    </div> */}
      {/* Navbar */}
      <nav className="navbarHome" style={{ backgroundColor: navBarColor }}>
        <ul>
          <li>
            <a href="#section-1">
              <img src={logoHomePath} alt="Logo" />
            </a>
          </li>
          <li>{!isMarqueBlanche && <a href="#section-1">Accueil</a>}</li>
          <li>{!isMarqueBlanche && <a href="#section-2">A Propos</a>}</li>
          <li>{!isMarqueBlanche && <a href="#section-3">Nos Plus</a>}</li>
          <li>{!isMarqueBlanche && <a href="#section-4">Solution</a>}</li>
          <li>{!isMarqueBlanche && <a href="#section-7">Actualités</a>}</li>
          <li>{!isMarqueBlanche && <a href="#section-5">Contact</a>}</li>
          <li>
            <button
              className="loginButton"
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              SE CONNECTER
            </button>
          </li>
        </ul>
      </nav>

      {playVideo && (
        <div className="modalVideo">
          <div className="videoContainer">
            <div className="videoInner">
              <div className="videoWrap">
                <button
                  className="closeVideo"
                  onClick={() => setPlayVideo(false)}
                >
                  <CloseIcon style={{ color: "white", fontSize: "50px" }} />
                </button>
                <iframe
                  width="100%"
                  src="//www.youtube.com/embed/bS5v_KXnPMc?autoplay=1&amp;cc_load_policy=1&amp;controls=1&amp;disablekb=0&amp;enablejsapi=0&amp;fs=1&amp;iv_load_policy=1&amp;loop=0&amp;rel=0&amp;showinfo=1&amp;start=0&amp;wmode=transparent&amp;theme=dark&amp;mute=0"
                  allowFullScreen
                  title="video"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )} *

      {/* Section 1 */}
      <section id="section-1" className="section1">
        <h2 style={{ fontSize: "50px" , textAlign:"center", fontWeight:"bold"}}>{title}</h2>
          <div className="subtitle" style={{minWidth:"fit-content", padding:"10px", fontSize:"12px"}}>
              <h3>Votre DUERP* en quelques clics</h3>
              <span style={{color: "white", fontStyle: "italic", textAlign: "start"}}>*Document Unique d’Evaluation des Risques Professionnels</span>
          </div>
          <SentencesCaroussel/>
          <IconButton onClick={() => setPlayVideo(true)}>
<PlayCircle style={{color:"white", width:"60px", height:"60px" }} />
          </IconButton>
          <span style={{color:"white", fontSize:"20px"}}>Notre vidéo</span>
      </section>
        {!isMarqueBlanche && <PartCaroussel/>}
        <CookieConsent
            location="bottom"
            buttonText="J'accepte"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Nous collectons des statistiques anonymes sur les pages consultées sur
        ce site. <span style={{ fontSize: "10px" }}></span>
      </CookieConsent>

      {/* Section 2 */}
      {!isMarqueBlanche && (
        <section id="section-2" className="section2">
          <div className="sectionAboutUs">
            <div className="sectionAboutUsTitre">
              <h1>SECURIZ ®</h1>
            </div>
            <div className="sectionAboutUsSousTitre">
              La Gestion de vos Risques Professionnels par
            </div>
            <img
              src={LogoAT}
              className="sectionAboutUsSousTitreLogo"
              alt="Logo"
            />
            <div className="sectionAboutUsTableau">
              <img
                src={about}
                alt="aboutUs"
                className="sectionAboutUsTableauImg"
              />
              <table className="sectionAboutUsTableTextes">
                <tbody>
                  <tr>
                    <th className="sectionAboutUsTableTitres">
                      <img src={Icone1}></img>
                      <div>Nos atouts</div>
                    </th>
                    <th className="sectionAboutUsTableTitres">
                      <img src={Icone2}></img>
                      <div>Nos valeurs</div>
                    </th>
                  </tr>
                  <tr>
                    <td className="sectionAboutUsTableTextes">
                      Notre équipe basée dans le Sud-Ouest de la France
                    </td>
                    <td className="sectionAboutUsTableTextes">
                      Travail d'équipe, humilité, simplicité
                    </td>
                  </tr>
                  <tr>
                    <td className="sectionAboutUsTableTextes">
                      Son expérience et ses compétences IPRP enregistrées à la
                      DREETS.
                    </td>
                    <td className="sectionAboutUsTableTextes">
                      Proximité, pragmatisme, efficacité
                    </td>
                  </tr>
                  <tr>
                    <th className="sectionAboutUsTableTitres">
                      <img src={Icone3}></img>
                      <div>Notre cible</div>
                    </th>
                    <th className="sectionAboutUsTableTitres">
                      <img src={Icone4}></img>
                      <div>Des prix attractifs</div>
                    </th>
                  </tr>
                  <tr>
                    <td className="sectionAboutUsTableTextes">
                      Les employeurs et les prescripteurs.
                    </td>
                    <td className="sectionAboutUsTableTextes">
                      Pour faire de la santé et sécurité au travail une des
                      priorités dans les entreprises.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}

      {/* Section 3 */}
      {!isMarqueBlanche && (
        <section id="section-3" className="section3">
          <div className="box3">
            <h2 style={{ alignSelf: "center" }}>Nos Plus</h2>
            <div className="line"></div>
            <div className="box3-content">
              <div className="nos-plus">
                <div className="nos-plus-items">
                  <p>
                    <b>La SST* en un coup d’œil</b>
                  </p>
                  <img src={sst} alt="plus" />
                  <p>* Santé et Sécurité au Travail.</p>
                </div>
                <div className="nos-plus-items">
                  <img src={tarif} alt="tarif" />
                </div>
                <div className="nos-plus-items">
                  <p>
                    <b>Facilité de Mise a jour du Document Unique</b>
                  </p>
                  <img src={facil} alt="facil" />
                </div>
              </div>
              <p>
                <b>
                  Des Affichages Réglementaires aux couleurs de l'Entreprise
                  compris dans la Formule !
                </b>
              </p>
            </div>
          </div>
        </section>
      )}

      {/* Section 4 */}
      {!isMarqueBlanche && (
        <section id="section-4" className="section4">
          <h1>Notre Formule</h1>
          <div className="line"></div>
          <a href="#section-5" style={{ textAlign: "center" }}>
            <img src={formule} alt="formule" />
          </a>
        </section>
      )}

      {!isMarqueBlanche && <ClientCaroussel />}

      <section id="section-7" className="section7">
        <h1>Actualités INRS</h1>
        <INRSNews />
      </section>
      {/* Section 5 */}
      {!isMarqueBlanche && (
        <section id="section-5" className="section5">
          <h1 style={{ textAlign: "center" }}>Contact</h1>
          <div className="line"></div>
          <Contact />
        </section>
      )}

      {/* Footer */}
      {!isMarqueBlanche && (
        <footer className="footer">
          <div className="footerLogo">
            <img src={Logo} alt="Logo" />
            <p>Ⓒ 2023 , AT Consulting, Tous droits réservés</p>
          </div>
          <div className="footerRight">
            <div className="footerLinks">
              <a href="./mentions-legales">Mentions légales</a>
              <a href="./confidentialite">Confidentialité</a>
              <a href="./cgvu">CGVU</a>
            </div>
            <div className="footerIcons">
              <a href="https://www.linkedin.com/company/at-consulting-fr/">
                <LinkedInIcon style={{ color: "#fff", fontSize: "35px" }} />
              </a>
              <a href="https://www.facebook.com/ATConsulting.fr">
                <FacebookIcon style={{ color: "#fff", fontSize: "35px" }} />
              </a>
              <a href="https://www.youtube.com/@securiz4478">
                <YouTubeIcon style={{ color: "#fff", fontSize: "35px" }} />
              </a>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Home;
