import "../assets/css/planAction.css";
import ReactDOMServer from "react-dom/server";
import image25 from "../assets/icons/image25.png";
import image26 from "../assets/icons/image26.png";
import televerser from "../assets/icons/televerser2.png";
import "../assets/css/affichageDU.css";
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  CSSProperties,
} from "react";
import { format } from "date-fns";
import { useMutation, useQuery } from "@apollo/client";
import "../assets/css/planAction.css";
import groupBy from "lodash/groupBy";

import {
  LINKS_PLANACTION_MUTATION,
  PLAN_ACTION_UPDATE_DATE,
} from "../queries/planAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Paper,
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print"; // Import the Print icon component
import useAnalyticsEventTracker from "./analytics";

import LoadingComponent from "./Loading";
import { LINKS_ETABLISSMENT_MUTATION } from "../queries/uniteTravail";
import ErrorComponent from "./ErrorComponent";

import { reformatDatas } from "../utils/formatDatas";

import A4PagePaysage from "./A4PagePaysage";
import { GET_USER } from "../queries/user";
import { useMarqueBlanche } from "../context/MarqueBlanche";
import API_URL from "../utils/api";
import RisqueCalcul from "../utils/RisqueCalcul";

function filterUniqueRows(rows: any[], priorites: { [key: string]: number }) {
  const uniqueRows: { [key: string]: any } = {};
  for (let row of rows) {
    if (!uniqueRows[row.uniteDeTravail + row.risque + row.action]) {
      uniqueRows[row.uniteDeTravail + row.risque + row.action] = row;
      continue;
    }
    const currentRow = uniqueRows[row.uniteDeTravail + row.risque + row.action];
    const currentRowPrio =
      priorites[currentRow.utID + currentRow.risqueID] ?? 4;
    const rowPrio = priorites[row.utID + row.risqueID] ?? 4;
    if (rowPrio < currentRowPrio) {
      uniqueRows[row.uniteDeTravail + row.risque + row.action] = row;
    }
  }
  return Object.values(uniqueRows);
}

const actionToRow = (
  action: any,
  index: number,
  priorites?: { [key: string]: number }
) => {
  const dateDouverture = new Date(action.dateouverture);

  const delai = RisqueCalcul.getEcheance(action, dateDouverture, priorites);
  return {
    utID: action.uniteTravail.id,
    risqueID: action.risque.id,
    mesureID: action.mesure.id,
    uniteDeTravail: action.uniteTravail.libelle,
    numero: index + 1,
    responsable: action.responsable || "",
    action: action.mesure.libelle,
    risque: action.risque.libelle,
    dateDouverture: dateDouverture.toISOString().slice(0, 10),
    delai: delai.toISOString().slice(0, 10),
    avancement: action.avancement,
    commentaire: action.comment || "",
    pieceJointe: action.pj,
    cloture: !action.isopen,
  };
};

function getUpdatesForMutation(
  actions: any,
  rows: { [key: string]: any }[],
  priorites: { [key: string]: number }
) {
  const sorted = [...actions].sort((a: any, b: any) =>
    (
      a.uniteTravail.libelle +
      a.risque.libelle +
      a.risque.description +
      a.mesure.libelle
    ).localeCompare(
      b.uniteTravail.libelle +
        b.risque.libelle +
        b.risque.description +
        b.mesure.libelle
    )
  );
  const oldRows: { [key: string]: any }[] = filterUniqueRows(
    sorted.map((action, index) => actionToRow(action, index)),
    priorites
  );
  const updates = [] as any[];
  for (let i = 0; i < rows.length; i++) {
    for (let key in rows[i]) {
      if (rows[i][key] === oldRows[i][key] || key === "delai") {
        continue;
      }
      console.log("changed", key);
      if (rows[i].cloture !== oldRows[i].cloture) {
        updates.push({
          ...rows[i],
          clotureChanged: true,
        });
      } else {
        updates.push(rows[i]);
      }
      break;
    }
  }

  return updates.map((row) => {
    const dateClotureValue = (
      row.clotureChanged ? new Date() : new Date(row.delai)
    ).toISOString();

    const delaiValue = row.delai; // Récupérer la valeur de "delai"

    console.log("Valeur de delai :", delaiValue); // Affiche la valeur dans la console du navigateur

    return {
      uniteTravailID: row.utID,
      uniteDeTravail: row.uniteDeTravail,
      risque: { id: row.risqueID, libelle: row.risque },
      mesure: { id: row.mesureID, libelle: row.action },
      dateouverture: new Date(row.dateDouverture).toISOString(),
      datecloture: dateClotureValue,
      avancement: row.avancement,
      isupdated: true,
      isopen: !row.cloture,
      responsable: row.responsable,
      comment: row.commentaire,
      clotureChanged: row.clotureChanged,
      pieceJointe: row.pieceJointe,
    };
  });
}

const PlanAction = ({
  actions,
  save,
  etablissementID,
  data,
}: {
  actions: any[];
  save?: any;
  etablissementID?: string;
  data: any;
}) => {
  const [logo, setLogo] = useState<any>("");
  const [raisonSociale, setRaisonSociale] = useState<any>("");
  const [isPrinting, setIsPrinting] = useState(false);
  const [editingCommentaireIndex, setEditingCommentaireIndex] = useState<number | null>(null);
  const inputRefs = useRef([]);
  useEffect(() => {
    if (editingCommentaireIndex !== null && inputRefs.current[editingCommentaireIndex]) {
      (inputRefs.current as any)[editingCommentaireIndex].focus();
    }
  }, [editingCommentaireIndex]);
  const { data: userData } = useQuery(GET_USER, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider."+process.env.REACT_APP_CGO_GRPUSR_CID+".LastAuthUser"
      ),
    },
  });

  const { logoPath } = useMarqueBlanche();
  useEffect(() => {
    if (userData) {
      if (userData?.utilisateur?.etablissements[0]) {
        console.log("ici", userData);
        setLogo(
          userData?.utilisateur?.etablissements[0]?.donnees?.infosAffichage
            ?.logo
        );
        setRaisonSociale(
          userData?.utilisateur?.etablissements[0]?.raisonsociale
        );
      }
    }
  }, [userData]);

  useEffect(() => {
    const beforePrint = () => {
      setIsPrinting(true);
    };

    const afterPrint = () => {
      setIsPrinting(false);
    };

    window.addEventListener("beforeprint", beforePrint);
    window.addEventListener("afterprint", afterPrint);

    return () => {
      window.removeEventListener("beforeprint", beforePrint);
      window.removeEventListener("afterprint", afterPrint);
    };
  }, []);
  const gaEventTracker = useAnalyticsEventTracker("Plan d'action");
  const [updateActions, updateActionState] = useMutation(
    LINKS_PLANACTION_MUTATION
  );
  const [updatePlanActionDate, updatePlanActionDateState] = useMutation(
    PLAN_ACTION_UPDATE_DATE
  );
  const [updateMesuresMutation] = useMutation(LINKS_ETABLISSMENT_MUTATION);
  const [readyRefs, setReadyRefs] = useState<boolean>(false);
  const [selectedAvancements, setSelectedAvancements] = useState<any>({});
  const [clotureValues, setClotureValues] = useState<any>({});

  useImperativeHandle(save, () => ({
    save: async () => {
      const sorted = [...actions].sort((a: any, b: any) =>
        (
          a.uniteTravail.libelle +
          a.risque.libelle +
          a.risque.description +
          a.mesure.libelle
        ).localeCompare(
          b.uniteTravail.libelle +
            b.risque.libelle +
            b.risque.description +
            b.mesure.libelle
        )
      );
      const allRows: { [key: string]: any }[] = sorted.map((action, index) =>
        actionToRow(action, index)
      );
      console.log("allRows", allRows);
      const updates = getUpdatesForMutation(
        actions,
        rows.current,
        RisqueCalcul.getPriorites(reformatDatas(data))
      );
      console.log("updates", updates);
      if (updates.length === 0) return;
      console.log("wait");
      await updatePlanActionDate({
        variables: {
          etablissementID: etablissementID,
          dateedition: new Date().toISOString(),
        },
      });
      const linksMesuresAdd = updates
        .filter((update: any) => update.clotureChanged)
        .reduce((acc: any, cur: any) => {
          const filtered = allRows.filter(
            (row: any) =>
              row.risque === cur.risque.libelle &&
              row.uniteDeTravail === cur.uniteDeTravail &&
              row.action === cur.mesure.libelle
          );
          return [
            ...acc,
            ...filtered.map((row: any) => ({ ...row, comment: cur.comment })),
          ];
        }, [])
        .map((update: any) => {
          let modelRisque =
            data.utilisateur.etablissements[0].metier.unitesTravail
              .find((ut: any) => ut.id === update.utID)
              ?.risques.find((risque: any) => risque.id === update.risqueID);
          let modelMesure = modelRisque?.mesures.find(
            (mesure: any) => mesure.id === update.mesureID
          );
          return {
            uniteTravailID: update.utID,
            risque: {
              id: update.risqueID,
              gravite: modelRisque?.gravite,
              frequence: modelRisque?.frequence,
            },
            mesure: {
              id: update.mesureID,
              specificite: update.comment,
              ponderation: modelMesure?.ponderation,
            },
          };
        });
      console.log("linksMesureAdd", linksMesuresAdd, updates);
      await updateMesuresMutation({
        variables: {
          id: etablissementID,
          linksAdd: linksMesuresAdd,
          linksDelete: [],
        },
      });
      await updateActions({
        variables: {
          etablissementID: etablissementID,
          linksAdd: updates
            .reduce((acc: any, cur: any) => {
              const filtered = allRows.filter(
                (row: any) =>
                  row.risque === cur.risque.libelle &&
                  row.uniteDeTravail === cur.uniteDeTravail &&
                  row.action === cur.mesure.libelle
              );
              return [
                ...acc,
                ...filtered.map((row: any) => ({
                  uniteTravailID: row.utID,
                  uniteDeTravail: row.uniteDeTravail,
                  risque: {
                    id: row.risqueID,
                    libelle: row.risque,
                  },
                  mesure: {
                    id: row.mesureID,
                    libelle: row.action,
                  },
                  dateouverture: row.dateDouverture,
                  datecloture: row.delai,
                  avancement: cur.avancement,
                  isupdated: cur.isupdated,
                  isopen: cur.isopen,
                  responsable: cur.responsable,
                  comment: cur.comment,
                  clotureChanged: undefined,
                  pieceJointe: cur.pieceJointe,
                })),
              ];
            }, [])
            .map((update: any) => ({
              ...update,
              clotureChanged: undefined,
              pieceJointe: undefined,
              uniteDeTravail: undefined,
              risque: { id: update.risque.id },
              mesure: { id: update.mesure.id },
              pj: update.pieceJointe,
            })),
          linksDelete: [],
        },
      });
    },
  }));
  const rows = useRef<any[]>([]);
  const [, setCloture] = React.useState(false);
  //const [sorted, setSorted] = React.useState(actions);

  useEffect(() => {
    // Mettez à jour les données dans la référence useRef
    var sorted = [...actions].sort((a: any, b: any) =>
      (
        a.uniteTravail.libelle +
        a.risque.libelle +
        a.risque.description +
        a.mesure.libelle
      ).localeCompare(
        b.uniteTravail.libelle +
          b.risque.libelle +
          b.risque.description +
          b.mesure.libelle
      )
    );
    let formattedDatas = reformatDatas(data);
    console.log("reformatted datas = ", formattedDatas);
    const priorites = RisqueCalcul.getPriorites(formattedDatas);
    console.log("prios", priorites);

    // Mettez à jour la valeur actuelle de la référence useRef
    rows.current = filterUniqueRows(
      sorted.map((action, index) => actionToRow(action, index, priorites)),
      priorites
    );
    rows.current = filterUniqueRows(
      sorted.map((action, index) => actionToRow(action, index, priorites)),
      priorites
    );
    setSelectedAvancements((prevState: any) => {
      const newSelectedAvancements = { ...prevState };
      setClotureValues((prevClotureValues: any) => {
        const newClotureValues: any = { ...prevClotureValues };
        rows.current.forEach((row, index) => {
          newSelectedAvancements[index] = row.avancement;
          newClotureValues[index] = row.cloture;
        });
        return newClotureValues;
      });
      return newSelectedAvancements;
    });
    setReadyRefs(true);
  }, [actions]);

  // refresh the displayed rows when the rows change
  useEffect(() => {
    setCloture(
      rows.current.every((row) => row.cloture) ||
        rows.current.every((row) => !row.cloture)
    );
  }, [readyRefs]);

  if (updateActionState.loading) return <LoadingComponent />;
  if (updateActionState.error)
    return <ErrorComponent error={updateActionState.error} />;

  const handleSelectChange = (
    event: { target: { value: any } },
    index: number,
    key: string | number
  ) => {
    const newRows: any = [...rows.current];
    newRows[index][key] = event.target.value;
    rows.current = newRows;
  };

  const handleFileUpload = (
    event: { target: { files: any[] } },
    index: number
  ) => {
    const newRows = [...rows.current];
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    if (file) {
      fetch(
        `${API_URL}/file/upload/${etablissementID}/${newRows[index].utID}/${newRows[index].risqueID}/${newRows[index].mesureID}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(() => {
          newRows[index].pieceJointe = file.name;
          gaEventTracker("Téléversement fichier");
          rows.current = newRows;
        })
        .catch((error) => {
          console.error(error);
        });
      // Charger le fichier sur un serveur ou stocker localement selon votre besoin
    }
  };

  const handleFileDownload = (row: any) => async () => {
    let link = `${API_URL}/file/download/${etablissementID}/${row.utID}/${row.risqueID}/${row.mesureID}`;
    const response = await fetch(link, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = row.pieceJointe;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const handleCloture = (index: number) => {
    gaEventTracker("Cloture action");
    const newRows = [...rows.current];
    newRows[index].cloture = !newRows[index].cloture;

    // Rechercher les lignes en doublon avec la même unité de travail et la même action
    const currentRow = newRows[index];
    const duplicateRows = newRows.filter(
      (row) =>
        row.uniteDeTravail === currentRow.uniteDeTravail &&
        row.action === currentRow.action &&
        row !== currentRow
    );

    // Clôturer les lignes en doublon
    duplicateRows.forEach((row) => {
      row.cloture = !row.cloture;
    });

    rows.current = newRows;

    // Mettre à jour l'état local clotureValues
    setClotureValues((prevClotureValues: any) => ({
      ...prevClotureValues,
      [index]: newRows[index].cloture,
    }));
  };

  const handlePrint = () => {
    const style: CSSProperties = {
      padding: "10px 5px",
      textAlign: "center",
      border: "2px solid black",
    };
    const printWindow = window.open("", "_blank");
    const currentDate = new Date(); // Get the current date
    const formattedDate = currentDate.toLocaleDateString(); // Format the date as needed

    const printDocument = (
      <div className="print-only">
        <style>
          {`
    @media print {
      @page {
        size: A4 landscape; /* Format A4 paysage */
        margin: 0;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        margin-left: 15px !important;
      }  

      .print-page {
        page-break-inside: avoid;
      }
      #tableau {
        margin-top: 0 !important;
      }

      .print-only {
        display: block !important;
      }
    }
  `}
        </style>
        <A4PagePaysage>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100%",
              padding: "20mm",
              fontFamily: "Arial",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "160px",
                }}
              >
                {logo && (
                  <img
                    src={logo}
                    alt="Top Left Logo"
                    style={{
                      width: "150px",
                      height: "150px"!,
                      objectFit: "contain",
                    }}
                  />
                )}
                <img
                  src={logoPath}
                  alt="Top Right Logo"
                  style={{
                    width: "150px",
                    height: "150px"!,
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            <div>
              <h1
                style={{
                  fontSize: "24pt",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                PLAN D'ACTIONS DUERP
              </h1>
              <h2 style={{ fontSize: "18pt", textAlign: "center" }}>
                {raisonSociale
                  ? raisonSociale
                  : "Erreur lors du chargement de la raison sociale"}
              </h2>
              <p style={{ fontSize: "10pt", textAlign: "center" }}>
                Date d'édition {formattedDate}
              </p>
            </div>
            <p style={{ fontSize: "8pt", textAlign: "center" }}>
              Tous droits réservés AT Consulting 2023
            </p>
          </div>
        </A4PagePaysage>
        <div className="table-page" style={{ padding: "30px", width: "297mm" }}>
          <TableContainer component={Paper}>
            <Table style={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "5px solid black",
                  }}
                >
                  <TableCell style={style} align="center">
                    Unité de travail
                  </TableCell>
                  <TableCell style={style} align="center">
                    Risque
                  </TableCell>
                  <TableCell style={style} align="center">
                    Responsable
                  </TableCell>
                  <TableCell style={style} align="center">
                    Action
                  </TableCell>
                  <TableCell style={style} align="center">
                    Echéance
                  </TableCell>
                  <TableCell style={style} align="center">
                    Avancement
                  </TableCell>
                  <TableCell style={style} align="center">
                    Commentaire
                  </TableCell>
                  <TableCell style={style} align="center">
                    Pièce Jointe
                  </TableCell>
                  <TableCell style={style} align="center">
                    Clos
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(groupBy(rows.current, "uniteDeTravail")).map(
                  ([a, risques]: [string, any], index: number) => (
                    <>
                      {Object.entries(groupBy(risques, "risque")).map(
                        ([b, restOfRows]: [string, any], unitIndex: number) => (
                          <>
                            {restOfRows.map((row: any, risqueIndex: number) => (
                              <TableRow
                                style={{ borderTop: "2px solid black" }}
                                key={
                                  index * 10000 + unitIndex * 100 + risqueIndex
                                }
                              >
                                {unitIndex === 0 && risqueIndex === 0 && (
                                  <TableCell
                                    rowSpan={risques.length}
                                    style={style}
                                  >
                                    <b>{row.uniteDeTravail}</b>
                                  </TableCell>
                                )}

                                {risqueIndex === 0 && (
                                  <TableCell
                                    rowSpan={restOfRows.length}
                                    style={style}
                                  >
                                    <b>{row.risque}</b>
                                  </TableCell>
                                )}
                                {[
                                  row.responsable,
                                  row.action,
                                  row.delai,
                                  row.avancement,
                                  row.commentaire,
                                  row.pieceJointe,
                                  row.cloture,
                                ].map((cell) => (
                                  <TableCell style={style}>
                                    <b>{cell}</b>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </>
                        )
                      )}
                    </>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );

    printWindow?.document.open();
    printWindow?.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              body {
                margin: 0; // Remove any default margin
              }
              .print-only {
                display: block !important;
              }
              .non-printable {
                display: none !important;
              }
            }
          </style>
        </head>
        <body>
          ${ReactDOMServer.renderToString(printDocument)}
        </body>
      </html>
    `);
    printWindow?.document.close();

    printWindow?.addEventListener("load", () => {
      printWindow?.print();
      printWindow?.close();
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handlePrint}
        style={{ marginBottom: "10px" }}
        startIcon={<PrintIcon />}
      >
        Imprimer
      </Button>

      <TableContainer style={{ maxHeight: "500px", overflow: "auto", position:"relative" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unité de travail</TableCell>
              <TableCell>Risque</TableCell>
              {/* <TableCell>Numéro</TableCell> */}
              <TableCell>Responsable</TableCell>
              <TableCell>Action</TableCell>

              <TableCell>Ouverture</TableCell>
              <TableCell>Echéance</TableCell>
              <TableCell>Avancement</TableCell>
              <TableCell>Commentaire</TableCell>
              <TableCell>Pièce-Jointe</TableCell>
              <TableCell>Cloture</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.current.map((row: any, index: number) => (
              <TableRow key={index} sx={row.cloture ? { opacity: 0.5 } : null}>
                <TableCell>
                  <b>{row.uniteDeTravail}</b>
                  {/* <TextField className="smallFontSize"
                    value={row.uniteDeTravail}
                    disabled={true}
                    style={{ fontSize:"0.5rem"}}
                    onChange={(e) => handleChange(e, index, "uniteDeTravail")}
                  /> */}
                </TableCell>
                <TableCell>
                  {row.risque}
                  {/* <TextField
                    value={row.risque}
                    disabled={true}
                    onChange={(e) => handleChange(e, index, "risque")}
                  /> */}
                </TableCell>
                {/* <TableCell>
                {row.numero}
                  <TextField
                    value={row.numero}
                    disabled={true}
                    onChange={(e) => handleChange(e, index, "numero")}
                  />
                </TableCell> */}
                <TableCell>
                  <TextField
                    defaultValue={row.responsable}
                    disabled={row.cloture}
                    onChange={(e) =>
                      (rows.current[index].responsable = e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  {row.action}
                  {/* <TextField
                    value={row.action}
                    disabled={true}
                    onChange={(e) => handleChange(e, index, "action")}
                  /> */}
                </TableCell>

                <TableCell>
                  {format(new Date(row.dateDouverture), "dd/MM/yyyy")}
                  {/* <TextField
                    type="date"
                    value={row.dateDouverture}
                    disabled={row.cloture}
                    onChange={(e) => handleChange(e, index, "dateDouverture")}
                  /> */}
                </TableCell>
                <TableCell>
                  {format(new Date(row.delai), "dd/MM/yyyy")}
                  {/* <TextField
                    type="date"
                    value={row.delai}
                    disabled={row.cloture}
                    onChange={(e) => handleChange(e, index, "delai")}
                  /> */}
                </TableCell>
                <TableCell>
                  <FormControl>
                    <InputLabel id={`avancement-label-${index}`}></InputLabel>
                    <Select
                      labelId={`avancement-label-${index}`}
                      id={`avancement-${index}`}
                      value={selectedAvancements[index] || row.avancement}
                      disabled={row.cloture}
                      onChange={(e) => {
                        handleSelectChange(e, index, "avancement");
                        setSelectedAvancements((prevState: any) => ({
                          ...prevState,
                          [index]: e.target.value,
                        }));
                      }}
                    >
                      <MenuItem value="En cours">En cours</MenuItem>
                      <MenuItem value="Terminé">Terminé</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField
                   inputRef={(el) => (inputRefs.current as any)[index] = el}
                    defaultValue={row.commentaire}
                    inputProps={{
                      maxLength: 80,
                    }}
                    multiline={editingCommentaireIndex === index}
                    disabled={row.cloture}
                    onChange={(e) =>
                      (rows.current[index].commentaire = e.target.value)
                    }
                    onFocus={() => setEditingCommentaireIndex(index)}
                    onBlur={() => setEditingCommentaireIndex(null)}
                    style={
                      editingCommentaireIndex === index
                        ? {
                            width: "600px",
                            zIndex: 2,
                            position: "absolute",
                            left: "50%",
                            backgroundColor: "#ffffff",
                            transform: "translateX(-50%) translateY(-50%)",
                          }
                        : {
                           
                          }
                    }
                  />
                </TableCell>
                <TableCell style={{}}>
                  <Button
                    style={{
                      width: "50%",
                      height: "50px",
                      backgroundColor: "#43425d00",
                      color: "white",
                      border: "0.5px solid #43425d",
                      borderRadius: "5px",
                      fontSize: "0.8rem",
                      cursor: "pointer",
                      boxShadow: "none",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#00000054";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "#43425d00";
                    }}
                    component="label"
                    variant="contained"
                    disabled={row.cloture}
                  >
                    <img
                      src={televerser}
                      alt="Televerser"
                      className="cloture_img"
                    />
                    <input
                      type="file"
                      accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                      hidden
                      onChange={(e: any) => handleFileUpload(e, index)}
                      style={{
                        backgroundColor: "#43425d00",
                      }}
                    />
                  </Button>
                  <div>
                    {row.pieceJointe && (
                      <span>
                        {" "}
                        <a
                          onClick={handleFileDownload(row)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            fontSize: "9px", // Taille de police de 9
                            color: "black", // Couleur du texte
                            textDecoration: "underline", // Souligner le lien
                            cursor: "pointer", // Changer le curseur au survol
                          }}
                        >
                          {row.pieceJointe}
                        </a>
                      </span>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  {row.avancement === "Terminé" && !clotureValues[index] && (
                    <IconButton onClick={() => handleCloture(index)}>
                      <img
                        src={image25}
                        className="cloture_img"
                        alt="cloture"
                      />
                    </IconButton>
                  )}
                  {clotureValues[index] && (
                    <IconButton
                      onClick={() => {
                        window.alert(
                          `Une action cloturée ne peut pas être réouverte`
                        );
                      }}
                    >
                      <img
                        src={image26}
                        className="cloture_img"
                        alt="cloture"
                      />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default PlanAction;
