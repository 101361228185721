
import Card from "../../components/card";
import LoadingComponent from "../../components/Loading";
import Navbar from "../../components/navbar";
import TopBar from "../../components/TopBar";
import useStats from "../../hooks/AdminDashBoard/useStats";


// define props interface
interface IAdminDashboardProps {
  username: string;
}

const AdminDashboard = ({ username }: IAdminDashboardProps) => {

  const {stats, loading} = useStats();


  if (loading) {
    return <LoadingComponent />;
  }

  const dividedMetiers = Object.entries(stats.metiers).reduce(
    (acc: any, metier: any, index: any) => {
      if (index % 3 === 0) {
        acc.push([]);
      }
      acc[acc.length - 1].push({
        name: metier[0],
        value: metier[1],
      });
      return acc;
    },
    []
  );

  return (
    <div>
      <div className="dashboard">
        <Navbar type="admin" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Tableau de bord</h1>
          <div className="pageLine"></div>
          {/* <p>Bonjour, {username} !</p> */}
          <div className="content content-cards">
            <Card title="Clients Actifs" value={stats.active} />
            <Card title="Clients Expirés" value={stats.expired} />
            <Card title="Grands Comptes" value={stats.grandscomptes} />
          </div>
          <div
            className="content-cards"
            style={{
              margin: "20px",
            }}
          >
            {dividedMetiers.map((metiers: any, index: number) => (
              <div className="content" key={index}>
                {metiers.map((metier: any, indexMetier: number) => (
                  <Card
                    title={metier.name}
                    value={metier.value}
                    key={indexMetier}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
