interface Datas {
  utilisateur: {
    etablissements: {
      id: string;
      donnees: {
        unitesTravail: {
          id: string;
          risques: {
            id: string;
            mesures: {
              id: string;
            }[];
          }[];
        }[];
        documentUnique: {
          planaction: {
            actions: {
              uniteTravail: {
                id: string;
              };
              risque: {
                id: string;
              };
              mesure: {
                id: string;
              };
              isopen: boolean;
            }[];
          };
        } | null;
      };
      metier: {
        unitesTravail: {
          id: string;
          risques: {
            id: string;
            mesures: {
              id: string;
            }[];
          }[];
        }[];
      };
    }[];
  };
}

function getNewActions(data: Datas) {
  var toDelete: any[] = [];
  var toAdd: any[] = [];

  const docUnique = data.utilisateur.etablissements[0].donnees.documentUnique;
  if (docUnique == null) {
    return toAdd;
  }
  for (let uniteTravail of data.utilisateur.etablissements[0].metier
    .unitesTravail) {
    let donneesUniteTravail =
      data.utilisateur.etablissements[0].donnees.unitesTravail.find(
        (uniteTravail2: any) => uniteTravail2.id === uniteTravail.id
      );
    if (donneesUniteTravail === undefined) {
      let foundActions = docUnique.planaction.actions.filter(
        (action: any) =>
          action.uniteTravail.id === uniteTravail.id && action.isopen === true
      );
      for (let action of foundActions) {
        toDelete.push({
          uniteTravailID: uniteTravail.id,
          risque: { id: action.risque.id },
          mesure: { id: action.mesure.id },
          dateouverture: new Date().toISOString(),
          datecloture: new Date().toISOString(),
          avancement: "En cours",
          isupdated: false,
          isopen: true,
        });
      }
      continue;
    }
    for (let risque of uniteTravail.risques) {
      let donneesRisque = donneesUniteTravail.risques.find(
        (risque2: any) => risque2.id === risque.id
      );
      if (donneesRisque === undefined) {
        let foundActions = docUnique.planaction.actions.filter(
          (action: any) =>
            action.uniteTravail.id === uniteTravail.id &&
            action.risque.id === risque.id &&
            action.isopen === true
        );
        for (let action of foundActions) {
          toDelete.push({
            uniteTravailID: uniteTravail.id,
            risque: { id: action.risque.id },
            mesure: { id: action.mesure.id },
            dateouverture: new Date().toISOString(),
            datecloture: new Date().toISOString(),
            avancement: "En cours",
            isupdated: false,
            isopen: true,
          });
        }
        continue;
      }
      for (let mesure of risque.mesures) {
        let donneesMesure = donneesRisque.mesures.find(
          (mesure2: any) => mesure2.id === mesure.id
        );
        if (donneesMesure !== undefined) {
          let foundAction = docUnique.planaction.actions.find(
            (action: any) =>
              action.uniteTravail.id === uniteTravail.id &&
              action.risque.id === risque.id &&
              action.mesure.id === mesure.id
          );
          if (foundAction !== undefined && foundAction.isopen === true) {
            toDelete.push({
              uniteTravailID: uniteTravail.id,
              risque: { id: risque.id },
              mesure: { id: mesure.id },
              dateouverture: new Date().toISOString(),
              datecloture: new Date().toISOString(),
              avancement: "En cours",
              isupdated: false,
              isopen: true,
            });
          }
          continue;
        }
        if (
          docUnique.planaction.actions.find(
            (action: any) =>
              action.uniteTravail.id === uniteTravail.id &&
              action.risque.id === risque.id &&
              action.mesure.id === mesure.id
          ) !== undefined
        ) {
          continue;
        }
        toAdd.push({
          uniteTravailID: uniteTravail.id,
          risque: { id: risque.id },
          mesure: { id: mesure.id },
          dateouverture: new Date().toISOString(),
          datecloture: new Date().toISOString(),
          avancement: "En cours",
          isupdated: false,
          isopen: true,
        });
      }
    }
  }
  return {
    linksAdd: toAdd,
    linksDelete: toDelete,
  };
}

export default async function muteActions(
  data: Datas,
  createDocumentUnique: any,
  linksMutation: any
) {
  if (data.utilisateur.etablissements[0].donnees.documentUnique === null) {
    await createDocumentUnique({
      variables: {
        etablissementID: data.utilisateur.etablissements[0].id,
        dateedition: new Date().toISOString(),
      },
    });
  }
  var newActions: any = getNewActions(data);
  if (
    newActions.linksAdd.length >= 100 ||
    newActions.linksDelete.length >= 100
  ) {
    for (var j = 0; j < newActions.linksAdd.length; j += 100) {
      await linksMutation({
        variables: {
          linksDelete: [],
          etablissementID: data.utilisateur.etablissements[0].id,
          linksAdd: newActions.linksAdd.slice(j, j + 100),
        },
      });
    }
    for (var i = 0; i < newActions.linksDelete.length; i += 100) {
      await linksMutation({
        variables: {
          linksAdd: [],
          etablissementID: data.utilisateur.etablissements[0].id,
          linksDelete: newActions.linksDelete.slice(i, i + 100),
        },
      });
    }
  } else {
    await linksMutation({
      variables: {
        ...newActions,
        etablissementID: data.utilisateur.etablissements[0].id,
      },
    });
  }
}
