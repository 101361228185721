import {
    StyleSheet,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({

    container: {
        flex: 1,
        padding: 16,
        width: '100%',
        
    },
    document : {
        marginTop: '15px',
    },
    covid1: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        
    },
    covid11: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    imagecovid1: {
        width: 100,
    },
    textecovid1: {
        fontSize: 7,
        textAlign: "justify",
    },

    liliste : {
        marginBottom: '10px',
    },


    table: {
        width: '100%',
        display: 'flex',
        borderWidth: 1,
        borderColor: 'black',
        marginBottom: 16,
        flexDirection: 'column',
    },
    tableRow: {
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
        alignItems: 'center',

    },
    tableHeaderCell: {
        flex: 1,
        borderWidth: 1,
        borderColor: 'black',
        padding: 8,
        minWidth: '25%',
        textAlign: 'center',
    },
    tableHeaderText: {
        fontWeight: 'bold',
    },
    tableCell: {
        flex: 1,
        borderWidth: 1,
        borderColor: 'black',
        padding: 8,
        textAlign: 'center',
    },
    tableCell2: {
        flex: 1,
        borderWidth: 1,
        borderColor: 'black',
        padding: 8,
        textAlign: 'center',
        height: '30px',
    },
    page: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 30,
        paddingBottom: 30,
        paddingHorizontal: 50,
        boxSizing: "border-box",
        backgroundColor: "#fff",
        padding: 30,
        marginTop: 0,
        width: "100%"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom:"10px",
        width: "100%",
    },
    logo: {
        width: 55,
        paddingBottom:"10px",
    },
    title: {
        fontSize: 18,
        fontFamily: "Oswald",
        textTransform: "uppercase",
    },
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        flexGrow: 1,
        padding: 15,
        
    },
    article: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        flexGrow: 1,
        margin: "20px 0",
    },
    articleTitle1: {
        fontWeight: "bold",
        fontSize: 15,
        marginBottom: 5,
        marginTop: 5,
    },
    articleTitle: {
        fontWeight: "bold",
        fontSize: 13,
        marginBottom: 5,
        marginTop: 5,
    },
    articleSubtitle: {
        fontSize: 11,
        marginBottom: 5,
        marginTop: 5,
    },
    articleText: {
        fontSize: 7,
        textAlign: "justify",
    },

    bodyae:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '80%',
    },

    dessin: { 
        width: '50vw',
        
    },

    paragraphe: {
        fontSize: "28px",
        textAlign: "center",
        margin:'12px'
    },

    bottom: {
        alignSelf: "flex-end",
    },

    copyright: {
        fontSize:"1rem",
        alignSelf: "center",
    }



});

export default styles;
