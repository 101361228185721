import { useEffect, useState } from "react";
import Login from "./login";
import ICognitoUserData from "../types/cognito_user";

import "../assets/css/dashboard.css";

import AdminDashboard from "./admin/AdminDashboard";
import UsersDashboard from "./users/UsersDashboard";
import GCDashboard from "./grandcomptes/GCDashboard";
import NoDashboard from "./noDashboard";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../queries/user";
import LoadingComponent from "../components/Loading";
import ErrorComponent from "../components/ErrorComponent";
import userPool from "../utils/userPool";




function dynamoUserActive(user: any) {
  return (
    user.active &&
    user.dateexpiration &&
    new Date() < new Date(user.dateexpiration)
  );
}

function Dashboard() {
  const [user, setUser] = useState<ICognitoUserData | null>(null);
  const dynamoQuery = useQuery(GET_USER, {
    skip: true,
  });
  const [dynamoQueryData, setDynamoQueryData] = useState<any>(null);

  useEffect(() => {
    if (dynamoQueryData || dynamoQuery.loading) return;
    const cognitoUser: any = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((error: any, session: any) => {
        if (error) {
          // Il y a eu une erreur lors de la récupération de la session utilisateur
          console.log(error);
        } else {
          const groups = session.getIdToken().payload["cognito:groups"];
          dynamoQuery
            .refetch({
              id: cognitoUser.username,
            })
            .then(({ data }) => {
              console.log("okk");
              setDynamoQueryData(data);
            });
          setUser({
            username: cognitoUser.username,
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
            groups: groups,
          });
        }
      });
    }
  }, [dynamoQuery]);

  if (dynamoQuery.error) return <ErrorComponent error={dynamoQuery.error} />;
  if (!dynamoQueryData) return <LoadingComponent />;

  const active = dynamoUserActive(dynamoQueryData.utilisateur);
  return (
    <div>
      {user ? (
        (user.groups.includes("ROLE_ADMIN") && (
          <AdminDashboard username={user.username} />
        ))  ||
        (user.groups.includes("ROLE_GC") &&
          (active ? (
            <GCDashboard username={user.username} />
          ) : (
            <NoDashboard />
          )))
          ||
        (user.groups.includes("ROLE_CLIENT") &&
          (active ? (
            <UsersDashboard username={user.username} />
          ) : (
            <NoDashboard />
          ))) ||
        (user.groups.includes("ROLE_USER") && <NoDashboard />)
      ) : (
        <>
          <Login />
        </>
      )}
    </div>
  );
}

export default Dashboard;
