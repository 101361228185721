import { useState } from "react";
import TopBar from "../components/TopBar";
import Navbar from "../components/navbar";
import gcFaq from "../assets/jsons/gcFaq.json";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  faq: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
  },
});

const FAQAdmin = () => {
  const [faqData, setFaqData] = useState(gcFaq);
  return (
    <>
      <div className="dashboard">
        <Navbar type="admin" />
        <div className="dashboard-content">
          <TopBar />
          <h1>ClientName</h1>
          <div className="pageLine"></div>

          <div style={styles.faq}>
            {faqData.questions.map((question, index) => (
              <div key={index} className="question">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<i className="fas fa-chevron-down"></i>}
                    aria-controls={`question${index + 1}-content`}
                    id={`question${index + 1}-header`}
                  >
                    <Typography variant="h6" className="question-header">
                      {question.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1">{question.reponse}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQAdmin;
