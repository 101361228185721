import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

//import css
import styles from "../../assets/css/documents";

function ASBTPListe(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            AFFICHAGES OBLIGATOIRES
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View
          style={{ ...styles.body, color: props.textColor, fontSize: "16px" }}
        >
          <Text style={{ fontSize: "18px" }}>
            1. Panneau de rappel des consignes
          </Text>
          <Text> </Text>
          <Text>
            Sur chaque chantier, installation de panneaux rappellant les
            consignes suivantes :
          </Text>
          <Text> </Text>
          <Text>Chantier interdit au public</Text>
          <Text> </Text>
          <Text>Port du casque obligatoire</Text>
          <Text> </Text>
          <Text>Attention danger !</Text>
          <Text> </Text>
          <Text>Stationnement interdit</Text>
          <Text> </Text>
          <Text style={{ fontSize: "18px" }}>2. Chômage intempéries</Text>
          <Text> </Text>
          <Text>
            L'employeur doit indiquer aux salariés et afficher les périodes de
            chômage pour cause d'intempéries.
          </Text>
          <Text> </Text>
          <Text>
            Cet affichage doit être situé à l'entrée du chantier concerné ou au
            siège de l'entreprise.
          </Text>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ASBTPListe;
