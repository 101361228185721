import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import styles from "../../assets/css/documents";

function ARConges(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  console.log("chargement du document");
  const { etablissementAffichages } = props;
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
    "Juin",
    "Juillet",
    "Décembre",
  ];

  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            DEPARTS EN CONGES
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>

        <View>
          <View>
            <Text style={{ ...styles.article, color: props.textColor }}>
              L'ordre de départ en congés doit être communiqué aux salariés dans
              les délais prévus par la convention collective et dans tous les
              cas au moins un mois à l'avance.
            </Text>
          </View>
        </View>

        <View
          style={{
            ...styles.container,
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  NOM Prénom
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  DEPART
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  RETOUR
                </Text>
              </View>
            </View>
            {months.map((month, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell2}></View>
                <View style={styles.tableCell2}></View>
                <View style={styles.tableCell2}></View>
              </View>
            ))}
          </View>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
            marginTop: "20px",
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ARConges;
