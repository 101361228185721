/**
 * This code contains utility functions and interfaces related to action plans and dates.
 */

import { Chart, registerables } from "chart.js/auto";
import { addMonths, format, isBefore, isEqual, startOfMonth } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useMemo, useRef } from "react";

/**
 * Interface representing an action.
 */
interface Action {
  delai: string;
  avancement: string; // The progress of the action
  dateouverture: string; // The opening date of the action
  datecloture: Date; // The closing date of the action
  isopen: boolean; // Indicates whether the action is open or closed
  isupdated: string; // Indicates whether the action has been updated
}

/**
 * Interface representing the data structure of a plan action.
 */
interface PlanActionData {
  etablissement?: {
    documentUnique?: {
      id: string; // The unique identifier of the document
      planaction?: {
        dateedition: string; // The date of edition of the plan action
        actions: Action[]; // An array of actions
      };
    };
  };
}

/**
 * Function to get the last 5 months starting from the current month.
 *
 * @returns {Date[]} - An array of Date objects representing the last 5 months.
 */
function getMonthsDates(monthBeforeNow: number, monthAfterNow: number): Date[] {
  const day = addMonths(startOfMonth(new Date()), -Math.abs(monthBeforeNow));
  const months = Array.from(
    { length: monthAfterNow - monthBeforeNow },
    (_, i) => {
      return addMonths(day, i);
    }
  );
  return months;
}

function isDateClotureOutDated(action: Action, dateNow: Date): boolean {
  return false && isBefore(new Date(action.delai), startOfMonth(dateNow));
}

function isSameMonth(action: Action, dateNow: Date): boolean {
  return isEqual(
    startOfMonth(new Date(action.delai)),
    startOfMonth(new Date(dateNow))
  );
}

function isReallyClotured(action: Action): boolean {
  return !action.isopen;
}

function isDateClotureInFuture(action: Action): boolean {
  return isBefore(new Date(), new Date(action.delai));
}

/**
 * This component renders a bar chart to display the number of ongoing and completed actions
 * for each month in the last few months.
 *
 * @param datas - The data object containing the plan action data.
 *
 * @returns The GraphActions component.
 */
const GraphActions = ({ actions }: { actions: any[] }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null); // Stores the instance of the chart
  const lastMonths = useMemo(() => getMonthsDates(0, 15), []);
  useEffect(() => {
    if (chartRef.current) {
      Chart.register(...registerables);

      const ctx = chartRef.current.getContext("2d");

      if (ctx) {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy(); // Destroys the existing chart if it exists
        }
        chartInstanceRef.current = new Chart(ctx, {
          type: "bar",
          data: {
            labels: lastMonths.map((date) =>
              format(date, "MMM-yy", { locale: fr })
            ),
            datasets: [
              {
                label: "Planifiées",
                data: lastMonths.map(
                  (date) =>
                    actions.filter(
                      (action) =>
                        isSameMonth(action, date) &&
                        isDateClotureInFuture(action) &&
                        !isReallyClotured(action)
                    ).length
                ),
                backgroundColor: "rgba(33, 150, 243, 0.6)",
              },
              {
                label: "En retard",
                data: lastMonths.map(
                  (date, index) =>
                    actions.filter(
                      (action) =>
                        index === 0 &&
                        !isDateClotureInFuture(action) &&
                        !isReallyClotured(action)
                    ).length
                ),
                backgroundColor: "rgba(139, 0, 74, 0.6)",
              },
              {
                label: "Cloturées",
                data: lastMonths.map(
                  (date, index) =>
                    actions.filter(
                      (action) =>
                        (index === 0 &&
                          !isDateClotureInFuture(action) &&
                          isReallyClotured(action)) ||
                        (isReallyClotured(action) && isSameMonth(action, date))
                    ).length
                ),
                backgroundColor: "rgba(139, 195, 74, 0.6)",
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          },
        });
      }
    }
  }, [actions, lastMonths]);

  if (!actions) {
    return <div>Pas de plan d'action</div>; // Returns a message if there is no plan action data
  }

  return <canvas ref={chartRef} />; // Renders the chart canvas
};

export default GraphActions;
