
import  API_URL from "../utils/api";
/**
 * Fetches INRS data from an API and processes the response to extract relevant information.
 * The fetched data is expected to be in XML format.
 *
 * @async
 * @returns {Promise<void>} A promise that resolves when the data has been fetched and processed successfully.
 */
const fetchInrsData = async () => {
  const fetch = require("node-fetch");

  // Define the URL of the API endpoint
  let url = `${API_URL}/rss`;

  // Fetch the data from the API
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/xml",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res: { text: () => any }) => res.text())
    .then((xmlString: string) => {
      // Parse the XML response
      const parser = new DOMParser();
      const xml = parser.parseFromString(xmlString, "text/xml");

      // Extract the news items from the XML
      const news = xml.querySelectorAll("item");

      // Initialize an array to store the extracted news items
      const newsArray:
        | ((prevState: never[]) => never[])
        | {
            title: string;
            description: string;
            link: string;
            pubDate: string;
            guid: string;
          }[] = [];

      // Iterate over each news item and extract the relevant information
      news.forEach((item: any) => {
        newsArray.push({
          title: item.querySelector("title").innerHTML,
          description: item.querySelector("description").innerHTML,
          link: item.querySelector("link").innerHTML,
          pubDate: item.querySelector("pubDate").innerHTML,
          guid: item.querySelector("guid").innerHTML,
        });
      });

      // Process the extracted news items (e.g., store them in a state variable)
      setNews(newsArray);
    })
    .catch((err: string) => console.error("error:" + err));
};

export default fetchInrsData;

/**
 * Sets the news data in the application.
 *
 * This function is responsible for setting the news data in the application.
 * It takes an array of news objects as input, where each news object contains
 * the following properties:
 * - title: A string representing the title of the news.
 * - description: A string representing the description of the news.
 * - link: A string representing the link to the news article.
 * - pubDate: A string representing the publication date of the news.
 * - guid: A string representing the unique identifier of the news.
 *
 * @param {Array} newsArray - An array of news objects containing the news data.
 * @throws {Error} This function is not implemented and will throw an error.
 *
 * @example
 * const newsData = [
 *   {
 *     title: "Breaking News",
 *     description: "This is a breaking news article.",
 *     link: "https://example.com/news/1",
 *     pubDate: "2022-01-01",
 *     guid: "1234567890"
 *   },
 *   {
 *     title: "Latest News",
 *     description: "This is the latest news article.",
 *     link: "https://example.com/news/2",
 *     pubDate: "2022-01-02",
 *     guid: "0987654321"
 *   }
 * ];
 * setNews(newsData);
 */
function setNews(
  newsArray: {
    title: string;
    description: string;
    link: string;
    pubDate: string;
    guid: string;
  }[]
) {
  throw new Error("Function not implemented.");
}
