import "../assets/css/loading.css";

// define props (not obligatory)
interface Props {
  type?: string;
}

const LoadingComponent = (props: Props) => {
  if (props.type === "topbar") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="loader"></div>
    </div>
  );
};

export default LoadingComponent;
