import { useState, useEffect } from "react";
import { addMonths, format, isBefore } from "date-fns";

import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";
import AntSwitch from "../../components/AntSwitch";

import "../../assets/css/clients.css";

// import query
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../queries/users";
import IEtablissementsData from "../../types/entreprises";
import LoadingComponent from "../../components/Loading";
import client from "../../utils/http-common";
import { UPDATE_USER } from "../../queries/user";
import { formatDateYear } from "../../utils/formatDate";

// util for export to csv
import { CSVLink } from "react-csv";
import ErrorComponent from "../../components/ErrorComponent";
import API_URL from "../../utils/api";

function mutateActivation(userID: string, active: boolean) {
  return client.mutate({
    mutation: UPDATE_USER,
    variables: {
      id: userID,
      active: active,
    },
  });
}

const Clients = () => {
  const [clientType, setClientType] = useState("client");
  const [searchText, setSearchText] = useState("");
  const [entreprises, setEntreprises] = useState<any[]>([]);
  const [entreprisesGC, setEntreprisesGC] = useState<any[]>([]);


  const { loading, error } = useQuery(GET_USERS, {
    onCompleted(data) {
      const _clients = data.utilisateurs.filter(
        (client: any) => client.role === "ROLE_CLIENT"
      );
      const _clientsGC = data.utilisateurs.filter(
        (client: any) => client.role === "ROLE_GC"
      );
      const newEntreprises = _clients.reduce(
        (acc: IEtablissementsData[], client: any) => {
          return acc.concat(
            client.etablissements.map((et: any) => ({
              ...et,
              client: client,
            }))
          );
        },
        []
      );

      setEntreprises(
        newEntreprises.filter(
          (entreprise: any) => entreprise.client.gcID == null
        )
      );
      const newEntreprisesGC = _clientsGC.reduce(
        (acc: IEtablissementsData[], client: any) => {
          return acc.concat(
            client.etablissements.map((et: any) => ({
              ...et,
              client: client,
            }))
          );
        },
        []
      );
      setEntreprisesGC(newEntreprisesGC);
    },
  });

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  // filtered rows for search who wait for the entreprises array to be filled
  const filteredRows = entreprises.filter((row) => {
    return (
      (row.raisonsociale &&
        row.raisonsociale.toLowerCase().includes(searchText.toLowerCase())) ||
      (row.dirigeant &&
        row.dirigeant.toLowerCase().includes(searchText.toLowerCase()))
    );
  });

  const filteredRowsGC = entreprisesGC.filter((row) => {
    return (
      row.raisonsociale &&
      row.raisonsociale.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const handleExport = (filteredRows: any) => {
    // export filteredRows to csv
    const headers = [
      { label: "Raison sociale", key: "raisonsociale" },
      { label: "Username cognito", key: "username" },
      { label: "Dirigeant", key: "dirigeant" },
      { label: "Metier", key: "metier" },
      { label: "Fin", key: "fin" },
    ];
    const csvData = filteredRows.map((row: any) => ({
      raisonsociale: row.raisonsociale,
      dirigeant: row.dirigeant,
      email: row.email,
      metier: row.metier.libelle,
      username: row.userId,
      fin: format(new Date(row.client.dateexpiration), "dd/MM/yyyy"), // Formatage de la date en "jour/mois/année"
    }));
    return (
      <CSVLink
        data={csvData}
        headers={headers}
        filename={"clients.csv"}
        className="export-button"
      >
        Exporter au format CSV
      </CSVLink>
    );
  };

  const getLogo = (client: any) => {
    console.log("client :", client);
    if (client.donnees?.infosAffichage?.logo) {
      return client.donnees?.infosAffichage?.logo;
    } else {
      return "https://pbs.twimg.com/profile_images/578844000267816960/6cj6d4oZ_400x400.png";
    }
  };

  const isDateExpired = (date: string | number | Date) => {
    return isBefore(new Date(date), new Date());
  };
  
  const isDateInNextThreeMonths = (date: string | number | Date) => {
    const currentDate = new Date();
    const threeMonthsLater = addMonths(currentDate, 3);
    return isBefore(new Date(date), threeMonthsLater);
  };

  return (
    <div className="dashboard">
      <Navbar type="admin" />
      <div className="dashboard-content">
        <TopBar />
        <div className="head">
          <h1>Gestion des clients</h1>
          {handleExport(
            clientType === "client" ? filteredRows : filteredRowsGC
          )}
        </div>
        <div className="pageLine"></div>
        <div className="tabs">
          <div
            className={clientType === "client" ? "tabSelected" : "tab"}
            onClick={() => setClientType("client")}
          >
            Clients
          </div>
          <div
            className={clientType === "gc" ? "tabSelected" : "tab"}
            onClick={() => setClientType("gc")}
          >
            Grands Comptes
          </div>
        </div>
        {clientType === "client" ? (
          <>
            <div className="tab-content">
              <div className="filter-box">
                Filtrer : &nbsp;
                <input
                  type="text"
                  className="filter-input"
                  placeholder="Nom, entreprise"
                  id="filter"
                  onChange={handleSearch}
                />
              </div>
              <>
                {filteredRows &&
                  filteredRows.map((item, index) => (
                    <div
                      key={index}
                      className="content-client"
                      style={{
                        borderBottom:
                          index === filteredRows.length - 1
                            ? "none"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      <img
                        src={getLogo(item)}
                        height="50px"
                        width="50px"
                        alt="client"
                      />
                      <h5 className="content-title">{item.raisonsociale}</h5>
                      <p className="content-text">
                        {item.client.etablissements[0].metier.libelle}
                      </p>
                      <div className="content-connexion">
                        <p className="content-text2">Expiration : </p>
                        <p
                          className={`content-text3 ${
                            isDateExpired(item.client.dateexpiration)
                              ? "highlight-red"
                              : isDateInNextThreeMonths(
                                  item.client.dateexpiration
                                )
                              ? "highlight-orange"
                              : ""
                          }`}
                        >
                          {formatDateYear(item.client.dateexpiration)}
                        </p>
                      </div>
                      <div style={{ alignContent: "right" }}>
                        <AntSwitch
                          style={{ alignSelf: "right" }}
                          checked={item.client.active}
                          onChange={() => {
                            mutateActivation(
                              item.client.id,
                              !item.client.active
                            ).then(() => {
                              setEntreprises(
                                entreprises.map((entreprise) => {
                                  if (entreprise.id === item.id) {
                                    return {
                                      ...entreprise,
                                      client: {
                                        ...entreprise.client,
                                        active: !entreprise.client.active,
                                      },
                                    };
                                  }
                                  return entreprise;
                                })
                              );
                            });
                            fetch(`${API_URL}/admin/users/${item.client.id}`, {
                              method: "POST",
                              headers: {
                                Authorization:
                                "Bearer " + localStorage.getItem("token"),
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                enabled: !item.client.active,
                              }),
                            });
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                      </div>

                      <Link
                        style={{ alignSelf: "right" }}
                        to={"/client?id=" + item.id + "&userid=" + item.userId}
                      >
                        <EditIcon className="edit-icon" />
                      </Link>
                    </div>
                  ))}
              </>
            </div>
          </>
        ) : (
          <>
            <div className="tab-content">
              <div className="filter-box">
                Filtrer : &nbsp;
                <input
                  type="text"
                  className="filter-input"
                  placeholder="Nom, entreprise"
                  id="filter"
                  onChange={handleSearch}
                />
              </div>
              <>
                {filteredRowsGC &&
                  filteredRowsGC.map((item, index) => (
                    <div
                      key={index}
                      className="content-client"
                      style={{
                        borderBottom:
                          index === filteredRowsGC.length - 1
                            ? "none"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      <img
                        src={getLogo(item)}
                        height="50px"
                        width="50px"
                        alt="clients"
                        className="content-client-icon"
                      />
                      <h5 className="content-title">{item.raisonsociale}</h5>
                      <p className="content-text">
                        {item.client.active ? "Actif" : "Inactif"}
                      </p>
                      <div className="content-connexion">
                        <p className="content-text2">Date d'expiration : </p>
                        <p className="content-text3">
                          {formatDateYear(item.client.dateexpiration)}
                        </p>
                      </div>
                      <AntSwitch
                        // checked={item.active ? true : false}
                        checked={item.client.active}
                        onChange={() => {
                          mutateActivation(
                            item.client.id,
                            !item.client.active
                          ).then(() => {
                            setEntreprisesGC(
                              entreprisesGC.map((entreprise) => {
                                if (entreprise.id === item.id) {
                                  return {
                                    ...entreprise,
                                    client: {
                                      ...entreprise.client,
                                      active: !entreprise.client.active,
                                    },
                                  };
                                }
                                return entreprise;
                              })
                            );
                          });
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Link
                        to={
                          "/clientgc?id=" +
                          item.client.id +
                          "&userid=" +
                          item.userId
                        }
                      >
                        <EditIcon className="edit-icon" />
                      </Link>
                    </div>
                  ))}
              </>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Clients;
