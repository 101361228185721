import { useState } from "react";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  TextField,
} from "@mui/material";
import { GET_FULL_METIER } from "../../queries/metier";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { deleteDuplicates } from "../../utils/formatDatas";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import { CSVLink } from "react-csv";

function formatDatas(datas: any) {
  const ret = [];
  for (const uniteTravail of datas.metier.unitesTravail) {
    const risques: any = deleteDuplicates(
      uniteTravail.risques,
      (item1: any, item2: any) => item1.libelle === item2.libelle
    );
    ret.push({
      ...uniteTravail,
      risques: risques,
    });
  }
  return ret;
}

const AdminMetierView = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // set modal state
  const [open, setOpen] = useState(false);
  const [metier, setMetier] = useState<any[]>([]);
  const { loading, error } = useQuery(GET_FULL_METIER, {
    variables: { id: queryParams.get("id") },
    onCompleted(data) {
      setMetier(formatDatas(data));
    },
    fetchPolicy:"no-cache"
  });

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;

  console.log(metier);

  // set modal
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleExport = (metier: any[]) => {
    // export filteredRows to csv
    const headers = [
      { label: "Unité de travail", key: "uniteTravail" },
      { label: "Risques", key: "risques" },
      { label: "Descriptions de risques", key: "risqueDescription" },
      { label: "Fréquence", key: "frequence" },
      { label: "Gravité", key: "gravité" },
      { label: "Moyens de Prévention", key: "prevention" },
      { label: "Pondération", key: "ponderation" },
      { label: "Spécificités Métier", key: "specificite" },
    ];


    const csvData = metier
      .map((uniteTravail: any) =>
        uniteTravail.risques.map((risque: any) =>
          risque.duplicates.map((desc: any) =>
            desc.mesures.map((mesure: any) => ({
              uniteTravail: uniteTravail.libelle,
              risques: risque.libelle,
              risqueDescription: desc.description,
              frequence: desc.frequence,
              gravité: desc.gravite,
              prevention: mesure.libelle,
              ponderation: mesure.ponderation,
              specificite: mesure.specificite,
            }))
          )
        )
      )
      .flat(4);
    return (
      <CSVLink
        data={csvData}
        headers={headers}
        filename={"metiers.csv"}
        className="export-button"
      >
        Exporter au format CSV
      </CSVLink>
    );
  };

  // get metier id from url
  // const url = window.location.href;
  // const metierId = url.substring(url.lastIndexOf("/") + 1);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-content">
          <h2>Modifier une unité de travail</h2>
          <TextField
            label="Nom de l'unité de travail"
            style={{ width: "80%" }}
          />
          <div className="modal-buttons">
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ margin: "10px" }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ margin: "10px" }}
            >
              Modifier
            </Button>
          </div>
        </div>
      </Modal>
      <div className="dashboard">
        <Navbar type="admin" />
        <div className="dashboard-content">
          <TopBar />
          <div className="head">
            <h1>Gestion des métiers</h1>
            {handleExport(metier)}
          </div>
          <div className="pageLine"></div>
          <TableContainer
            component={Paper}
            style={{ width: "100%", marginTop: "20px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #e0e0e0" }}
                  >
                    <b>Unité de travail</b>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #e0e0e0" }}
                  >
                    <b>Risques professionnels</b>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ width: 120, borderBottom: "none" }}
                        >
                          <b>Risque</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: 170, borderBottom: "none" }}
                        >
                          <b>Description risque</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: 40, borderBottom: "none" }}
                        >
                          <b>F</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: 15, borderBottom: "none" }}
                        >
                          <b>G</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: 450, borderBottom: "none" }}
                        >
                          <b>Mesures préventives</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: 100, borderBottom: "none" }}
                        >
                          <b>P</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: 120, borderBottom: "none" }}
                        >
                          <b>Spécificités métier</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metier.map((uniteTravail) => (
                  <TableRow>
                    <TableCell style={{ width: 70 }}>
                      {uniteTravail.libelle}
                    </TableCell>
                    <TableCell>
                      <Table size="small">
                        <TableBody>
                          {uniteTravail.risques.map((risque: any) => (
                            <TableRow>
                              <TableCell
                                style={{
                                  width: 70,
                                  borderBottom:
                                    "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                {risque.libelle}
                              </TableCell>
                              <TableCell
                                style={{
                                  borderBottom:
                                    "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Table size="small">
                                  <TableBody>
                                    {risque.duplicates.map((desc: any) => (
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            width: 100,
                                            borderBottom:
                                              "1px solid rgba(224, 224, 224, 1)",
                                          }}
                                        >
                                          {desc.description}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: 5,
                                            borderBottom:
                                              "1px solid rgba(224, 224, 224, 1)",
                                          }}
                                        >
                                          {`${desc.frequence}`}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: 5,
                                            borderBottom:
                                              "1px solid rgba(224, 224, 224, 1)",
                                          }}
                                        >
                                          {`${desc.gravite}`}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: 500,
                                            borderBottom:
                                              "1px solid rgba(224, 224, 224, 1)",
                                          }}
                                        >
                                          <Table
                                            size="small"
                                            style={{ border: "none" }}
                                          >
                                            <TableBody>
                                              {desc.mesures.map(
                                                (mesure: any) => (
                                                  <TableRow
                                                    style={{ border: "none" }}
                                                  >
                                                    <TableCell
                                                      style={{
                                                        border: "none",
                                                        width: "76%",
                                                      }}
                                                    >
                                                      {mesure.libelle}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border: "none",
                                                        width: "12%",
                                                      }}
                                                    >
                                                      {`${mesure.ponderation}%`}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border: "none",
                                                        width: "12%",
                                                      }}
                                                    >
                                                      {`${
                                                        mesure.specificite ||
                                                        "Aucune"
                                                      }`}
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminMetierView;

// <TableRow
// sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
// >
// <TableCell
//   component="th"
//   scope="row"
//   onClick={() => {
//     handleOpen();
//     console.log("clicked");
//   }}
// >
//   Unité 1
// </TableCell>
// <TableCell align="right">Risque 1</TableCell>
// <TableCell align="right">Description 1</TableCell>
// </TableRow>
