import React, { useState } from "react";
import "../assets/css/inputfile.css";

interface Props {
  logo: string;
  onLogoChange: (logo: string) => void;
}

const InputFile = ({ logo, onLogoChange }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(logo);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const imageUrl = fileReader.result as string;
        setPreviewUrl(imageUrl);
        onLogoChange(imageUrl); // appel de la fonction de rappel avec la nouvelle valeur du logo
      };
      fileReader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
      onLogoChange(""); // appel de la fonction de rappel avec une chaîne vide pour supprimer le logo
    }
  };

  return (
    <div className="style-inputfile">
      <label htmlFor="file-input">
        <fieldset>
          <div className="file-preview">
            {previewUrl ? (
              <img src={previewUrl} alt="File preview" />
            ) : (
              <img src={logo} alt="Logo" />
            )}
          </div>

          <label htmlFor="file-input">Sélectionnez un fichier</label>
          <div className="file-input-text">
            {selectedFile ? selectedFile.name : "Aucun fichier sélectionné"}
          </div>
          <input
            type="file"
            id="file-input"
            name="file-input"
            onChange={handleFileInputChange}
          />
        </fieldset>
      </label>
    </div>
  );
};

export default InputFile;
