import { Document, Page, View, Text, Image } from "@react-pdf/renderer";



//import css
import styles from "../../assets/css/documents";

function ASRestaurantListe1(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;

  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            AFFICHAGES OBLIGATOIRES
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View
          style={{ ...styles.body, color: props.textColor, fontSize: "16px" }}
        >
          <Text style={{ fontSize: "18px" }}>1. Origine de la viande</Text>
          <Text> </Text>
          <Text>
            L'origine est indiquée par l'une ou l'autre des mentions suivantes.
          </Text>
          <Text> </Text>
          <Text>
            "Origine : (nom du pays)" lorsque la naissance, l'élevage et
            l'abattage du bovin dont sont issues les viandes ont eu lieu dans le
            même pays.
          </Text>
          <Text> </Text>
          <Text>
            "Né et élevé : (nom du pays de naissance et nom du ou des pays
            d'élevage) et abattu : (nom du pays d'abattage)" lorsque la
            naissance, l'élevage et l'abattage ont eu lieu dans des pays
            différents.
          </Text>
          <Text> </Text>
          <Text>
            L'information doit être donnée de façon lisible et visible, par
            affichage, indication sur les cartes et menus ou sur tout autre
          </Text>
          <Text> </Text>
          <Text style={{ fontSize: "18px" }}>2. Affichage des prix</Text>
          <Text> </Text>
          <Text>Vous devez afficher vos prix TTC.</Text>
          <Text> </Text>
          <Text>
            Ils doivent être affichés à l’extérieur au minimum à partir de 11h30
            pour le déjeuner et 18h pour le dîner et à l’intérieur de votre
            restaurant pendant au moins toute la durée du service et contenir la
            mention « prix service compris ».
          </Text>
          <Text> </Text>
          <Text>
            Indiquer également le prix d’au moins 5 vins, ou de 5 boissons si
            vous ne servez pas de vin. Dans les établissements ne servant pas de
            vin, une carte comportant au minimum la nature des boissons et les
            prix de cinq boissons couramment servies doit être affichée.
          </Text>
          <Text> </Text>
          <Text>
            Les cartes et menus doivent comporter, pour chaque prestation, le
            prix ainsi que la mention " boisson comprise " ou " boisson non
            comprise " et, dans tous les cas, indiquer pour les boissons : la
            nature et la contenance.
          </Text>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ASRestaurantListe1;
