import { gql } from "@apollo/client";

const LIST_MESURES_USER = gql`
  query GetMesures($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        metier {
          unitesTravail {
            id
            libelle
            risques {
              libelle
              description
              id
              mesures {
                id
                libelle
                ponderation
              }
            }
          }
        }
        donnees {
          unitesTravail {
            id
            risques {
              mesures {
                id
                specificite
              }
              id
            }
          }
        }
      }
    }
  }
`;

const CREATE_MESURE = gql`
  mutation CreateMesure($libelle: String!, $ponderation: Int!) {
    mesure {
      add(libelle: $libelle, ponderation: $ponderation) {
        id
      }
    }
  }
`;

const UPDATE_MESURE = gql`
  mutation UpdateMesure($id: String!, $libelle: String, $ponderation: Int) {
    mesure {
      update(id: $id, libelle: $libelle, ponderation: $ponderation) {
        id
      }
    }
  }
`;

export { LIST_MESURES_USER, CREATE_MESURE, UPDATE_MESURE };
