import { Document, Page, View, Text, Image } from "@react-pdf/renderer";


import Fumer from "../../assets/images/nosmoke.png";
import styles from "../../assets/css/documents";

function ARFumer(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            INTERDICTION DE FUMER & VAPOTER
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View
          style={{
            ...styles.body,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <Image
            style={{ height: "300", width: "300", alignSelf: "center" }}
            src={Fumer}
          />
          <View>
            <Text style={{ fontSize: "20px", color: props.textColor }}>
              Décret numéro 2017 633 du 25 avril 2017
            </Text>
            <Text style={{ fontSize: "15px", color: props.textColor }}>
              relatif aux conditions d'application de l'interdiction de vapoter
              dans certains lieux à usage collectif.
            </Text>
            <Text style={{ fontSize: "20px", color: props.textColor }}>
              Décret numéro 2006 1386 du 15 novembre 2006
            </Text>
            <Text style={{ fontSize: "15px", color: props.textColor }}>
              fixant les conditions d'application de l'interdiction de fumer
              dans les lieux affectés à usage collectif.
            </Text>
            <Text
              style={{
                fontSize: "20px",
                color: props.textColor,
                alignSelf: "center",
                marginTop: "20px",
              }}
            >
              Pour arrêter de fumer, faites-vous aider en appelant le
            </Text>
            <Text
              style={{
                fontSize: "50px",
                color: props.textColor,
                alignSelf: "center",
              }}
            >
              3989
            </Text>
            <Text
              style={{
                fontSize: "10px",
                color: props.textColor,
                alignSelf: "center",
              }}
            >
              TABAC INFO SERVICE du lundi au vendredi de 9h à 8h. 0,15 € / min
            </Text>
          </View>
        </View>
        <View>
          <Text
            style={{
              fontSize: "10px",
              fontStyle: "italic",
              textAlign: "right",
              marginTop: "20px",
              color: props.textColor,
            }}
          >
            Tous droits réservés AT Consulting
          </Text>
        </View>
      </Page>
    </Document>
  );
}

export default ARFumer;
