import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import Image100 from "../../assets/images/Image100.png";
import styles from "../../assets/css/documents";

function ARMarianneExplications(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  logo: any;
}) {
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          <Image style={styles.logo} src={Image100} />
          <Text style={{ ...styles.title, color: props.textColor }}>
            Explications Marianne
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ARMarianneExplications;
