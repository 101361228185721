import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import horaires from "../../assets/images/horaires.png";
import conges from "../../assets/images/conges.png";
import medecin from "../../assets/images/medecin.png";
import inspection from "../../assets/images/inspection.png";
import urgences from "../../assets/images/urgences.png";
import justice from "../../assets/images/justice.png";
import livre from "../../assets/images/livre.png";
import roue from "../../assets/images/roue.png";
import repas from "../../assets/images/repas.png";

import styles from "../../assets/css/documents";

function ARInfos(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  console.log("testaff :", etablissementAffichages);
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            INFORMATIONS
          </Text>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image style={styles.logo} src={props.logo} />
          ) : null}
        </View>
        <View
          style={{
            ...styles.container,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={horaires} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <View>
                <Text
                  style={{
                    fontWeight: "bold",
                    color: props.textColor,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Horaires de travail
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  margin: "3px",
                  justifyContent: "space-evenly",
                  fontSize: "12px",
                  color: props.textColor,
                }}
              >
                <View>
                  <Text>
                    Lundi :{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairelundidebut
                    }{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairelundifin
                    }
                  </Text>
                  <Text>
                    Mardi :{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairemardidebut
                    }{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairemardifin
                    }
                  </Text>
                  <Text>
                    Mercredi :{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairemercredidebut
                    }{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairemercredifin
                    }
                  </Text>
                </View>
                <View>
                  <Text>
                    Jeudi :{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairejeudidebut
                    }{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairejeudifin
                    }
                  </Text>
                  <Text>
                    Vendredi :{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairevendredidebut
                    }{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairevendredifin
                    }
                  </Text>
                </View>
                <View>
                  <Text>
                    Samedi :{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairesamedidebut
                    }{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairesamedifin
                    }
                  </Text>
                  <Text>
                    Dimanche :{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairesamedidebut
                    }{" "}
                    {
                      etablissementAffichages?.donnees.infosAffichage
                        .horairesamedifin
                    }
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={repas} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Pause déjeuner
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: props.textColor,
                  margin: "7px",
                }}
              >
                {etablissementAffichages?.donnees.infosAffichage.joursrepos}
              </Text>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={roue} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Dérogation aux horaires
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: props.textColor,
                  margin: "7px",
                }}
              >
                {etablissementAffichages?.donnees.infosAffichage.derogation}
              </Text>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={conges} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Départs en congés
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: props.textColor,
                  margin: "7px",
                }}
              >
                L'ordre et les dates de départ en congés doivent être connus des
                salariés avec le préavis fixé par la convention collective et à
                défaut avec un mois de préavis.
              </Text>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={urgences} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Numéros d'urgence
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  margin: "3px",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  fontSize: "12px",
                  color: props.textColor,
                }}
              >
                <View style={{ margin: "7px" }}>
                  <Text style={{ fontSize: "12px", color: props.textColor }}>
                    Toute urgence : 112
                  </Text>
                  <Text style={{ fontSize: "12px", color: props.textColor }}>
                    Pompier : 18
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: "12px", color: props.textColor }}>
                    SAMU : 15
                  </Text>
                  <Text style={{ fontSize: "12px", color: props.textColor }}>
                    Police : 17
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={medecin} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Médecine du travail
              </Text>
              <View
                style={{
                  fontSize: "12px",
                  color: props.textColor,
                  marginLeft: "7px",
                  marginBottom: "7px",
                }}
              >
                <Text style={{ fontSize: "12px", color: props.textColor }}>
                  Adresse :{" "}
                  {
                    etablissementAffichages?.donnees.infosAffichage
                      .adressemedecine
                  }
                </Text>
                <Text style={{ fontSize: "12px", color: props.textColor }}>
                  Téléphone :{" "}
                  {
                    etablissementAffichages?.donnees.infosAffichage
                      .numeromedecine
                  }
                </Text>
              </View>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={inspection} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Inspection du travail
              </Text>
              <View
                style={{
                  fontSize: "12px",
                  color: props.textColor,
                  margin: "7px",
                }}
              >
                <Text style={{ fontSize: "12px", color: props.textColor }}>
                  Nom :{" "}
                  {
                    etablissementAffichages?.donnees.infosAffichage
                      .nominspection
                  }
                </Text>
                <Text style={{ fontSize: "12px", color: props.textColor }}>
                  Adresse :{" "}
                  {
                    etablissementAffichages?.donnees.infosAffichage
                      .adresseinspection
                  }
                </Text>
                <Text style={{ fontSize: "12px", color: props.textColor }}>
                  Téléphone :{" "}
                  {
                    etablissementAffichages?.donnees.infosAffichage
                      .numeroinspection
                  }
                </Text>
              </View>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={justice} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Défenseur des droits
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: props.textColor,
                  alignSelf: "center",
                }}
              >
                Appelez le 09 69 39 00 00
              </Text>
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Image style={styles.logo} src={livre} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px",
                borderRadius: "5",
                marginLeft: "5px",
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: props.textColor,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Convention collective
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: props.textColor,
                  margin: "7px",
                }}
              >
                L'entreprise est soumise à la{" "}
                {etablissementAffichages?.donnees.infosAffichage.convention}{" "}
                consultable à tout moment sur www.legifrance.gouv.fr ou sur
                demande à la direction.
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            marginTop: "20px",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ARInfos;
