import { Document, Page, View, Text, Image } from "@react-pdf/renderer";



//import css
import styles from "../../assets/css/documents";

function ASBTPChomage(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  const months = ["2023", "2024", "2025", "2026", "2027", "2028"];
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            CHÔMAGE INTEMPERIES
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View
          style={{ ...styles.body, color: props.textColor, fontSize: "16px" }}
        >
          <Text style={{ fontSize: "18px" }}>CONDITIONS METEOROLOGIQUES</Text>
          <Text> </Text>
          <Text>
            Les conditions météorologiques suivantes rendent le travail sur le
            chantier impossible ou dangereux :
          </Text>
          <Text> </Text>
          <Text>
            TEMPETE / VENT / PLUIE / INONDATION / NEIGE / GEL / VERGLAS /
            CANICULE / AUTRE (PRECISER)
          </Text>
          <Text> </Text>
          <Text style={{ fontSize: "18px" }}>CHÔMAGE</Text>
          <Text> </Text>
          <Text>
            A ce titre, l'employeur met en place du chômage sur les dates
            suivantes
          </Text>
          <Text> </Text>
          <Text>DU (INLUS) :</Text>
          <Text> </Text>
          <Text>AU (INLUS) :</Text>
          <Text> </Text>

          <View style={{ ...styles.table, width: "470px" }}>
            <View style={{ ...styles.tableRow }}>
              <View style={styles.tableHeaderCell}>
                <Text
                  style={{ color: props.textColor, fontWeight: "ultrabold" }}
                >
                  SALARIE
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  CHANTIER
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  SUBSITUTION
                </Text>
              </View>
            </View>
            {months.map((index) => (
              <View style={{ ...styles.tableRow }} key={index}>
                <View style={{ ...styles.tableHeaderCell, height: "50px" }}>
                  <Text
                    style={{ color: props.textColor, height: "100%" }}
                  ></Text>
                </View>
                <View style={{ ...styles.tableHeaderCell, height: "50px" }}>
                  <Text
                    style={{ color: props.textColor, height: "100%" }}
                  ></Text>
                </View>
                <View style={{ ...styles.tableHeaderCell, height: "50px" }}>
                  <Text
                    style={{ color: props.textColor, height: "100%" }}
                  ></Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ASBTPChomage;
