/**
 * This file contains a React component that displays a formatted date based on the input date string.
 * It also imports necessary dependencies and defines a helper function for formatting the date.
 */

import { Typography, Grid } from "@mui/material"; // Importing necessary components from the Material-UI library

import "../assets/css/docu.css"; // Importing custom CSS styles

import ArrowRightIcon from "@mui/icons-material/ArrowRight"; // Importing the ArrowRightIcon component from Material-UI

import { useQuery } from "@apollo/client"; // Importing the useQuery hook from the Apollo Client library

import {
  GET_DOCUMENT_UNIQUE_INFOS,
  GET_FULL_DOCUMENT_UNIQUE,
} from "../queries/documentUnique"; // Importing GraphQL queries related to document uniqueness

import { useEffect, useState } from "react"; // Importing the useEffect and useState hooks from React

import { GET_WORKSGROUPS } from "../queries/worksgroups"; // Importing a GraphQL query related to worksgroups

import LoadingComponent from "./Loading"; // Importing a custom LoadingComponent

/**
 * A helper function that formats a date string into a localized date format.
 *
 * @param date - The input date string to be formatted.
 *
 * @returns {string} - The formatted date string.
 */
function formatDate(date: string): string {
  console.log("date : ", date);

  // Handling different scenarios for the input date
  if (date === undefined) return "Jamais mis à jour";
  if (date === null) return "No date";
  if (date === "") return "Empty date";

  var _date;
  try {
    _date = new Date(date);
  } catch {
    return "Invalid date";
  }

  // Formatting the date using the Intl.DateTimeFormat API
  const formattedDate = new Intl.DateTimeFormat("fr-FR", {
    day: "numeric",
    year: "numeric",
    month: "long",
  }).format(_date);

  return formattedDate;
}

/**
 * This component represents a document viewer that displays the progress of a unique document.
 * It retrieves information about the document and its progress from the server and renders it in a visual format.
 *
 * @param userId - The ID of the user for whom the document progress is being displayed.
 */
const DocU = ({ userId }: { userId: string }) => {
  // Query to fetch the unique document information
  const { data, loading, error } = useQuery(GET_DOCUMENT_UNIQUE_INFOS, {
    variables: { id: userId },
  });

  // Query to fetch the full document unique information
  const avancementInfos = useQuery(GET_FULL_DOCUMENT_UNIQUE, {
    variables: { id: userId },
  });

  // Query to fetch the work groups information
  const workGroup = useQuery(GET_WORKSGROUPS, {
    variables: {
      id: userId,
    },
  });

  // State to store the progress arrows
  const [avancementArrows, setAvancementArrows] = useState<any>({});

  // useEffect hook to update the progress arrows based on the fetched data
  useEffect(() => {
    console.log("data : ", data);
    if (!data || !avancementInfos.data || !workGroup.data) return;

    // Check if the user has any establishments
    if (data.utilisateur.etablissements.length === 0) {
      setAvancementArrows({});
    }

    // Create a progress object
    var ret = {
      validdocu:
        data.utilisateur.etablissements[0]?.donnees?.documentUnique?.isvalid,
    } as any;

    // Check if the user has any work groups
    if (
      workGroup.data?.utilisateur?.etablissements?.[0]?.donnees?.[0]?.workGroup
        ?.participants?.length !== 0
    ) {
      ret.workgroup = true;
    }

    // Check if the user has any work units
    if (
      avancementInfos.data.utilisateur.etablissements[0]?.donnees?.unitesTravail
        .length === 0
    ) {
      setAvancementArrows(ret);
      return;
    }

    ret.unitestravail = true;

    // Check if the user has any risks
    if (
      avancementInfos.data.utilisateur.etablissements[0]?.donnees
        ?.unitesTravail[0]?.risques.length === 0
    ) {
      setAvancementArrows(ret);
      return;
    }

    ret.risques = true;
    ret.descriptions = true;

    // Check if the user has any measures
    if (
      avancementInfos.data.utilisateur.etablissements[0]?.donnees
        ?.unitesTravail[0]?.risques[0]?.mesures.length === 0
    ) {
      setAvancementArrows(ret);
      return;
    }

    ret.mesures = true;
    setAvancementArrows(ret);
  }, [data, avancementInfos.data, workGroup.data]);

  // Render loading component while data is being fetched
  if (loading || avancementInfos.loading) return <LoadingComponent />;

  return (
    <div className="docu-style">
      {avancementInfos.error && <div>Error</div>}
      {error && <div>Error</div>}
      <h2 id="modal-modal-title">Avancement du document unique</h2>
      <div id="modal-modal-description">
        <Grid container spacing={2} style={{ marginTop: "50px" }}>
          <Grid item xs={1}>
            <div style={{ width: "2vh", height: "455px" }}>
              <div
                style={{
                  width: "2vh",
                  height: "50px",
                  borderBottom: "1px solid white",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "60px",
                  marginBottom: "3px",
                }}
              >
                {avancementArrows.risques && (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                )}
              </div>
              <div
                style={{
                  width: "2vh",
                  height: "50px",
                  borderBottom: "1px solid white",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "60px",
                  marginBottom: "3px",
                }}
              >
                {avancementArrows.unitestravail && (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div style={{ width: "190px", height: "455px" }}>
              <div
                style={{
                  width: "190px",
                  height: "50px",
                  backgroundColor: "#FAC090",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Risques
              </div>
              <div
                style={{
                  width: "190px",
                  height: "50px",
                  backgroundColor: "#E46D0A",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Unités de Travail
              </div>
              <div
                style={{
                  width: "190px",
                  height: "50px",
                  backgroundColor: "red",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Groupe de Travail
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <u>Mise à jour de Mon Document Unique*</u>
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              {data.utilisateur.etablissements.length > 0 &&
                formatDate(
                  data.utilisateur.etablissements[0].donnees?.documentUnique
                    ?.dateedition
                )}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <u>Mise à jour Modèle*</u>
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              {data.utilisateur?.etablissements.length > 0 &&
                formatDate(
                  data.utilisateur.etablissements[0].metier?.updatedAt
                )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="right"
              style={{ marginTop: "15px" }}
            >
              *Date de la dernière Mise à jour
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DocU;
