// App.tsx
/**
 * Ceci est le fichier principal de l'application.
 * @module App
 */

import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MobileComponent from "./components/MobileComponent";
import { MarqueBlancheProvider } from "./context/MarqueBlanche";
// import de pages
import Home from "./pages/accueil";
import Client from "./pages/admin/Client";
import ClientGC from "./pages/admin/ClientGC";
import Clients from "./pages/admin/Clients";
import ClientsGCList from "./pages/admin/ClientsGCList";
/**
 * TEST Ceci est le fichier .
 * @module AdminMetier
 */
import AdminMetier from "./pages/admin/gestionMetier";
/**
 * TEST Ceci est le fichier .
 * @module AdminMetiers
 */
import AdminMetiers from "./pages/admin/gestionMetiers";
import AdminMetierView from "./pages/admin/ViewMetier";
import Cgvu from "./pages/cgvu";
import Confidentialite from "./pages/confidentialite";
import Dashboard from "./pages/dashboard";
import FAQAdmin from "./pages/faq";
import GCClient from "./pages/grandcomptes/GCClient";
import GCClients from "./pages/grandcomptes/GCClients";
import FAQGc from "./pages/grandcomptes/gcFAQ";
import Login from "./pages/login";
import MentionsLegales from "./pages/mentions-legales";
import Register from "./pages/register";
import AffichagesReglementaires from "./pages/users/AffichagesReglementaires";
import DescriptionRisques from "./pages/users/document-unique-editor/descriptions-risques";
import Mesures from "./pages/users/document-unique-editor/mesures";
import Risques from "./pages/users/document-unique-editor/risques";
import UniteTravail from "./pages/users/document-unique-editor/unite-travail";
import WorkGroups from "./pages/users/document-unique-editor/workgroups";
import DocumentUnique from "./pages/users/DocumentUnique";
import DocumentUniqueConsultation from "./pages/users/DocumentUniqueConsultation";
import DocumentUniqueImpression from "./pages/users/DocumentUniqueImpression";
import NewsReglementaires from "./pages/users/NewsReglementaires";
import PlanActions from "./pages/users/PlanActions";
import Settings from "./pages/users/Settings";
import Support from "./pages/users/Support";
import TutosPages from "./pages/users/TutosPages";
import UserFAQ from "./pages/users/UserFAQ";
import client from "./utils/http-common";
// import de composants
import PrivateRoute from "./utils/privateRoute";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';




/**
 *@remarks Ces lignes permettent à Google Analytics de s'initier. On y trouve l'identifiant du flux Analytics
 *
 */
const TRACKING_ID = "G-W8CS3HJ0SD";
ReactGA.initialize(TRACKING_ID);

/**
 * Cette fonction gère le routing URL de l'ensemble du site
 * @remarks
 * ApolloProvider fournit une API d'accès graphql à l'ensemble des modules qu'il contient.
 *
 * PrivateRoute indique que l'accès à ces modules est réservé aux utilisateurs authentifiés
 *
 * EXEMPLE :
 *
 * si l'URL correspond à /mon-document-unique, l'utilisateur sera renvoyé vers l'affichage du module DocumentUnique.
 *
 * Puisque dans PrivateRoute, il sera donc necessaire que l'utilisateur soit authentifié
 *
 * Le tout est bien inclus dans AppoloProvider, DocumentUnique aura donc accès aux requêtes GraphQL
 * @returns Retourne le bon module selon l'URL
 */
function App() {
  /**
   * UseEffect permet de déclencher des effets parallèles au programme principal.
   * Ici, l'URL des pages naviguées est envoyé à Google Analytics
   * @category Google Analytics
   */
  function myEffect() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  // const save = useRef<any>(null);

  // const { data, loading, error } = useQuery(MUTE_PLAN_ACTION_PREPARE, {
  //   fetchPolicy: "no-cache",
  //   variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
  // });

  // const planActionRes = useQuery(GET_PLAN_ACTION, {
  //   skip: true,
  //   variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
  // });

  useEffect(myEffect, []);
  return (
    <ApolloProvider client={client}>
        <ToastContainer/>
      <Router>
        <MarqueBlancheProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cgvu" element={<Cgvu />} />
            <Route path="/confidentialite" element={<Confidentialite />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/mobile" element={<MobileComponent />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/faq"
              element={
                <PrivateRoute>
                  <FAQAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="/metiers"
              element={
                <PrivateRoute>
                  <AdminMetiers />
                </PrivateRoute>
              }
            />
            <Route
              path="/metier"
              element={
                <PrivateRoute>
                  <AdminMetier />
                </PrivateRoute>
              }
            />
            <Route
              path="/metier-view"
              element={
                <PrivateRoute>
                  <AdminMetierView />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <PrivateRoute>
                  <Clients />
                </PrivateRoute>
              }
            />
            <Route
              path="/client"
              element={
                <PrivateRoute>
                  <Client />
                </PrivateRoute>
              }
            />
            <Route
              path="/clientgc"
              element={
                <PrivateRoute>
                  <ClientGC />
                </PrivateRoute>
              }
            />
            <Route
              path="/clientsgclist"
              element={
                <PrivateRoute>
                  <ClientsGCList />
                </PrivateRoute>
              }
            />
            <Route
              path="/mes-clients"
              element={
                <PrivateRoute>
                  <GCClients />
                </PrivateRoute>
              }
            />
            <Route
              path="/mon-client"
              element={
                <PrivateRoute>
                  <GCClient />
                </PrivateRoute>
              }
            />
            <Route
              path="/mon-document-unique"
              element={
                <PrivateRoute>
                  <DocumentUnique />
                </PrivateRoute>
              }
            />
            <Route
              path="/consulter-mon-document-unique"
              element={
                <PrivateRoute>
                  <DocumentUniqueConsultation />
                </PrivateRoute>
              }
            />

            <Route
              path="/unites-de-travail"
              element={
                <PrivateRoute>
                  <UniteTravail />
                </PrivateRoute>
              }
            />
            <Route
              path="/editer-mon-document-unique"
              element={
                <PrivateRoute>
                  <WorkGroups />
                </PrivateRoute>
              }
            />
            <Route
              path="/selection-des-risques"
              element={
                <PrivateRoute>
                  <Risques />
                </PrivateRoute>
              }
            />
            <Route
              path="/description-des-risques"
              element={
                <PrivateRoute>
                  <DescriptionRisques />
                </PrivateRoute>
              }
            />
            <Route
              path="/mesures-preventives"
              element={
                <PrivateRoute>
                  <Mesures />
                </PrivateRoute>
              }
            />
            <Route
              path="/imprimer-mon-document-unique"
              element={
                <PrivateRoute>
                  <DocumentUniqueImpression />
                </PrivateRoute>
              }
            />
            <Route
              path="/unites-de-travail"
              element={
                <PrivateRoute>
                  <UniteTravail />
                </PrivateRoute>
              }
            />
            <Route
              path="/selection-des-risques"
              element={
                <PrivateRoute>
                  <Risques />
                </PrivateRoute>
              }
            />
            <Route
              path="/description-des-risques"
              element={
                <PrivateRoute>
                  <DescriptionRisques />
                </PrivateRoute>
              }
            />
            <Route
              path="/mesures-preventives"
              element={
                <PrivateRoute>
                  <Mesures />
                </PrivateRoute>
              }
            />
            <Route
              path="/mon-plan-dactions"
              element={
                <PrivateRoute>
                  <PlanActions />
                </PrivateRoute>
              }
            />
            <Route
              path="/mes-affichages-reglementaires"
              element={
                <PrivateRoute>
                  <AffichagesReglementaires />
                </PrivateRoute>
              }
            />

            <Route
              path="/demarche-generale"
              element={
                <PrivateRoute>
                  <TutosPages title="Démarche générale" url="https://www.youtube.com/embed/yTh3OpXKHBw/" />
                </PrivateRoute>
              }
            />
            <Route
              path="/document-unique"
              element={
                <PrivateRoute>
                  <TutosPages title="Document Unique" url="https://www.youtube.com/embed/uUk3GSGX404/" />
                </PrivateRoute>
              }
            />
            <Route
              path="/plan-actions"
              element={
                <PrivateRoute>
                  <TutosPages title="Plan d’actions" url="https://www.youtube.com/embed/RVwZ6KNxf5c/"/>
                </PrivateRoute>
              }
            />
            <Route
              path="/affichages-reglementaires"
              element={
                <PrivateRoute>
                  <TutosPages title="Affichages réglementaires" url="https://www.youtube.com/embed/4RmXDu56dcA/" />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-reglementaires"
              element={
                <PrivateRoute>
                  <NewsReglementaires />
                </PrivateRoute>
              }
            />
            <Route
              path="/mon-compte"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/faq-utilisateur"
              element={
                <PrivateRoute>
                  <UserFAQ />
                </PrivateRoute>
              }
            />
            <Route
              path="/gcfaq"
              element={
                <PrivateRoute>
                  <FAQGc />
                </PrivateRoute>
              }
            />
            <Route
              path="/aide-et-support"
              element={
                <PrivateRoute>
                  <Support />
                </PrivateRoute>
              }
            />
          </Routes>
        </MarqueBlancheProvider>
      </Router>
    </ApolloProvider>
  );
}

export default App;
