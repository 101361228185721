import { gql } from "@apollo/client";

const GET_WORKSGROUPS = gql`
  query ($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        donnees {
          workGroup {
            id
            createdAt
            participants {
              id
              workgroupID
              fonction
              nom
            }
          }
        }
      }
    }
  }
`;

const PUT_WORKSGROUPS = gql`
  mutation ($id: String!, $participants: [ParticipantsInput!]!) {
    workGroup {
      add(entrepriseID: $id, participants: $participants) {
        id
      }
    }
  }
`;

const DEL_WORKSGROUPS = gql`
  mutation ($id: String!, $participants: [String!]!) {
    workGroup {
      delete(entrepriseID: $id, participants: $participants) {
        id
      }
    }
  }
`;

export { GET_WORKSGROUPS, PUT_WORKSGROUPS, DEL_WORKSGROUPS };
