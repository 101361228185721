import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// imgs
import Logo from "../assets/images/logo.png";

// icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

// css personnalisés
import "../assets/css/accueil/accueil.css";
import "../assets/css/accueil/accueilAboutUs.css";
import "../assets/css/accueil/accueilNavbar.css";
import "../assets/css/accueil/accueilFooter.css";
import { Typography } from "@mui/material";

const MentionsLegales = () => {
  return (
    <div>
      {/* Navbar */}
      <nav className="navbarHome">
        <ul>
          <li>
            <a href="./">
              <img src={Logo} alt="Logo" />
            </a>
          </li>
          <li>
            <a href="#section-1">Accueil</a>
          </li>
          <li>
            <a href="#section-2">A Propos</a>
          </li>
          <li>
            <a href="#section-3">Nos Plus</a>
          </li>
          <li>
            <a href="#section-4">Solution</a>
          </li>
          <li>
            <a href="#section-5">Contact</a>
          </li>
          <li>
            <button
              className="loginButton"
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              SE CONNECTER
            </button>
          </li>
        </ul>
      </nav>

      {/* Section 2 */}
      <section id="section-2" className="section2">
        <div className="text-legal">
          <h1>Mentions légales</h1>
          <div className="line-accueil"></div>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            Bienvenue sur le site internet www.securiz.org
          </Typography>
          <Typography variant="body1">
            En vous connectant ou en utilisant le site, vous reconnaissez avoir
            lu, compris et accepté, sans limitation ni réserve, les présentes
            conditions générales d’utilisation et notre charte sur la protection
            des données à caractère personnel.
          </Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            AT CONSULTING
          </Typography>
          <Typography variant="body1">
            Le présent site est la propriété de la SAS AT CONSULTING au capital
            de 1 000 €, inscrite au registre du commerce et des sociétés de
            Toulouse, sous le numéro 885 264 721, dont le siège social est situé
            : 31, allée du camp de Catis – 31840 SEILH – France.<br></br>
            Tél. 05 36 09 07 00<br></br>
            Nous contacter : https://www.shorturl.at/isxy5<br></br>
            Président : M. Arnaud SELLIER-BORDAS<br></br>
            Responsable de la publication : M. Arnaud SELLIER-BORDAS<br></br>
          </Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Conception technique et développement du site internet
          </Typography>
          <Typography variant="body1">
            AT CONSULTING &<br></br>
            ILAN CHEKROUN<br></br>
            Siège Social : 33 RUE DE LA REYNIE 81370 SAINT-SULPICE-LA-POINTE,
            France<br></br>
            EI<br></br>
            Registre du commerce et des sociétés de Toulouse sous le numéro 838
            110 690<br></br>
          </Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Hébergement
          </Typography>
          <Typography variant="body1">
            Le site est hébergé par la société OVH
            <br></br>
            Siège social : 2 rue kellermann BP 80157 59053 ROUBAIX CEDEX 1
            <br></br>
            SAS au capital de 10 174 560,00 €<br></br>
            Registre du commerce de et des sociétés de LILLE METROPOLE 424 761
            419 0004
            <br></br>
            Site : https://www.ovhcloud.com/fr/<br></br>
          </Typography>
          <Typography variant="body1">
            Les bases de données sont hébergées par la société AMAZON WEB
            SERVICES EMEA SARL
            <br></br>
            Siège social : 38 AV JOHN F KENNEDY L 1855 99137 LUXEMBOURG<br></br>
            Société de droit étranger<br></br>
            Capital de 25 000 euros<br></br>
            Registre du commerce de et des sociétés du Luxembourg 831 001 334
            <br></br>
            Site : https://aws.amazon.com/fr/<br></br>
          </Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Informations sur nos activités
          </Typography>
          <Typography variant="body1">
            AT CONSULTING a mis en ligne ce site afin de vous présenter
            certaines de ses activités et de vous proposer de souscrire à
            l’outil SECURIZ pour réaliser votre Document Unique et générer vos
            Affichages Réglementaires. Les informations et recommandations
            disponibles sur le site (« Informations ») sont proposées de bonne
            foi sans aucune garantie d’aucune sorte, que ce soit expressément ou
            tacitement. Elles sont censées être correctes au moment où elles
            sont publiées sur le site et ont uniquement pour but de fournir des
            renseignements d’ordre général.<br></br>
            <br></br>
            AT CONSULTING ne garantit pas le caractère exhaustif et l’exactitude
            de ces Informations. Vous assumez pleinement les risques liés au
            crédit que vous leur accordez. Ces Informations vous sont fournies à
            la condition que vous ou toute autre personne qui les recevez
            puissiez déterminer leur intérêt pour un objectif précis avant de
            les utiliser. AT CONSULTING a fait des efforts raisonnables pour
            s’assurer que l’information dispensée sur le site était actualisée
            au moment de son inclusion mais décline toute responsabilité pour ce
            qui est de toute obligation de mise à jour, ou pour toute omission,
            imprécision ou erreur typographique.<br></br>
            <br></br>
            L’information contenue dans ce portail peut être modifiée, corrigée
            et/ou complétée à tout moment sans avertissement. En aucun cas, AT
            CONSULTING ne sera responsable des dommages susceptibles de résulter
            du crédit accordé à ces Informations ou de leur utilisation. Ces
            Informations ne doivent pas être considérées comme des
            recommandations pour l’utilisation d’informations, de produits, de
            procédures, d’équipements ou de formulations qui seraient en
            contradiction avec un brevet, un copyright ou une marque déposée. AT
            CONSULTING décline toute responsabilité, expresse ou implicite, si
            l’utilisation de ces Informations venait à contrevenir à un brevet,
            un copyright ou une marque déposée.<br></br>
            <br></br>
            AT CONSULTING ne sera pas responsable envers un quelconque
            utilisateur ou envers tout tiers pour tout dommage direct, indirect,
            immatériel ou particulier ou pour toute perte quelque que soit leur
            cause, leur nature ou leurs conséquences, y compris, et ce sans
            limitation aucune, de toute perte de profits, perte de programmes ou
            de toute autre sorte de données subies par le système de gestion
            d’information de l’utilisateur ou de tout autre système.<br></br>
            <br></br>
            AT CONSULTING et ses filiales directes ou indirectes n’assument
            aucune responsabilité pour de tels dommages directs, indirects,
            immatériels ou particuliers qui pourraient avoir pour cause un
            empêchement à utiliser ce portail, ou bien à y accéder, ou bien
            encore à avoir accès à tout site Web relié, quelle que soit
            l’information contenue dans ce portail ou dans le site Web relié par
            lien hypertexte, et ce que AT CONSULTING ait eu connaissance ou non
            de la possibilité de tels dommages.<br></br>
          </Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Propriété intellectuelle
          </Typography>
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            <b>Principes</b>
            <br></br>
            L’intégralité du site, à savoir le logiciel, le contenu, les textes,
            graphismes, les vidéos, les images en direct et les images fixes,
            les sons, les icones, les procédés, le produit, la technologie, les
            documents téléchargeables et le savoir-faire en général ainsi que
            tous les autres éléments qui font partie du site sont la propriété
            exclusive de AT CONSULTING ou de ses donneurs de licence. Toute
            utilisation totale ou partielle, copie, représentation ou
            reproduction, publication, téléchargement, affichage, transmission
            ou diffusion de ce portail sous quelque forme que ce soit, sans
            l’autorisation expresse de AT CONSULTING, est interdite et sera
            considérée comme une atteinte aux dispositions du Code Français de
            la Propriété Intellectuelle (article L.122-4), sous réserve d’être
            expressément autorisé par la loi applicable.<br></br>
            <br></br>
            <b>Signes distinctifs</b>
            <br></br>
            De même, il est strictement interdit d’utiliser ou de reproduire le
            nom AT CONSULTING et/ou son logo AT CONSULTING, le nom SECURIZ et/ou
            son logo SECURIZainsi que toute version dérivée de ceux-ci. Ces
            marques/logos sont protégés par le droit d’auteur et objet
            d’enregistrements auprès des offices de Propriété Intellectuelle.
            Toute reproduction totale ou partielle de telles marques
            commerciales et/ou de tels logos qui serait faite sans
            l’autorisation expresse de AT CONSULTING est interdite conformément
            aux dispositions du Code français de la Propriété Intellectuelle.
            Cette restriction (règle) s’applique également aux marques et/ou
            logo des partenaires commerciaux de AT CONSULTING présentes sur le
            portail.
            <br></br>
            <br></br>
            <b>Non-respect</b>
            <br></br>
            Le non-respect de ces interdictions précitées constitue un acte de
            contrefaçon pouvant engager la responsabilité civile et/ou pénale de
            son auteur. AT CONSULTING se réserve le droit d’engager des
            poursuites judiciaires à l’encontre de toute personne qui n’aurait
            pas respecté cette interdiction.<br></br>
            <br></br>
            <b>Engagement de l’utilisateur</b>
            <br></br>
            Chaque utilisateur du site qui fournit des informations consent à AT
            CONSULTING l’intégralité des droits transférables relatifs à cette
            information et autorise AT CONSULTING à en faire usage. Les
            informations ainsi fournies par les utilisateurs seront considérées
            comme non confidentielles. Toutefois, lorsque les informations
            fournies sont des données personnelles au sens du droit applicable,
            elles sont traitées conformément à la charte sur la protection des
            données à caractère personnel applicable à ce site. Chaque
            utilisateur du site déclare se conformer aux présentes conditions
            générales d’utilisation et lois en vigueur, en particulier : –
            disposer de la compétence et des moyens nécessaires pour accéder au
            site et l’utiliser ; – avoir vérifié que la configuration
            informatique utilisée ne contient aucun virus et qu’elle est en
            parfait état de fonctionnement ; – consentir à AT CONSULTING et à
            ses partenaires le cas échéant le droit de faire tout usage des
            informations fournies (autres que des données à caractère personnel)
            ;<br></br>– devoir conserver confidentiels et être en conséquence
            responsable de l’utilisation et de la sécurité des codes d’accès et
            mots de passe que AT CONSULTING peut vous transmettre pour accéder à
            certaines rubriques. AT CONSULTING se réserve le droit de suspendre
            votre accès au site en cas d’utilisation frauduleuse ou de tentative
            d’utilisation frauduleuse de cet accès.<br></br>
            <br></br>
            <b>Liens hypertextes</b>
            <br></br>
            <br></br>
            <b>Navigation</b>
            <br></br>
            Les sites Web d’une tierce partie que l’utilisateur utilisera pour
            avoir accès à ce portail ne sont pas sous le contrôle de AT
            CONSULTING. En conséquence, AT CONSULTING ne prend aucun engagement
            de quelque nature que ce soit et décline toute responsabilité pour
            ce qui est du contenu et de l’utilisation quelle qu’elle soit des
            sites Web ainsi reliés ou de tout lien contenu dans un site relié,
            ou pour toute modification ou mise à jour de tels sites Web.
            <br></br>
            <br></br>
            <b>Activation des liens</b>
            <br></br>
            AT CONSULTING décline formellement toute responsabilité quant aux
            contenus des sites vers lesquels elle offre des liens. Ces liens
            sont proposés aux utilisateurs de ce site et nous attirons votre
            attention sur le fait que dès lors qu’un Utilisateur a quitté le
            site, AT CONSULTING n’a aucun contrôle sur cet autre site et que
            celui-ci n’est pas soumis à la notice sur la protection des données
            personnelles de AT CONSULTING.<br></br>
            Par conséquent, AT CONSULTING ne saurait être responsable de la
            protection ni du caractère privé de toute information que
            l’utilisateur fournit lors de la visite de cet autre site.
            L’utilisateur est invité à contrôler lui-même la politique de
            protection des données personnelles de tout autre site Web. La
            décision d’activer les liens appartient exclusivement aux
            utilisateurs du site. AT CONSULTING peut modifier ou supprimer un
            lien sur le site à tout moment.<br></br>
            <br></br>
            <b>Autorisation des liens</b>
            <br></br>
            Si vous souhaitez créer un lien hypertexte avec le site, vous devez
            obtenir l’autorisation écrite et préalable de AT CONSULTING en
            utilisant les coordonnées de contact mentionnées à la fin de ce
            document.<br></br>
            <br></br>
            <b>Accès au site</b>
            <br></br>
            AT CONSULTING ne garantit absolument pas que l’accès au site ne sera
            pas interrompu, que le site sera sécurisé, n’aura pas de virus ou
            tout autre composant nuisible ou que l’information donnée sur le
            site sera à tout instant exhaustive, exacte et à jour. En règle
            générale, il est possible d’avoir accès au site 24 heures sur 24 et
            7 jours sur 7. Cependant, AT CONSULTING est en droit, à sa seule
            discrétion, de suspendre, d’interrompre ou d’annuler l’accès à tout
            ou partie du portail dans les cas suivants mais dont la liste n’est
            pas limitative : force majeure, problèmes informatiques, difficultés
            en rapport avec la structure du réseau de télécommunications ou
            difficultés d’ordre technique, ou pour des raisons ayant trait à la
            maintenance.<br></br>
            L’information contenue dans ce portail peut être modifiée, corrigée
            et/ou complétée à tout moment sans avertissement.<br></br>
            <br></br>
            <b>
              Mises à jour des conditions d’utilisation du site internet et loi
              applicable
            </b>
            <br></br>
            AT CONSULTING peut procéder à la mise à jour de ces conditions
            d’utilisation à tout moment. En conséquence, vous êtes invités à
            vous référer régulièrement aux dernières conditions d’utilisation en
            vigueur. Ces conditions d’utilisation sont soumises au droit
            applicable et relèvent de la compétence des tribunaux compétents.
            <br></br>
            <br></br>
            <b>Coordonnées de contact</b>
            <br></br>
            Pour toute question relative aux conditions d’utilisation du site,
            veuillez nous contacter par email via le formulaire de contact.
            <br></br>
            <br></br>
            <b>Loi informatique et libertés</b>
            <br></br>
            En application de la loi Informatique et Liberté du 6 janvier 1978,
            la personne communiquant des informations personnelles auprès de la
            société AT CONSULTING, dispose d’un droit d’accès, de modification,
            de rectification ou de suppression des données le concernant. Ces
            droits sont exerçables en contactant l’entreprise par courrier à
            l’adresse 31, allée du camp de Catis – 31840 SEILH – France.
            <br></br>
            <br></br>
          </Typography>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footerLogo">
          <img src={Logo} alt="Logo" />
          <p>Ⓒ 2023 , AT Consulting, Tous droits réservés</p>
        </div>
        <div className="footerRight">
          <div className="footerLinks">
            <a href="./mentions-legales">Mentions légales</a>
            <a href="./confidentialite">Confidentialité</a>
            <a href="./cgvu">CGVU</a>
          </div>
          <div className="footerIcons">
            <a href="https://www.linkedin.com/">
              <LinkedInIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
            <a href="https://www.facebook.com/">
              <FacebookIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
            <a href="https://www.youtube.com/">
              <YouTubeIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MentionsLegales;
