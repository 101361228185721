import { useState } from "react";
import userFaqGeneral from "../../assets/jsons/userFaqGeneral.json";
import userFaqAffichages from "../../assets/jsons/userFaqAffichages.json";
import userFaqDU from "../../assets/jsons/userFaqDU.json";
import userFaqPA from "../../assets/jsons/userFaqPA.json";
import "../../assets/css/faq.css";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";
import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  faq: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
  },
});

const UserFAQ = () => {
  const [faqDataGeneral] = useState(userFaqGeneral);
  const [faqDataAffichages] = useState(userFaqAffichages);
  const [faqDataDU] = useState(userFaqDU);
  const [faqDataPA] = useState(userFaqPA);
  return (
    <div className="dashboard">
      <Navbar type="user" />
      <div className="dashboard-content">
        <TopBar />
        <div>
          <h1>Questions générales</h1>
          <div className="pageLine"></div>
          <div style={styles.faq}>
            {faqDataGeneral.questions.map((question, index) => (
              <div key={index} className="question">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<i className="fas fa-chevron-down"></i>}
                    aria-controls={`question${index + 1}-content`}
                    id={`question${index + 1}-header`}
                  >
                    <Typography variant="h6" className="question-header">
                      {question.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1">{question.reponse}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
          <div>
            <h1>Questions Affichages</h1>
            <div className="pageLine"></div>
            <div style={styles.faq}>
              {faqDataAffichages.questions.map((question, index) => (
                <div key={index} className="question">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down"></i>}
                      aria-controls={`question${index + 1}-content`}
                      id={`question${index + 1}-header`}
                    >
                      <Typography variant="h6" className="question-header">
                        {question.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body1">
                        {question.reponse}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </div>
            <div>
              <h1>Questions Document Unique</h1>
              <div className="pageLine"></div>
              <div style={styles.faq}>
                {faqDataDU.questions.map((question, index) => (
                  <div key={index} className="question">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down"></i>}
                        aria-controls={`question${index + 1}-content`}
                        id={`question${index + 1}-header`}
                      >
                        <Typography variant="h6" className="question-header">
                          {question.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body1">
                          {question.reponse}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h1>Questions Plan d'Actions</h1>
              <div className="pageLine"></div>
              <div style={styles.faq}>
                {faqDataPA.questions.map((question, index) => (
                  <div key={index} className="question">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down"></i>}
                        aria-controls={`question${index + 1}-content`}
                        id={`question${index + 1}-header`}
                      >
                        <Typography variant="h6" className="question-header">
                          {question.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body1">
                          {question.reponse}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>

            <button
              className="importButton"
              style={{
                border: "none",
                width: "20%",
                alignSelf: "center",
              }}
              onClick={() => {
                window.location.href = "/aide-et-support";
              }}
            >
              Je n'ai pas trouvé ma réponse
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFAQ;
