import Navbar from "../../components/navbar";
import TopBar from "../../components/TopBar";

import "../../assets/css/news.css";
import { useEffect, useState } from "react";
import API_URL from "../../utils/api";

type News = {
  title: string;
  description: string;
  link: string;
  pubDate: string;
  guid: string;
};

const NewsReglementaires = () => {
  const [news, setNews] = useState([] as News[]);

  useEffect(() => {
    const fetch = require("node-fetch");

    let url = `${API_URL}/rss`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/xml",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res: { text: () => any }) => res.text())
      .then((xmlString: string) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlString, "text/xml");
        const news = xml.querySelectorAll("item");
        const newsArray:
          | ((prevState: never[]) => never[])
          | {
              title: string;
              description: string;
              link: string;
              pubDate: string;
              guid: string;
            }[] = [];
        news.forEach((item: any) => {
          newsArray.push({
            title: item.querySelector("title").innerHTML,
            description: item.querySelector("description").innerHTML,
            link: item.querySelector("link").innerHTML,
            pubDate: item.querySelector("pubDate").innerHTML,
            guid: item.querySelector("guid").innerHTML,
          });
        });
        console.log(newsArray);
        setNews(newsArray);
      })
      .catch((err: string) => console.error("error:" + err));
  }, []);

  return (
    <div>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>INRS News</h1>
          <div className="pageLine"></div>
          <div className="content">
            <div className="news">
              <h2>Dernières news de l'INRS :</h2>
              <div className="news-content">
                {news.map((item: any, index: number) => {
                  return (
                    <div className="news-item" key={item}>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                      <p style={{ fontSize: "0.8rem", color: "grey" }}>
                        {item.pubDate}
                      </p>
                      <a href={item.link}>{item.link}</a>
                      {index !== news.length - 1 && (
                        <div
                          className="line"
                          style={{
                            width: "20%",
                            alignSelf: "flex-start",
                            margin: "30px 0 0 0",
                          }}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsReglementaires;
