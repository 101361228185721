import { Card } from "@mui/material";
import Navbar from "../../components/navbar";
import TopBar from "../../components/TopBar";
import DocUUsers from "../../components/docU-users";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/userdashboard.css";

import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../queries/users";
import {
  GET_DOCUMENT_UNIQUE_INFOS,
  GET_FULL_DOCUMENT_UNIQUE,
} from "../../queries/documentUnique";
import { USER_ID_LS_KEY } from "../../queries/uniteTravail";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { GET_PLAN_ACTION_DATE } from "../../queries/planAction";
import LoadingComponent from "../../components/Loading";
import { reformatDatas } from "../../utils/formatDatas";

import RisqueCalcul from "../../utils/RisqueCalcul";
import ErrorComponent from "../../components/ErrorComponent";

import GestionPriorite from "../../components/GestionPriorite";
import GraphActionsComponent from "../../components/GraphiqueActions";
import fetchInrsData from "../../components/FetchInrsData";
import useActionDatas from "../../hooks/UserDashBoard/useActionDatas";
import OwlCarousel, { OwlCarouselProps } from "react-owl-carousel";

// caroussel options
const options: Omit<OwlCarouselProps, "children"> = {
  loop: true,

  center: true,
  nav: false,
  dots: false,
  margin: 10,
  autoplayHoverPause: false,

  autoplay: true,
  autoplayTimeout: 5000, // délai en millisecondes
  autoplaySpeed: 4000,
  smartSpeed: 4000,
  responsive: {
    0: {
      items: 1,
    },
    502: {
      items: 1,
    },
    768: {
      items: 1,
    },
    1024: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  },
};

// define props interface
interface IUsersDashboardProps {
  username: string;
}

const UsersDashboard = ({ username }: IUsersDashboardProps) => {
  const [priorites1, setPriorites1] = useState<any>([]);
  const [priorites2, setPriorites2] = useState<any>([]);
  const [priorites, setPriorites] = useState<any>([]);
  const { loading, error } = useQuery(GET_USERS);
  const {actions, loading: actionDataloading, error: actionDataError, dateedition} = useActionDatas(priorites);

  const [formattedData, setFormattedData] = useState<any>(null);
  useQuery(GET_FULL_DOCUMENT_UNIQUE, {
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
    onCompleted(data) {

      setFormattedData(reformatDatas(data));
      console.log("DATAS BRUTES : " + data);
    },
    fetchPolicy: "no-cache",
  });
  const documentUniqueInfos = useQuery(GET_DOCUMENT_UNIQUE_INFOS, {
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const infos = data.utilisateur?.etablissements.find(
        (etablissement: any) => etablissement.id === entrepriseID
      );
      if (infos) {
        setEntreprise(infos);
      }
    },
  });
  const [entreprise, setEntreprise] = useState<any>(null);
  const [entrepriseID, setEntrepriseID] = useState<string | null>(null);

  const news : any[]= [];// [{title: "Une nouvelle nouveauté", link: "https://google.com"}, {title: "Une autre nouveauté", link: "https://google.com"}];
  useEffect(() => {
    // get "idEntreprise" from local storage
    const idEntreprise = localStorage.getItem("idEntreprise");
    setEntrepriseID(idEntreprise);
    fetchInrsData();
  }, []);

  useEffect(() => {}, [documentUniqueInfos.data, entrepriseID]);

  useEffect(() => {
    let _priorites1: any = [];
    let _priorites2: any = [];

    if (formattedData) {
      formattedData.map((ut: any) =>
        ut?.risques?.map((risque: any) =>
          risque.descriptions.map((description: any) => {
            const priorite1 = RisqueCalcul.getPriorite(description);

            if (priorite1 === 1 && !_priorites1.includes(ut.libelle)) {
              _priorites1.push(ut.libelle);
            }
          })
        )
      );
      formattedData.map((ut: any) =>
        ut?.risques?.map((risque: any) =>
          risque.descriptions.map((description: any) => {
            const priorite2 = RisqueCalcul.getPriorite(description);

            if (
              priorite2 === 2 &&
              !_priorites1.includes(ut.libelle) &&
              !_priorites2.includes(ut.libelle)
            ) {
              _priorites2.push(ut.libelle);
            }
          })
        )
      );
    }

    _priorites1.sort();
    _priorites2.sort();

    setPriorites1(_priorites1);
    setPriorites2(_priorites2);
  }, [formattedData]);

  useEffect(() => {

    if (formattedData) {
      setPriorites(RisqueCalcul.getPriorites(formattedData));
    }
  }, [formattedData]);

  console.log("prios 1 :", priorites1);
  console.log("prios 2 :", priorites2);

  if (loading) return <LoadingComponent />;
  if (error)
    return (
      <p>
        Error :(
        {error.message})
      </p>
    );

  if (documentUniqueInfos.loading) return <LoadingComponent />;
  //if (documentUniqueInfos.error) return <ErrorComponent />;
  if (actionDataloading) return <LoadingComponent />;
  if (actionDataError)
    return <ErrorComponent error={actionDataError} />;
  // console.log("data :", avancementInfos.data);

  return (
    <div>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Tableau de bord</h1>
          <div className="pageLine"></div>
          <div className="content">
            <CookieConsent
              location="bottom"
              buttonText="J'accepte"
              cookieName="securizanalytics"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              Nous collectons des statistiques anonymes sur les pages consultées
              sur ce site. <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
            <div className="user-dashboard">
              <DocUUsers
                entrepriseID={entrepriseID}
                etablissement={entreprise}
              />
              <div className="user-dashboard-right">
                <Card
                  id="gestionpriorite"
                  className="user-dashboard-right-card1"
                  style={{ marginBottom: "20px" }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        height: "80px",
                        width: "80px",
                        background: "linear-gradient(60deg, #ef5350, #e53935)",
                        float: "left",
                        borderRadius: "5px",
                        marginTop: "-30px",
                        boxShadow:
                          "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(244, 67, 54,.4)",
                      }}
                    >
                      <PriorityHighIcon
                        style={{
                          color: "white",
                          fontSize: "3rem",
                          textAlign: "center",
                          margin: "15px",
                        }}
                      ></PriorityHighIcon>
                    </div>
                    <h2 style={{ textAlign: "center", marginTop: "10px" }}>
                      Gestion des priorités
                    </h2>
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    <span style={{ marginLeft: "10px" }}>Priorité 1</span>
                  </div>
                  <GestionPriorite priorites={priorites1} />
                  <span style={{ marginLeft: "10px" }}>Priorité 2</span>
                  <GestionPriorite priorites={priorites2} />
                </Card>

                <GraphActionsComponent
                  planActionData={actions}
                  dateedition={dateedition}
                />
              </div>
            </div>
          </div>
          <Card className="user-dashboard-regle-card">
            <h2 style={{ textAlign: "center", marginTop: "10px" }}>
              Evolution Affichages Réglementaires :
            </h2>
           {news.length === 0 ? <div className="user-dashboard-regle-card-content">
              <p>Pas de nouveauté</p>
            </div> :
            <OwlCarousel {...options} className="inrscaroussel">
                {news.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <p
                        style={{
                          color: "black",
                        }}
                      >
                        {item.title}
                      </p>
                      <a
                        href={item.link}
                        target="_blank"
                        style={{ color: "#3f51b5", textDecoration: "none" }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.textDecoration = "underline";
                        }}
                      >
                        En savoir plus
                      </a>
                    </div>
                  );
                })}
              </OwlCarousel>}
          </Card>
          <div className="line"></div>
       
        </div>
      </div>
    </div>
  );
};

export default UsersDashboard;
