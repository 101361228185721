import { CognitoUserPool } from "amazon-cognito-identity-js";

const config = {
  region: process.env.REACT_APP_CGO_GRPUSR_ZONE,
  UserPoolId: process.env.REACT_APP_CGO_GRPUSR_ID,
  ClientId: process.env.REACT_APP_CGO_GRPUSR_CID,
};

console.log({config})

const userPool = new CognitoUserPool(config);

export default userPool;