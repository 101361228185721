import React, { useState } from "react";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";
import "../../assets/css/support.css";

const Support = () => {
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");

  const handleSubmit1 = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // envoyer le message par mail à arnaud.sellier@atconsulting.fr  avec l'objet "Demande de support Secure"
    window.open(
      `mailto:arnaud.sellier@atconsulting.fr ?subject=${encodeURIComponent(
        "Securiz - Démarche"
      )}&body=${encodeURIComponent(message1)}`
    );
  };
  const handleSubmit2 = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // envoyer le message par mail à arnaud.sellier@atconsulting.fr  avec l'objet "Demande de support Secure"
    window.open(
      `mailto:arnaud.sellier@atconsulting.fr ?subject=${encodeURIComponent(
        "Securiz - Support Technique"
      )}&body=${encodeURIComponent(message2)}`
    );
  };

  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Aide & Support</h1>
          <div className="pageLine"></div>
          <div className="support-content">
            <div className="support-right">
              <div className="support-title">Questions Fréquentes</div>
              <p>
                Consultez les questions fréquentes et trouver une réponse à
                votre question, cliquez sur le bouton ci-dessous.
              </p>
              <button
                className="style-button"
                onClick={() => {
                  window.location.href = "/faq-utilisateur";
                }}
              >
                QUESTIONS FREQUENTES
              </button>
            </div>

            <div className="support-left">
              <div className="pageLine"></div>
              <div className="support-title">Contactez votre expert SST</div>
              <form
                onSubmit={handleSubmit1}
                style={{
                  width: "100%",
                }}
              >
                <p>
                  Vous pouvez poser ici une question sur la réglementation, la
                  Santé Sécurité au Travail ainsi que demander conseil sur la
                  mise en place des mesures de prévention. Il vous reste 5
                  questions.
                </p>
                <textarea
                  className="style-textarea"
                  placeholder="Votre message"
                  value={message1}
                  onChange={(e) => setMessage1(e.target.value)}
                ></textarea>
                <p
                  style={{
                    color: "#5a5a5a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Notre engagement : réponse en 5 jours ouvrés maximum.
                </p>
                <button type="submit" className="style-button">
                  Envoyer
                </button>
              </form>
              <div className="pageLine"></div>
            </div>
            <div className="support-left">
              <div className="support-title">
                Contactez votre support technique
              </div>
              <form
                onSubmit={handleSubmit2}
                style={{
                  width: "100%",
                }}
              >
                <p>
                  Vous pouvez solliciter ici nos équipes informatiques pour un
                  support technique. Ces équipes ne pourront pas vous renseigner
                  sur l'aspect réglementaire.
                </p>
                <textarea
                  className="style-textarea"
                  placeholder="Votre message"
                  value={message2}
                  onChange={(e) => setMessage2(e.target.value)}
                ></textarea>
                <p
                  style={{
                    color: "#5a5a5a",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Notre engagement : réponse en 2 jours ouvrés maximum.
                </p>
                <button type="submit" className="style-button">
                  Envoyer
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
