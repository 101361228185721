import { useEffect, useState } from "react";
import {
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import "../assets/css/login.css";


import { useQuery } from "@apollo/client";
import { GET_USER } from "../queries/user";

import { useLocation } from "react-router-dom";
import { useMarqueBlanche } from "../context/MarqueBlanche";
import userPool from "../utils/userPool";



const Login = () => {
  const userQuery = useQuery(GET_USER, {
    skip: true,
  });

  const location = useLocation();
  

  const [, setTitre] = useState<string>("SECURIZ ®"); // Titre par défaut
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [verificationCodeModalOpen, setVerificationCodeModalOpen] =
    useState(false);
  const [, setEmail] = useState("");
  const [emailVerification, setEmailVerification] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const {isMarqueBlanche, logoLoginPath} = useMarqueBlanche();
 
  

  const handleLogin = (event: any) => {
    event.preventDefault();

    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        // set token in local storage
        cognitoUser.getSession((err: any, session: any) => {
          if (err) {
            console.log(err);
          } else {
            localStorage.setItem("token", session.getIdToken().getJwtToken());
            console.log("username :", cognitoUser.getUsername());
            userQuery
              .refetch({
                id: cognitoUser.getUsername(),
              })
              .then((res) => {
                console.log("res : ", res);
                localStorage.setItem(
                  "idEntreprise",
                  res.data.utilisateur.etablissements[0].id
                );
                // L'utilisateur est connecté
                window.location.href = "/dashboard";
              })
              .catch((err) => {
                console.log("err : ", err);
              });
          }
        });
      },
      onFailure: (error) => {
        // La connexion a échoué
        console.log(error);
        setError(true);
      },
    });
  };

  const handleForgotPassword = (event: any) => {
    event.preventDefault();
    setError(false);
    setForgotPasswordModalOpen(true);
  };

  const handleCloseModal = () => {
    setForgotPasswordModalOpen(false);
    setVerificationCodeModalOpen(false);
    setEmail("");
    setVerificationCode("");
  };

  const handleForgotPasswordSubmit = (event: any) => {
    event.preventDefault();

    const userData = {
      Username: emailVerification,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        console.log("Forgot Password onSuccess", data);
        setForgotPasswordModalOpen(false);
        setVerificationCodeModalOpen(true);
        setSuccess(true);
      },
      onFailure: (err) => {
        console.log("Forgot Password onFailure", err);
        setError(true);
      },
      inputVerificationCode: () => {
        setForgotPasswordModalOpen(false);
        setVerificationCodeModalOpen(true);
      },
    });
  };

  const handleVerificationCodeSubmit = (event: any) => {
    event.preventDefault();

    const userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    if (password !== passwordConfirmation) {
      setError(true);
      return;
    }

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: (data) => {
        console.log("Confirm Password onSuccess", data);
        setSuccess(true);
      },
      onFailure: (err) => {
        console.log("Confirm Password onFailure", err);
        setError(true);
      },
    });
  };

  return (
    <div className="login">
      {forgotPasswordModalOpen && (
        <div className="modal">
          <form onSubmit={handleForgotPasswordSubmit}>
            <h2>Mot de passe oublié</h2>
            {error && (
              <p className="error">
                L'adresse e-mail que vous avez entrée n'est pas valide.
              </p>
            )}
            {success && (
              <p className="success">
                Un code de vérification a été envoyé à l'adresse e-mail que vous
                avez entrée.
              </p>
            )}
            <p>
              Entrez votre adresse e-mail pour recevoir un code de vérification.
            </p>
            <input
              type="text"
              placeholder="Adresse e-mail"
              value={emailVerification}
              onChange={(event) => setEmailVerification(event.target.value)}
            />
            <button type="submit">Envoyer</button>
            <button onClick={handleCloseModal}>Fermer</button>
          </form>
        </div>
      )}

      {verificationCodeModalOpen && (
        <div className="modal">
          <form onSubmit={handleVerificationCodeSubmit}>
            <h2>Code de vérification</h2>
            {error && (
              <p className="error">
                Le code de vérification que vous avez entré n'est pas valide ou
                les mots de passe ne correspondent pas.
              </p>
            )}
            {success && (
              <p className="success">
                Votre mot de passe a été réinitialisé avec succès.
              </p>
            )}
            <p>Entrez le code de vérification que vous avez reçu par e-mail.</p>
            <input
              type="text"
              placeholder="Code de vérification"
              value={verificationCode}
              onChange={(event) => setVerificationCode(event.target.value)}
            />
            <input
              type="password"
              placeholder="Nouveau mot de passe"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <input
              type="password"
              placeholder="Confirmer le nouveau mot de passe"
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
            />
            <button type="submit">Vérifier</button>
            <button onClick={handleCloseModal}>Fermer</button>
          </form>
        </div>
      )}

      <form onSubmit={handleLogin} className="loginForm">
        <div className="headForm">
          <img src={logoLoginPath} alt="logo" />
          <h5>Connexion espace client</h5>
          <p>Accédez à tous vos services en quelques clics !</p>
        </div>
        <div className="line"></div>
        {error && (
          <p className="error">
            Votre adresse mail ou votre mot de passe est incorrect.
          </p>
        )}
        <p>Adresse courriel : *</p>
        <input
          type="text"
          placeholder="Adresse courriel"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <p>Mot de passe : *</p>
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <button type="submit">CONNEXION</button>
        <p>
          <a href="#" onClick={handleForgotPassword}>
            Mot de passe oublié ?
          </a>
        </p>
        <div className="line"></div>
        {!isMarqueBlanche && (
          <p>
            Nouvel utilisateur ? <a href="./register">Créer un compte</a>
          </p>
        )}
      </form>
    </div>
  );
};

export default Login;
