import { Card, Grid, Typography } from "@mui/material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import GraphActions from "./chartactions";

interface IGraphActionsProps {
  planActionData: any[];
  dateedition:string;
}

function formatDate(date: string) {
  if (date === undefined) return "Jamais mis à jour";
  if (date === null) return "No date";
  if (date === "") return "Empty date";
  var _date;
  try {
    _date = new Date(date);
  } catch {
    return "Invalid date";
  }
  const formattedDate = new Intl.DateTimeFormat("fr-FR", {
    day: "numeric",
    year: "numeric",
    month: "long",
  }).format(_date);
  return formattedDate;
}

const GraphActionsComponent = ({ planActionData , dateedition}: IGraphActionsProps) => {

  return (
    <Card className="user-dashboard-right-card1" style={{ marginTop: "5%" }}>
      <div
        style={{
          height: "80px",
          width: "80px",
          background:
            "linear-gradient(160deg, rgba(33, 150, 243, 0.6), rgba(33, 150, 243, 0.6))",
          float: "left",
          borderRadius: "5px",
          marginTop: "-30px",
          boxShadow:
            "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(244, 67, 54,.4)",
        }}
      >
        <EqualizerIcon
          style={{
            color: "white",
            fontSize: "3rem",
            textAlign: "center",
            margin: "15px",
          }}
        ></EqualizerIcon>
      </div>
      <h2 style={{ textAlign: "center", marginTop: "10px" }}>
        Actions de prévention
      </h2>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          height: "160px",
        }}
      >
        <GraphActions actions={planActionData} />
      </div>
      <p style={{ textAlign: "center", marginTop: "10px" }}>
        Mise à jour du Plan d'Actions* :
      </p>
      <p style={{ textAlign: "center", marginTop: "10px" }}>
        {formatDate(dateedition)}
      </p>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          align="right"
          fontSize="0.7rem"
          style={{ marginTop: "15px" }}
        >
          <i>*Date de la dernière Mise à jour</i>
        </Typography>
      </Grid>
    </Card>
  );
};

export default GraphActionsComponent;
