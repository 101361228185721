import { useEffect, useRef, useState } from "react";
import Card from "../../components/card";
import Navbar from "../../components/navbar";
import TopBar from "../../components/TopBar";
import { USER_ID_LS_KEY } from "../../queries/uniteTravail";
import LoadingComponent from "../../components/Loading";
import API_URL from "../../utils/api";

// define props interface
interface IGCDashboardProps {
  username: string;
}

//function getStats(data: any, myID: any)
//{
//  const me = data.utilisateurs.find((user: any) => user.id === myID);
//  if (!me) return;
//
//  console.log("me", me);
//  if (!me.gcID) return [];
//  const gcUsers = data.utilisateurs.filter((it: any) => it.gcID === me.gcID);
//
//  return {
//    active: gcUsers.filter((it: any) => it.active).length,
//    expired: gcUsers.filter((it: any) => !it.active).length,
//  }
//}

const GCDashboard = ({ username }: IGCDashboardProps) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const textInputRef: any = useRef(null);

  const copyToClipboard = () => {
    textInputRef.current.select();
    document.execCommand("copy");
    setCopySuccess(true);
  };

  //const { data, loading, error } = useQuery(GET_USERS, {
  //  fetchPolicy: "no-cache",
  //  onCompleted: (data) => {
  //    getStats(data, username);
  //  }
  //});
  //const [users, setUsers] = useState<any>({});

  const [stats, setStats] = useState<any>(null);

  useEffect(() => {
    if (!stats) {
      fetch(`${API_URL}/gc/stats`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: localStorage.getItem(USER_ID_LS_KEY) }),
      })
        .then((res) => res.json())
        .then((data) => {
          setStats(data);
        })
        .catch((err) =>
          setStats({
            error: true,
            message: err.message,
          })
        );
    }
  });

  const getRegisterURL = () => {
    const url = window.location.href;
    const modifiedURL = url.replace("/dashboard", "/register?idgc=");

    const lastAuthUser = localStorage.getItem(
      "CognitoIdentityServiceProvider."+process.env.REACT_APP_CGO_GRPUSR_CID+".LastAuthUser"
    );
    const result = lastAuthUser ? lastAuthUser.replace(/"/g, "") : "";
    console.log("url :", modifiedURL + result);
    return modifiedURL + result;
  };

  if (!stats) return <LoadingComponent />;
  if (stats.error) return <div>{stats.message}</div>;
  console.log(stats);
  return (
    <div>
      <div className="dashboard">
        <Navbar type="grandcompte" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Tableau de bord</h1>
          <div className="pageLine"></div>
          <div className="content">
            <Card title="Utilisateurs actifs" value={stats.active} />
            <Card title="Abonnements expirés" value={stats.expired} />
            <Card title="Actions utilisateurs" value={stats.actions} />
          </div>
          <div className="content">
            <Card
              title="Documents uniques valides"
              value={stats.documentuniquesvalides}
            />
          </div>
          <div className="input-container">
            <p>Lien d'inscription</p>
            <input
              ref={textInputRef}
              type="text"
              value={getRegisterURL()}
              readOnly
            />
            <button onClick={copyToClipboard}>Copier</button>
            {copySuccess && <span>Copié avec succès!</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GCDashboard;
