import { useState, useEffect } from "react";
import { GET_METIERS } from "../../queries/metier";
import { GET_USERS } from "../../queries/users";
import { useQuery } from "@apollo/client";


function useStats() {
  const {
    data: metierData,
    loading: metierLoading,
    error: metierError,
  } = useQuery(GET_METIERS);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USERS);

  const [stats, setStats] = useState<any>(null);

  useEffect(() => {
    if (userData === undefined || metierData === undefined) {
      return;
    }
    const now = new Date();
    setStats({
        active: userData.utilisateurs.filter((user:any) => user.active).length,
        expired: userData.utilisateurs
          .filter((user: any) => user.active)
          .filter((user: any) => {
            try {
              if (!user.dateexpiration) return true;
              const date = new Date(user.dateexpiration);
              return date < now;
            } catch (e) {
              return true;
            }
          }).length,
        grandscomptes: userData.utilisateurs.filter(
          (user:any) => user.role === "ROLE_GC"
        ).length,
        metiers: Object.fromEntries(metierData.metiers.map((metier: any) => [metier.libelle, userData.utilisateurs.filter((u: any) => u.etablissements[0]?.metier.id === metier.id).length,])),
      });
  }, [metierData, userData]);
  

  return { stats, loading: userLoading || metierLoading || !stats };
}

export default useStats;
