/**
 * This module exports a React functional component that represents an A4 page in landscape orientation with a specific width.
 * It takes in children as props, which will be rendered inside the component.
 */
import React, { ReactNode } from "react";

/**
 * Props interface for the A4PagePaysageLargeur component.
 */
interface A4PageProps {
  children?: ReactNode;
}

/**
 * A functional component that represents an A4 page in landscape orientation with a specific width.
 *
 * @param {A4PageProps} props - The props for the component.
 * @param {ReactNode} props.children - The children to be rendered inside the component.
 *
 * @returns {JSX.Element} - The rendered A4PagePaysageLargeur component.
 */
const A4PagePaysageLargeur: React.FC<A4PageProps> = ({ children }) => {
  /**
   * The style object for the div element representing the A4 page.
   */
  const divStyle = {
    width: "297mm",
    backgroundColor: "white",
  };

  /**
   * Renders the A4PagePaysageLargeur component.
   *
   * @returns {JSX.Element} - The rendered A4PagePaysageLargeur component.
   */
  return <div style={divStyle}>{children}</div>;
};

export default A4PagePaysageLargeur;