import { gql } from "@apollo/client";

const GET_USERS = gql`
  query {
    utilisateurs {
      id
      active
      role
      gcID
      gcType
      dateexpiration
      etablissements {
        id
        raisonsociale
        userId
        dirigeant
        metier {
          libelle
          id
        }
        donnees {
          infosAffichage {
            logo
          }
        }
      }
    }
  }
`;

export { GET_USERS };
