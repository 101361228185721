// Import des images
import Logo from "../assets/images/logo.png";

// Import des icones
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Import des styles
import "../assets/css/accueil/accueil.css";
import "../assets/css/accueil/accueilAboutUs.css";
import "../assets/css/accueil/accueilNavbar.css";
import "../assets/css/accueil/accueilFooter.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Typography } from "@mui/material";

/**
 * cette fonction génère la page des CGV
 *
 * @returns le code html de la page CGV
 */
const Cgvu = () => {
  return (
    <div>
      {/* Navbar */}
      <nav className="navbarHome">
        <ul>
          <li>
            <a href="./">
              <img src={Logo} alt="Logo" />
            </a>
          </li>
          <li>
            <a href="#section-1">Accueil</a>
          </li>
          <li>
            <a href="#section-2">A Propos</a>
          </li>
          <li>
            <a href="#section-3">Nos Plus</a>
          </li>
          <li>
            <a href="#section-4">Solution</a>
          </li>
          <li>
            <a href="#section-5">Contact</a>
          </li>
          <li>
            <button
              className="loginButton"
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              SE CONNECTER
            </button>
          </li>
        </ul>
      </nav>

      {/* Section 2 */}
      <section id="section-2" className="section2">
        <div className="text-legal">
          <h1>Conditions générales de vente</h1>
          <div className="line-accueil"></div>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <u>
              <b>
                Conditions générales de vente et d’utilisation (CGVU) de
                SECURIZ®
              </b>
            </u>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Toute personne naviguant sur le site https://www.securiz.org
            (Ci-après le « Site »), qu’elle soit Visiteur, Utilisateur ou
            Utilisateur Abonné, accepte les présentes CGVU et s’engage à les
            respecter.
            <br></br>
            <br></br>
            Le Site est une plateforme d’information sur les Risques
            Professionnels et fournit un outil permettant aux utilisateurs de
            générer leur Document Unique et leurs Affichages Réglementaires.
            <br></br>
            <br></br>
            Les CGVU sont consultables en ligne et peuvent être modifiées à tout
            moment à la discrétion d’AT CONSULTING sans autre formalité que leur
            mise en ligne. Seule la dernière version en ligne, ou la version en
            ligne au moment des faits considérés, sera applicable aux Parties.
            <br></br>
            <br></br>
            Les Visiteurs s’engagent à consulter les différents tutorials avant
            de lancer la démarche ainsi que les mentions légales disponibles sur
            le Site.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 1 – PARTIES</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            « AT CONSULTING » est une société de droit français dont le siège
            social est sis 31, allée du camp de Catis à SEILH (31840), France et
            est enregistrée au RCS de Toulouse sous le numéro 885 264 721  qui
            fournit le Site.<br></br>
            <br></br>
            « Visiteur » est une personne physique qui navigue sur le Site.
            <br></br>
            <br></br>
            « Utilisateur » est une personne morale qui a souscrit aux services
            proposés par AT CONSULTING à travers l’outil SECURIZ® et qui utilise
            le Site.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 2 – OBJET</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Les Conditions Générales de Vente et d’Utilisation ont pour objet de
            définir les modalités et conditions d’utilisation de l’outil
            SECURIZ® disponibles sur le Site.<br></br>
            <br></br>
            Elles informent également les Utilisateurs Abonnés sur les modalités
            de souscription et les conditions d’utilisation du Site et du
            Service Abonné.<br></br>
            <br></br>
            AT CONSULTING met à disposition des Utilisateurs l’outil SECURIZ®
            visant à faciliter la gestion de leurs Risques Professionnels et
            notamment :<br></br>
            <br></br>- les informer sur les Exigences Réglementaires afférentes
            à leur métier,<br></br>- leur permettre de générer leur Document
            Unique et le plan d’actions associé,<br></br>- leur permettre de
            générer et de personnaliser leurs Affichages Réglementaires,
            <br></br>- leur donner accès à une FAQ pour faciliter leur démarche,
            <br></br>- faciliter le contact entre eux et AT CONSULTING à travers
            une Aide en ligne pour chaque étape clé de la démarche.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 3 – INSCRIPTION</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Tout Visiteur peut s’inscrire sur le Site afin de devenir un
            Utilisateur. En s’inscrivant, le Visiteur garantit qu’il a la
            capacité légale de s’inscrire et de contracter.<br></br>
            <br></br>
            Pour s’inscrire, le Visiteur va créer, sur le Site, un espace
            utilisateur (Compte utilisateur). Ce Compte utilisateur est créé par
            le remplissage du formulaire prévu à cet effet ainsi que par le
            choix d’un identifiant et d’un mot de passe.<br></br>
            <br></br>
            Chaque identifiant et chaque mot de passe sont uniques pour chaque
            Utilisateur. L’utilisateur est seul responsable de la
            confidentialité et de la sécurité de son identifiant et de son mot
            de passe.<br></br>
            <br></br>
            L’Utilisateur est responsable de la gestion de son Compte
            utilisateur. L’Utilisateur est seul responsable des conséquences de
            l’utilisation, frauduleuse ou non, de son Compte utilisateur et/ou
            de ses identifiants par un tiers.<br></br>
            <br></br>
            L’Utilisateur garantit l’exactitude des informations fournies lors
            de son inscription. L’Utilisateur assume la pleine et entière
            responsabilité des conséquences éventuelles découlant, directement
            ou indirectement, de la fourniture d’informations erronées ou
            tardives.
            <br></br>
            <br></br>
            L’inscription implique l’acceptation par l’Utilisateur des présentes
            CGVU.<br></br>
            <br></br>
            AT CONSULTING se réserve le droit de supprimer le Compte utilisateur
            de tout Utilisateur qui contrevient aux présentes CGVU, notamment
            lorsque l&#39;Utilisateur fournit des informations erronées lors de
            son inscription.<br></br>
            <br></br>
            La souscription de l’Utilisateur au service lui permet d’accéder à
            l’ensemble des services proposés par l’outil SECURIZ® depuis le
            Compte utilisateur.
            <br></br>
            <br></br>
            Il est précisé que les utilisateurs s’engagent à consulter les
            tutorials accessibles sur la plateforme et notamment le Premier
            intitulé « Démarche Générale » décrivant la démarche dans sa
            globalité avant d’initier sa propre démarche.<br></br>
            <br></br>
            L’inscription sur le site a pour effet d’autoriser AT CONSULTING à
            envoyer aux professionnels des mails pour les informer sur les
            services proposés par AT CONSULTING. L’utilisateur a la possibilité
            à tout moment de refuser la réception de ces informations, en se
            désinscrivant de la liste des destinataires, lors de la réception du
            mail.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 4 – LA GENERATION DE DOCUMENTS</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Après avoir souscrit à l’un des services proposés par la plateforme
            SECURIZ®, les Utilisateurs, après avoir renseigné les
            caractéristiques de leur Société, peuvent générer leur Document
            Unique et le plan d’actions associé par le biais de
            questions/réponses préétablies en fonction de leur métier.<br></br>
            <br></br>
            De même, les utilisateurs peuvent générer leurs Affichages
            Réglementaires classés en trois catégories : les affichages
            obligatoires et communs à tous les métiers, les affichages
            spécifiques et les affichages exceptionnels.<br></br>
            <br></br>
            Les Utilisateurs peuvent sauvegarder les documents pré-générés dans
            leur Compte Utilisateur.<br></br>
            <br></br>
            Les documents finaux et personnalisés sont disponibles (consultables
            et téléchargeables) à tout moment sous réserve que l’abonnement de
            l’Utilisateur soit valide.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 5 – SERVICE PROPOSE PAR AT CONSULTING</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Préalable : Les Prestations de service proposées par AT CONSULTING
            ne sont valables que pour les Sociétés implantés en Territoire
            Français.
            <br></br>
            <br></br>
          </Typography>

          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>5.2. Description du service</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            L’Utilisateur peut souscrire au service pour le prix de 500,00 €
            H.T. par année et par établissement de moins de 50 salariés, frais
            de déplacement exclus. Les tarifs sont dégressifs au-delà.<br></br>
            <br></br>
            Ce service lui permet :<br></br>
            <br></br>- de réaliser son Document Unique et ses Affichages
            Réglementaires en utilisant SECURIZ ®,<br></br>- d’avoir accès à
            SECURIZ ® et donc aux tutoriels mis à disposition pendant un an,
            <br></br>- de pouvoir poser jusqu’à 20 questions, via le service
            d’AIDE en Ligne, par mail à AT CONSULTING au sujet de l’outil ou de
            sa démarche Sécurité,<br></br>- d’accéder gratuitement à la base de
            données de Questions Fréquentes (FAQ) capitalisée et maintenue par
            AT CONSULTING.<br></br>- d’accéder au service de veille
            règlementaire.
            <br></br>
            l’Utilisateur bénéficie du support d’AT CONSULTING pour la
            préparation et/ou la participation lors de la visite de l’Inspection
            du Travail.<br></br>
            <br></br>
            Le support d’AT CONSULTING est organisé de la façon suivante :
            <br></br>- en présentiel pour les sociétés situées en régions
            Nouvelle Aquitaine ou Occitanie,<br></br>- en visioconférence pour
            les sociétés du Territoire Français sauf demande particulière qui
            fera l’objet d’une proposition commerciale.<br></br>
            <br></br>
            En ce qui concerne les frais de déplacement, voici la grille
            appliquée :<br></br>
            <br></br>- Déplacement de moins de 50 km, gratuit.<br></br>-
            Déplacement de 50 km à 100 km : Forfait de 50 € H.T.<br></br>-
            Déplacement de plus de 100 km en Région Occitanie ou Nouvelle
            Aquitaine : Forfait de 150 € H.T.<br></br>
            L’Utilisateur peut résilier le service à tout moment mais au plus
            tard 15 jours ouvrés avant la date prévue de la visite de
            l’Inspection du Travail.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>
              ARTICLE 6 – SERVICE AIDE EN LIGNE et QUESTIONS FREQUENTES (FAQ)
            </b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>6.1. Principes généraux</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Comme indiqué dans l’Article 5, le service d’Aide en ligne SECURIZ
            permet aux Utilisateurs de poser des questions à AT CONSULTING.
            <br></br>
            <br></br>
            Les questions posées par les Utilisateurs, jugées valides pour
            plusieurs Utilisateurs par AT CONSULTING, sont rendues anonymes
            avant toute publication dans la FAQ.<br></br>
            <br></br>
            Les questions peuvent être relatives soit à la démarche Sécurité
            soit à l’utilisation de l’outil SECURIZ®.<br></br>
            <br></br>
            Par ailleurs, AT CONSULTING se réserve la possibilité de modifier
            les questions afin d’éviter toute référence à un cas d’espèce
            spécifique et plus généralement pour le bon respect des règles
            d’utilisation de la FAQ.<br></br>
            <br></br>
            L’accès à la FAQ est libre et vivement conseillé pour tous les
            Utilisateurs, qui peuvent consulter les questions et les réponses
            déjà formulées, avant d’envoyer une question à AT CONSULTING.
            <br></br>
            <br></br>
            La question posée par l’Utilisateur ne doit pas :<br></br>
            <br></br>- porter atteinte de quelque manière que ce soit, aux
            droits à la réputation, à la vie privée, à l&#39;image d&#39;un
            tiers ;<br></br>- être dénigrant, diffamatoire, porter atteinte à
            l&#39;image, à la réputation d&#39;une marque ou d&#39;une
            quelconque personne physique ou morale, de quelque manière que ce
            soit ;<br></br>- permettre à des tiers de se procurer des logiciels
            piratés, des numéros de série de logiciels ou tout logiciel pouvant
            nuire ou porter atteinte, de quelque manière que ce soit, aux droits
            ou aux biens des tiers ;<br></br>- porter atteinte aux droits de
            propriété intellectuelle de quelque personne que ce soit ;<br></br>-
            conseiller une pratique douteuse ou frauduleuse ;<br></br>-
            comprendre de lien hypertexte ou faire la publicité ou la promotion
            d&#39;une société, d&#39;une marque, d&#39;un site, d&#39;un blog ou
            d&#39;un forum.
            <br></br>
            <br></br>
            En tout état de cause, AT CONSULTING reste libre de répondre ou non
            à la question de l’Utilisateur.<br></br>
            <br></br>
            Aucune garantie n’est donnée sur la qualité ou sur l’exactitude de
            la réponse fournie à l’Utilisateur.<br></br>
            <br></br>
            En aucun cas les réponses fournies par AT CONSULTING à l’Utilisateur
            ne peuvent garantir qu’aucune remarque ne pourra être émise par
            l’Inspection du Travail en cas de contrôle.<br></br>
            <br></br>
            AT CONSULTING se réserve le droit de supprimer les questions ainsi
            que le compte de tout Utilisateur qui contrevient aux présentes
            Conditions Générales d&#39;Utilisation, notamment, lorsque
            l&#39;Utilisateur dans sa question, viole le présent article 6.
            <br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>6.2. Description du Service d’Aide en Ligne</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Par le biais du service d’Aide en Ligne, l’Utilisateur pourra
            envoyer un mail à AT CONSULTING pour toute question relative à sa
            démarche Sécurité ou à l’utilisation de l’outil SECURIZ®.<br></br>
            <br></br>
            Toute question ne doit pas excéder 600 caractères et la réponse d’AT
            CONSULTING doit rester dans la limite d’environ 1200 caractères.  
            <br></br>
            <br></br>
            AT CONSULTING répondra aux questions posées par l’Utilisateur Abonné
            dans un délai de 5 jours ouvrés hors période de fermeture annuelle
            (mois d’aout sauf exception) et de maintenance du site.<br></br>
            <br></br>
            Ce service d’Aide en Ligne est ouvert dans une limite raisonnable
            d&#39;utilisation. En particulier, il n&#39;est pas possible à
            l&#39;Utilisateur de multiplier les questions sur la même
            problématique, de poser un nombre déraisonnable de questions dans
            une courte période de temps (plus de 10 dans la semaine).<br></br>
            <br></br>
            En aucun cas les réponses fournies par AT CONSULTING à l’Utilisateur
            ne peuvent garantir qu’aucune remarque ne pourra être émise par
            l’Inspection du Travail en cas de contrôle.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>6.3. Exclusion du Service Aide en Ligne</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Les questions suivantes sont exclues du service Aide en Ligne :
            <br></br>
            <br></br>- questions impliquant des Sociétés implantées en dehors du
            territoire français ;<br></br>- questions considérées sans objet par
            AT CONSULTING;<br></br>- question relatives aux secteurs d’activités
            non couverts par AT CONSULTING.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 7 – CONDITIONS DE L’ABONNEMENT DES UTILISATEURS</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <br></br>
            La souscription représente une période de 1 an minimum quelle que
            soit la date de souscription. Le paiement de l’abonnement s’effectue
            à terme à échoir. Tout année facturée est dûe.
            <br></br>
            <br></br>
            L’Abonnement est résiliable ou modifiable à tout moment et
            l&#39;Utilisateur abonné continuera à avoir accès au service jusqu’à
            la fin de la période de facturation annuelle. Toute demande de
            modification d’un abonnement ou de résiliation doit être adressée en
            se connectant à son compte ou en nous écrivant à{" "}
            <u>client@atconsulting.fr</u>. A défaut, il se renouvelle dans les
            mêmes conditions chaque année.<br></br>
            <br></br>
            Les conditions tarifaires de l’Abonnement figurent sur le Site, et
            sont celles en vigueur au moment de la commande. Ces tarifs
            n’incluent pas le coût de la connexion à Internet qui reste de la
            responsabilité du Client et est à sa charge. AT CONSULTING informe
            le Client des nouvelles conditions tarifaires qui seront
            éventuellement applicables pour l’année suivante avant la fin de
            l’année civile en cours lors du réabonnement.<br></br>
            <br></br>
            L’Abonnement est conclu intuitu personae pour une utilisation
            exclusivement destinée à l’usage interne du Client. Le Client
            s’interdit de céder l’abonnement et/ou tout droit et obligation qui
            en découlent à un tiers par quelque moyen que ce soit. <br></br>
            <br></br>
            <b>Droit de rétractation</b>
            <br></br>
            <br></br>
            AT CONSULTING, précise qu’il n&#39;existe pas de droit de
            rétractation suite à l&#39;acquisition du service.<br></br>
            <br></br>
            Conformément à l’article L221-3 du Code de la consommation, le droit
            de rétractation est applicable aux Utilisateurs professionnels qui
            remplissent les conditions suivantes :<br></br>
            <br></br>- La prestation de service proposée par AT CONSULTING
            n&#39;entre pas dans le champ de l&#39;activité principale du
            professionnel sollicité<br></br>
            <br></br>- Le nombre de salariés employés par celui-ci est inférieur
            ou égal à cinq.<br></br>
            <br></br>
            Ainsi, l&#39;utilisateur pourra exercer son droit de rétractation
            dans un délai de 14 jours calendaires à compter de la date à
            laquelle il a démarré son accès à l’outil SECURIZ®. S’il résilie son
            abonnement et exerce son droit de rétractation avant
            l&#39;expiration de ce délai, il pourra demander le remboursement de
            l’abonnement, à condition qu&#39;aucun document n&#39;ait été
            téléchargé.<br></br>
            <br></br>
            Si l’utilisateur n&#39;en fait pas la demande avant la fin de cette
            période de 14 jours calendaires, il sera facturé jusqu&#39;à la fin
            l’année, période pendant laquelle son accès aux Service Abonné sera
            maintenu.<br></br>
            <br></br>
            Pour exercer son droit de rétractation, l’Utilisateur doit envoyer
            un e-mail à <u>client@atconsulting.fr</u>
            <br></br>
            <br></br>
            Aucun remboursement ne sera effectué dans les cas suivants :
            <br></br>
            <br></br>- lorsque le délai légal de 14 jours calendaires est écoulé
            ou a expiré, l’achat du service est considéré comme ayant déjà été
            entièrement exécuté et accepté par l&#39;utilisateur.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 8 – CONDITIONS DE PAIEMENT</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Les prix s&#39;entendent en principe TTC sauf indication contraire.
            <br></br>
            <br></br>
            Le règlement des commandes s&#39;effectue :<br></br>
            <br></br>- soit par chèque ;<br></br>- soit par carte bancaire ;
            <br></br>- soit par prélèvement SEPA.<br></br>
            <br></br>
            AT CONSULTING se réserve le droit de modifier ses prix à tout moment
            mais les produits/abonnements seront facturés sur la base des tarifs
            en vigueur au moment de la commande.<br></br>
            <br></br>
            En cas de retard de paiement, des pénalités égales à trois (3) fois
            le taux de l&#39;intérêt légal en vigueur à la date de la commande
            seront appliquées à compter du premier jour de retard. Ces pénalités
            sont exigibles de plein droit sans qu&#39;un rappel ne soit
            nécessaire ainsi qu’une indemnité forfaitaire pour frais de
            recouvrement d’un montant de quarante (40) Euros. En outre, tout
            règlement ultérieur quelle qu’en soit la cause sera imputé
            immédiatement et, par priorité, à l’extinction de la plus ancienne
            des dettes.<br></br>
            <br></br>
            En outre, en cas de retard de paiement, AT CONSULTING se réserve le
            droit de suspendre l’exécution de ses propres obligations et ce,
            jusqu’à apurement du compte, sans engager sa responsabilité ou que
            l’Utilisateur puisse prétendre bénéficier d’un avoir ou d’un
            remboursement.<br></br>
            <br></br>
            Le délai de prescription pour le recouvrement de toute somme due à
            AT CONSULTING court à compter de la date d’émission de la facture
            concernée.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 9 – ESCOMPTE</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Aucun escompte ne sera consenti en cas de paiement anticipé.
            <br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 10 – DISPONIBILITE DU SITE</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Le Site est accessible gratuitement à tout Utilisateur disposant
            d&#39;un accès à internet. AT CONSULTING s’efforce de permettre
            l’accès au site 24 heures sur 24, 7 jours sur 7 sans qu’aucune
            garantie de disponibilité ne soit donnée aux Utilisateurs.<br></br>
            <br></br>
            La responsabilité d’AT CONSULTING ne saurait être engagée en cas
            d’impossibilité d’accès au Site.<br></br>
            <br></br>
            AT CONSULTING se réserve le droit d’interrompre la disponibilité du
            Site afin de pouvoir procéder à toutes opérations de maintenance
            nécessaires à la bonne administration du Site.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 11 – PROPRIETE INTELLECTUELLE</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>11.1. Contenu des Utilisateurs</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Les Utilisateurs garantissent à AT CONSULTING que tous les contenus
            fournis par eux ne sont en violation d’aucun droit en matière de
            propriété intellectuelle.<br></br>
            <br></br>
            Les Utilisateurs assument l’entière responsabilité des conséquences
            pouvant découler de la diffusion sur le Site des contenus qu’ils
            publient.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>11.2. AT CONSULTING</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Le Site « https://www.securiz.org», ainsi que les textes,
            graphiques, images, sons et vidéos le composant, sont la propriété
            exclusive de AT CONSULTING et/ou de ses sous-traitants.<br></br>
            <br></br>
            Les marques « AT CONSULTING », « SECURIZ » ainsi que les textes,
            graphiques, images, sons et vidéos les composant, sont la propriété
            exclusive de AT CONSULTING et/ou de ses sous-traitants.<br></br>
            <br></br>
            Les Utilisateurs s&#39;engagent à ne faire aucun usage de ces
            documents, susceptible de porter atteinte aux droits de propriété
            industrielle ou intellectuelle d’AT CONSULTING.<br></br>
            <br></br>
            Toute représentation et/ou reproduction et/ou exploitation partielle
            ou totale des contenus, services proposés par le Site et des marques
            précédemment énoncées, par quelque procédé que ce soit, est
            strictement interdite et serait susceptible de constituer une
            contrefaçon au sens des articles L 335-2 et suivants du Code de la
            propriété intellectuelle.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 12 – TRAITEMENTS DES DONNEES PERSONNELLES</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            AT CONSULTING s’engage, en matière de protection des données
            personnelles, à être en conformité vis-à- vis de l’ensemble des lois
            et règlements français et européens, et plus particulièrement, du
            Règlement Général Européen de Protection des Données Personnelles
            2016/679 du 27 Avril 2016.<br></br>
            <br></br>
            De plus, conformément à la Loi 78-17 « Informatique et Libertés » du
            6 janvier 1978, les Utilisateurs disposent d’un droit d’accès, de
            rectification et d’opposition aux données personnelles traitées les
            concernant.<br></br>
            <br></br>
            Ils peuvent exercer ce droit en écrivant à AT CONSULTING, à{" "}
            <u>contact@atconsulting.fr</u>
            <br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 13 – RESPONSABILITE</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>13.1. Responsabilité d’ AT CONSULTING</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            La relation entre l’Utilisateur et l’Inspection du Travail est
            totalement indépendante d’AT CONSULTING. AT CONSULTING est,
            uniquement et exclusivement, responsable pour sa prestation de
            conseil en matière de Gestion des Risques Professionnels
            conformément à la législation en vigueur et pour les Métiers
            identifiés dans la rubrique « A propos » de son site
            https://www.securiz.org.<br></br>
            <br></br>
            AT CONSULTING n’est en aucun cas contraint de répondre à l’ensemble
            des questions posées par les Utilisateurs.<br></br>
            <br></br>
            La responsabilité d’AT CONSULTING ne saurait être engagée pour tout
            dommage subi par les Utilisateurs ou par un tiers résultant
            directement ou indirectement du non-respect par les Utilisateurs de
            l’une de ses obligations au titre des présentes conditions générales
            d’utilisation.<br></br>
            <br></br>
            La responsabilité d’AT CONSULTING ne saurait être engagée pour toute
            utilisation du Site à d’autres fins que celles définies aux
            présentes.
            <br></br>
            <br></br>
            La responsabilité d’AT CONSULTING ne saurait être engagée pour toute
            utilisation faite des informations contenues sur le Site, des
            réponses fournies aux Utilisateurs et des interprétations de ces
            informations et de ces réponses.<br></br>
            <br></br>
            La responsabilité d’AT CONSULTING ne saurait être engagée quant aux
            conséquences des conseils et des documents fournis par AT
            CONSULTING, qui sont générés sous la seule responsabilité de
            l’Utilisateur suite aux réponses qu’il a apportées aux questions qui
            lui ont été posées.
            <br></br>
            <br></br>
            La responsabilité d’AT CONSULTING ne saurait être engagée quant aux
            conséquences de la non implémentation des actions préconisées dans
            le plan d’actions généré suite à l’application de la démarche
            Sécurité déroulée en utilisant l’outil SECURIZ®.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>13.2. Responsabilité des Utilisateurs</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Les Utilisateurs sont seuls responsables des dommages directs ou
            indirects, matériels ou immatériels, causés par eux à l’occasion de
            l’utilisation du Site.<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>ARTICLE 14 – REGLEMENT DES LITIGES ET LOI APPLICABLE</b>
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Les présentes CGVU ainsi que les réclamations, les actions en
            justice, les différends en découlant ou s’y rattachant sont régis
            par le droit français.<br></br>
            <br></br>
            Tous les litiges auxquels les opérations d&#39;achat et de vente
            conclues en application des présentes conditions générales de vente
            pourraient donner lieu, concernant tant leur validité, leur
            interprétation, leur exécution, leur résiliation, leurs conséquences
            et leurs suites et qui n&#39;auraient pas pu être résolus à
            l&#39;amiable entre le vendeur et le client, seront soumis aux
            tribunaux compétents dans les conditions de droit commun.<br></br>
            <br></br>
            Conformément à l’article 14 du Règlement (UE) n°524/2013, la
            Commission Européenne a mis en place une plateforme de Règlement en
            Ligne des Litiges, facilitant le règlement indépendant par voie
            extrajudiciaire des litiges en ligne entre consommateurs et
            professionnels de l’Union européenne. Cette plateforme est
            accessible au lien suivant : https://webgate.ec.europa.eu/odr/
            <br></br>
            <br></br>
          </Typography>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footerLogo">
          <img src={Logo} alt="Logo" />
          <p>Ⓒ 2023 , AT Consulting, Tous droits réservés</p>
        </div>
        <div className="footerRight">
          <div className="footerLinks">
            <a href="./mentions-legales">Mentions légales</a>
            <a href="./confidentialite">Confidentialité</a>
            <a href="./cgvu">CGVU</a>
          </div>
          <div className="footerIcons">
            <a href="https://www.linkedin.com/">
              <LinkedInIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
            <a href="https://www.facebook.com/">
              <FacebookIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
            <a href="https://www.youtube.com/">
              <YouTubeIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Cgvu;
