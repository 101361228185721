import Navbar from "../components/navbar";
import TopBar from "../components/TopBar";
import CookieConsent from "react-cookie-consent";

const NoDashboard = () => {
  return (
    <div>
      <div className="dashboard">
        <Navbar type="noUser" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Tableau de bord</h1>
          <div className="pageLine"></div>
          <div className="content">
            <CookieConsent
              location="bottom"
              buttonText="J'accepte"
              cookieName="securizanalytics"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              Nous collectons des statistiques anonymes sur les pages consultées
              sur ce site. <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
            <b>
              Bonjour, votre compte n'est pas encore activé.
              Veuillez contacter le support pour plus d'informations.
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDashboard;
