import { gql } from "@apollo/client";

const USER_ID_LS_KEY =
  "CognitoIdentityServiceProvider."+process.env.REACT_APP_CGO_GRPUSR_CID+".LastAuthUser";

const LIST_UNITE_TRAVAIL_USER = gql`
  query GetUnitesTravail($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        metierID
        metier {
          unitesTravail {
            id
            libelle
            updatedAt
          }
        }
        donnees {
          unitesTravail {
            id
            libelle
            nbsalaries
            updatedAt
          }
        }
      }
    }
  }
`;

const LINKS_ETABLISSMENT_MUTATION = gql`
  mutation AddLinksUniteTravail(
    $id: String!
    $linksAdd: [LinkEtablissement!]
    $linksDelete: [LinkEtablissement!]
  ) {
    links {
      etablissement(id: $id) {
        add(links: $linksAdd)
        delete(links: $linksDelete)
      }
    }
  }
`;

const CREATE_UNITE_TRAVAIL = gql`
  mutation CreateUniteTravail($libelle: String!) {
    uniteTravail {
      add(libelle: $libelle) {
        id
      }
    }
  }
`;

export {
  LIST_UNITE_TRAVAIL_USER,
  LINKS_ETABLISSMENT_MUTATION,
  USER_ID_LS_KEY,
  CREATE_UNITE_TRAVAIL,
};
