import TopBar from "../../../components/TopBar";
import Navbar from "../../../components/navbar";

import "../../../assets/css/docu.css";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useMutation } from "@apollo/client";
import {
  LINKS_ETABLISSMENT_MUTATION,
  USER_ID_LS_KEY,
} from "../../../queries/uniteTravail";
import { useEffect, useRef, useState } from "react";
import FreqGrav from "../../../components/FreqGrav";
import LoadingComponent from "../../../components/Loading";
import ErrorComponent from "../../../components/ErrorComponent";
import { UPDATE_DOCUMENT_UNIQUE } from "../../../queries/documentUnique";
import API_URL from "../../../utils/api";

// const getMutationParams = (uts: any, data: any) => {
//   const oldUts = getRisquesFromData(data);
//   var risquesToAdd = [];
//   var risquesToRemove = [];

//   for (let ut of uts) {
//     let oldUt = oldUts.find((oldItem: any) => oldItem.id === ut.id);
//     for (let risque of ut.risques) {
//       let oldRisque = oldUt.risques.find(
//         (oldItem: any) => oldItem.id === risque.id
//       );
//       for (let duplicate of risque.duplicates) {
//         let oldDuplicate = oldRisque.duplicates.find(
//           (oldItem: any) => oldItem.id === duplicate.id
//         );
//         if (
//           (duplicate.checked && !oldDuplicate.checked) ||
//           (oldDuplicate.frequence !== duplicate.frequence &&
//             duplicate.checked) ||
//           (oldDuplicate.gravite !== duplicate.gravite && duplicate.checked)
//         ) {
//           var item = {
//             uniteTravailID: ut.id,
//             risque: { id: duplicate.id } as any,
//           };
//           if (oldDuplicate.frequence !== duplicate.frequence) {
//             item.risque.frequence = duplicate.frequence;
//           }
//           if (oldDuplicate.gravite !== duplicate.gravite) {
//             item.risque.gravite = duplicate.gravite;
//           }
//           risquesToAdd.push(item);
//         }
//         if (duplicate.checked === false && (oldDuplicate.checked || oldDuplicate.haveDesc === false)) {
//           risquesToRemove.push({
//             uniteTravailID: ut.id,
//             risque: { id: duplicate.id },
//           });
//         }
//       }
//     }
//   }

//   return {
//     linksAdd: risquesToAdd,
//     linksDelete: risquesToRemove,
//   };
// };

const DescriptionRisques = () => {
  // const { loading, error, data } = useQuery(LIST_DESCRIPTIONS_USER, {
  //   variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
  //   fetchPolicy: "no-cache",
  // });

  const oldModel = useRef([] as any);
  const model = useRef([] as any);
  const [loading, setLoading] = useState(true);
  const [pageToSwitch, setPageToSwitch] = useState("");
  const mutationQueue = useRef(undefined as any);

  useEffect(() => {
    fetch(
      `${API_URL}/rest/user/model/${localStorage.getItem(
        USER_ID_LS_KEY
      )}/${localStorage.getItem("idEntreprise")}`
    ).then((res) => {
      res.json().then((data) => {
        data = data
          .filter((ut: any) => ut.checked)
          .map((ut: any) => {
            ut.risques = ut.risques.filter((risque: any) => risque.checked);
            return ut;
          });
        model.current = data;
        oldModel.current = data;
        setLoading(false);
      });
    });
  }, []);

  const [mutateDocUnique, docUniqueMutation] = useMutation(
    UPDATE_DOCUMENT_UNIQUE
  );

  const [linksMutation, mutation] = useMutation(LINKS_ETABLISSMENT_MUTATION);

  const mutate = (data: any) => {
    if (mutation.loading) {
      mutationQueue.current = {
        linksAdd: [
          ...(mutationQueue.current?.linksAdd.filter(
            (item: any) =>
              !data.linksDelete.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              ) &&
              !data.linksAdd.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              )
          ) ?? []),
          ...data.linksAdd,
        ],
        linksDelete: [
          ...(mutationQueue.current?.linksDelete.filter(
            (item: any) =>
              !data.linksAdd.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              ) &&
              !data.linksDelete.find(
                (item2: any) =>
                  item2.uniteTravailID === item.uniteTravailID &&
                  item2.risque.id === item.risque.id
              )
          ) ?? []),
          ...data.linksDelete,
        ],
      };
    } else {
      linksMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: data.linksAdd,
          linksDelete: data.linksDelete,
        },
      });
    }
  };

  useEffect(() => {
    if (!mutation.loading && mutationQueue.current !== undefined) {
      let toMutate = mutationQueue.current;
      mutationQueue.current = undefined;
      linksMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: toMutate.linksAdd,
          linksDelete: toMutate.linksDelete,
        },
      }).then(async () => {
        if (pageToSwitch !== "" && mutationQueue.current === undefined) {
          window.location.href = pageToSwitch;
        }
      });
    } else {
      if (pageToSwitch !== "") {
        window.location.href = pageToSwitch;
      }
    }
  }, [mutation]);

  const content =  () =>
      model.current.map((ut: any, indexUt: number) =>
        ut.risques.map((risque: any, indexRisque: number) => {
          if (!risque.checked) return null;
          return (
            <Accordion className="accordion" key={indexUt * 100 + indexRisque}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
                      {ut.libelle + " | " + risque.libelle}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper} className="table_risques">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Descriptions du risque identifiés
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {risque.descriptions.map(
                            (description: any, indexDescription: number) => (
                              <TableRow key={indexDescription}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    checked={description.checked || false}
                                    onChange={() => {
                                      description.checked =
                                        !description.checked;
                                      mutate({
                                        linksAdd: description.checked
                                          ? [
                                              {
                                                uniteTravailID: ut.id,
                                                risque: {
                                                  id: description.id,
                                                } as any,
                                              },
                                            ]
                                          : [],
                                        linksDelete: description.checked
                                          ? []
                                          : [
                                              {
                                                uniteTravailID: ut.id,
                                                risque: {
                                                  id: description.id,
                                                } as any,
                                              },
                                            ],
                                      });
                                    }}
                                  />
                                  <div style={{ flex: 3 }}>
                                    {description.description}
                                  </div>
                                  <div style={{ flex: 1 }}>
                                    <FreqGrav
                                      freq={parseInt(description.frequence)}
                                      grav={parseInt(description.gravite)}
                                      onChange={(
                                        newFreq: number,
                                        newGrav: number
                                      ) => {
                                        description.frequence = newFreq;
                                        description.gravite = newGrav;
                                        if (description.checked)
                                          mutate({
                                            linksAdd: [
                                              {
                                                uniteTravailID: ut.id,
                                                risque: {
                                                  id: description.id,
                                                  frequence: newFreq,
                                                  gravite: newGrav,
                                                } as any,
                                              },
                                            ],
                                            linksDelete: [],
                                          });
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })
      );

  if (loading) return <LoadingComponent />;

  if (mutation.loading && pageToSwitch !== "") return <LoadingComponent />;
  if (mutation.error) return <ErrorComponent error={mutation.error} />;

  if (docUniqueMutation.loading && pageToSwitch !== "")
    return <LoadingComponent />;
  if (docUniqueMutation.error) return <ErrorComponent error={docUniqueMutation.error} />;

  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Sélection des descriptions de risques</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            <p>
              Voici les descriptions de risque les plus fréquemment identifiées
              par unité de travail dans votre activité.
            </p>
            <p>
              Cochez la case si la situation indiquée peut se présenter dans
              votre entreprise.
            </p>
            <button
              onClick={async () => {
                let toMutate = model.current.reduce((acc: [], ut: any) => {
                  return [
                    ...acc,
                    ...ut.risques.reduce((acc2: [], risque: any) => {
                      return [
                        ...acc2,
                        ...risque.descriptions.reduce(
                          (acc3: [], description: any) => {
                            description.checked=true;
                            return [
                              ...acc3,
                              {
                                uniteTravailID: ut.id,
                                risque: { id: description.id } as any,
                              },
                            ];
                          },
                          []
                        ),
                      ];
                    }, []),
                  ];
                }, []);
                for (let i = 0; i < toMutate.length; i += 25) {
                  mutate({
                    linksAdd: toMutate.slice(i, i + 25),
                    linksDelete: [],
                  });
                  // wait 2 sec
                  await new Promise((resolve) => setTimeout(resolve, 2000));
                }
                console.log("end");
              }}
            >
              Selectionner tout
            </button>
            {content()}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="button-style-doc"
                onClick={async () => {
                  await mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 2,
                    },
                  });
                  setPageToSwitch("/selection-des-risques");
                }}
              >
                Précédent
              </button>
              <button
                className="button-style-doc"
                onClick={async () => {
                  await mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 4,
                    },
                  });
                  setPageToSwitch("/mesures-preventives");
                }}
              >
                Suivant
              </button>
              {mutation.loading && "sauvegarde en cours..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionRisques;
