type ComputeDaysArgs = {
  days: number;
  previousDate?: string;
};

export const computeDate = ({
  days,
  previousDate,
}: ComputeDaysArgs): string => {
  const now = new Date();
  let current = previousDate ? new Date(previousDate) : new Date();
  if (current < now) {
    current = now;
  }
  current.setDate(current.getDate() + days);
  return current.toISOString();
};
