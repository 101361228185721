import { gql } from "@apollo/client";

const MUTE_PLAN_ACTION_PREPARE = gql`
  query MutePlanActionPrepare($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        metier {
          unitesTravail {
            id
            risques {
              id
              frequence
              gravite
              mesures {
                id
                ponderation
              }
            }
          }
        }
        donnees {
          unitesTravail {
            id
            risques {
              id
              libelle
              gravite
              frequence
              mesures {
                id
                ponderation
              }
            }
          }
          documentUnique {
            planaction {
              actions {
                uniteTravail {
                  id
                }
                risque {
                  id
                }
                mesure {
                  id
                }
                isopen
              }
            }
          }
        }
      }
    }
  }
`;

const GET_PLAN_ACTION = gql`
  query GetPlanAction($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        donnees {
          documentUnique {
            id
            planaction {
              actions {
                uniteTravail {
                  id
                  libelle
                }
                risque {
                  id
                  libelle
                  gravite
                  frequence
                }
                mesure {
                  id
                  libelle
                  ponderation
                }
                avancement
                comment
                responsable
                dateouverture
                datecloture
                isopen
                isupdated
                pj
              }
            }
          }
        }
      }
    }
  }
`;
//
//
const LINKS_PLANACTION_MUTATION = gql`
  mutation AddLinksUniteTravail(
    $etablissementID: String!
    $linksAdd: [LinkAction!]
    $linksDelete: [LinkAction!]
  ) {
    links {
      action(id: $etablissementID) {
        add(links: $linksAdd)
        delete(links: $linksDelete)
      }
    }
  }
`;

const PLAN_ACTION_UPDATE_DATE = gql`
  mutation UpdatePlanActionDate(
    $etablissementID: String!
    $dateedition: String!
  ) {
    documentUnique(etablissementID: $etablissementID) {
      update(
        etablissementID: $etablissementID
        planaction: { dateedition: $dateedition }
      ) {
        id
      }
    }
  }
`;

const CREATE_DOCUMENT_UNIQUE = gql`
  mutation CreateDocumentUnique(
    $etablissementID: String!
    $dateedition: String!
  ) {
    documentUnique(etablissementID: $etablissementID) {
      add(
        etablissementID: $etablissementID
        dateedition: $dateedition
        isvalid: true
        planaction: { dateedition: $dateedition, comment: "" }
      ) {
        id
      }
    }
  }
`;

const GET_PLAN_ACTION_DATE = gql`
  query GetPlanActionDate($idEtablissement: String!) {
    etablissement(id: $idEtablissement) {
      documentUnique {
        planaction {
          dateedition
          actions {
            uniteTravail {
              id
            }
            risque {
              id
            }
            avancement
            dateouverture
            datecloture
            isopen
            isupdated
            isopen
            pj
          }
        }
      }
    }
  }
`;

const GET_DOCUMENT_UNIQUE_VALID = gql`
  query GetDocumentUniqueValid($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        donnees {
          documentUnique {
            isvalid
            planaction {
              dateedition
            }
          }
        }
      }
    }
  }
`;

export {
  MUTE_PLAN_ACTION_PREPARE,
  LINKS_PLANACTION_MUTATION,
  CREATE_DOCUMENT_UNIQUE,
  GET_PLAN_ACTION,
  GET_PLAN_ACTION_DATE,
  PLAN_ACTION_UPDATE_DATE,
  GET_DOCUMENT_UNIQUE_VALID,
};
