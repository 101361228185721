import Papa from "papaparse";
import groupBy from "lodash/groupBy";
import { toast } from "react-toastify";
import { useCallback, useMemo, useState } from "react";
import API_URL from "../../utils/api";

async function csvToJson(file: File) {
  const [headers, ...rows] = Papa.parse<string[]>(await file.text()).data;
  return rows.map((row) => {
    return headers.reduce((acc: { [key: string]: string }, header, index) => {
      acc[header] = row[index];
      return acc;
    }, {});
  });
}

function jsonToCsv(jsonString: string): File {
  const jsonArray = JSON.parse(jsonString);
  const csvString = Papa.unparse(jsonArray, { delimiter: ";" });
  return new File([csvString], "data.csv", { type: "text/csv" });
}

export function useMetierImport() {
  const [counter, setCounter] = useState(0);
  const [unitCount, setUnitCount] = useState(0);

  const show = useMemo(() => !!unitCount, [unitCount]);
  const progress = useMemo(
    () => (counter * 100) / unitCount,
    [counter, unitCount]
  );

  const cutImport = useCallback(
    (libelle: string, ape: string) => async (e: any) => {
      const file: File = e.target.files[0];
      const items = await csvToJson(file);
      console.log(items);
      let importedCounter = 0
      const uniteTravails = Object.entries(
        groupBy(items, "Unité de travail")
      ).filter(([uniteTravail]) => !!uniteTravail);
      setUnitCount(uniteTravails.length);
      for (const [uniteTravail, items] of uniteTravails) {
        const file = jsonToCsv(JSON.stringify(items));
        const formData = new FormData();
        formData.append("file", file);
        formData.append("libelle", libelle);
        formData.append("ape", ape);

        const response = await fetch(`${API_URL}/admin/csv`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        });
        if (!response.ok) {
          toast.error(
            `Un problème est survenu sur l'unité de travail ${uniteTravail}`
          );
        } else {
          importedCounter++;
        }
        setCounter((c) => c + 1);
      }
      toast.success(
        `Importation terminée: ${importedCounter}/${uniteTravails.length} importées correctement `
      );
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    },
    []
  );

  const importMetier = useCallback(
    (libelle: string, ape: string) => (e: any) => {
      if (!libelle || !ape) {
        toast.warn(
          "Vous n'avez pas renseigné le code APE ou le nom libelle du métier"
        );
        return;
      }
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".csv";

      input.onchange = cutImport(libelle, ape);

      input.click();
    },
    []
  );

  return {
    importMetier,
    progress,
    show,
  };
}
