import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import styles from "../../assets/css/documents";

function ARElecVerif(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  console.log("chargement du document");
  const months = [
    "JANVIER",
    "MARS",
    "AVRIL",
    "MAI",
    "JUIN",
    "JUILLET",
    "AOUT",
    "SEPTEMBRE",
    "OCTOBRE",
    "NOVEMBRE",
    "DECEMBRE",
  ];
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            VERIFICATION MATERIEL ELECTRIQUE
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>

        <View
          style={{
            ...styles.container,
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <View style={styles.table}>
            <View style={{ ...styles.tableRow }}>
              <View style={{ ...styles.tableHeaderCell, width: "50%" }}>
                <Text
                  style={{ color: props.textColor, fontWeight: "ultrabold" }}
                >
                  MOIS
                </Text>
              </View>
              <View style={{ ...styles.tableHeaderCell, width: "50%" }}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  VERIF.
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  ETAT
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  NOTES
                </Text>
              </View>
            </View>
            <View>
              {months.map((month, index) => (
                <View style={{ ...styles.tableRow }} key={index}>
                  <View
                    style={{
                      ...styles.tableHeaderCell,
                      height: "25px",
                      width: "50%",
                    }}
                  >
                    <Text
                      style={{
                        color: props.textColor,
                        fontSize: "0.5rem",
                        height: "75%",
                      }}
                    >
                      {month}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableHeaderCell,
                      height: "25px",
                      width: "50%",
                    }}
                  >
                    <Text style={{ color: props.textColor, height: "100%" }}>
                      OUI / NON
                    </Text>
                  </View>
                  <View style={{ ...styles.tableHeaderCell, height: "25px" }}>
                    <Text
                      style={{ color: props.textColor, height: "100%" }}
                    ></Text>
                  </View>
                  <View style={{ ...styles.tableHeaderCell, height: "25px" }}>
                    <Text
                      style={{ color: props.textColor, height: "100%" }}
                    ></Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View>
            <Text style={{ color: props.textColor, fontWeight: "bold" }}>
              LISTE DU MATERIEL
            </Text>
          </View>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
            marginTop: "20px",
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ARElecVerif;
