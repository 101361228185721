import { Typography, Grid } from "@mui/material";

import "../assets/css/docu.css";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DescriptionIcon from "@mui/icons-material/Description";

import { useQuery } from "@apollo/client";
import { GET_WORKSGROUPS } from "../queries/worksgroups";
import { useEffect } from "react";
import LoadingComponent from "./Loading";
import ErrorComponent from "./ErrorComponent";

function formatDate(date: string | undefined) {
  if (!date) {
    return "Jamais";
  }
  const _date = new Date(date);
  const formattedDate = new Intl.DateTimeFormat("fr-FR", {
    day: "numeric",
    year: "numeric",
    month: "long",
  }).format(_date);
  return formattedDate;
}

const DocUUsers = ({
  etablissement,
}: {
  entrepriseID: any;
  etablissement: any;
}) => {
  const { data, loading, error } = useQuery(GET_WORKSGROUPS, {
    variables: {
      id: localStorage.getItem(
        "CognitoIdentityServiceProvider."+process.env.REACT_APP_CGO_GRPUSR_CID+".LastAuthUser"
      ),
    },
  });

  const avancement = etablissement?.donnees?.documentUnique?.avancement;
  var [ArrowWG, ArrowUT, ArrowRisque, ArrowDescription, ArrowMesure] = [
    0, 1, 2, 3, 4,
  ].map((index) => {
    if (!avancement) {
      return index === 0 && !etablissement?.donnees?.documentUnique?.isvalid;
    }
    return avancement === index;
  });
  //function defineArrowWG(avancementInfos: any) {
  //  if (
  //    data?.utilisateur?.etablissements?.[0]?.donnees?.[0]?.workGroup
  //      ?.participants?.length !== 0 &&
  //    avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //      ?.unitesTravail?.length == 0
  //  ) {
  //    return true;
  //  } else {
  //    return false;
  //  }
  //}
  //
  //function defineArrowUT(avancementInfos: any) {
  //  if (
  //    avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //      ?.unitesTravail?.length !== 0 &&
  //    avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //      ?.unitesTravail?.[0]?.risques?.length == 0
  //  ) {
  //    return true;
  //  } else {
  //    return false;
  //  }
  //}
  //
  //function defineArrowRisque(avancementInfos: any) {
  //  if (
  //    avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //      ?.unitesTravail?.[0]?.risques?.length !== 0 &&
  //    avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //      ?.unitesTravail?.[0]?.risques?.[0]?.description?.length == 0
  //  ) {
  //    return true;
  //  } else {
  //    return false;
  //  }
  //}
  //
  //function defineArrowDescription(avancementInfos: any) {
  //  if (
  //    avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //      ?.unitesTravail?.[0]?.risques?.[0]?.description?.length !== 0 &&
  //    avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //      ?.unitesTravail?.[0]?.risques?.[0]?.mesures?.length == 0
  //  ) {
  //    return true;
  //  } else {
  //    return false;
  //  }
  //}

  // function defineArrowMesure(avancementInfos: any) {
  //   const isValid =
  //     etablissement && etablissement.donnees.documentUnique?.isvalid;
  //   console.log(isValid);

  //   if (
  //     avancementInfos?.utilisateur?.etablissements?.[0]?.donnees?.[0]
  //       ?.unitesTravail?.[0]?.risques?.[0]?.mesures?.length !== 0 &&
  //     (isValid === null || isValid === false)
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  console.log(etablissement);

  useEffect(() => {
    if (data) {
      console.log("datax :", data);
    }
  }, [data]);

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <div className="docu-style2">
      <div
        style={{
          height: "80px",
          width: "80px",
          background: "linear-gradient(160deg, #92D050, #92D050)",
          float: "left",
          borderRadius: "5px",
          marginTop: "-30px",
          boxShadow:
            "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(244, 67, 54,.4)",
        }}
      >
        <DescriptionIcon
          style={{
            color: "white",
            fontSize: "3rem",
            textAlign: "center",
            margin: "15px",
          }}
        ></DescriptionIcon>
      </div>
      <h2 id="modal-modal-title" style={{ textAlign: "center" }}>
        Avancement du document unique
      </h2>
      <div id="modal-modal-description">
        <Grid container spacing={2} style={{ marginTop: "50px" }}>
          <Grid item xs={1}>
            <div style={{ width: "2vh", height: "455px" }}>
              <div
                style={{
                  width: "2vh",
                  height: "100px",
                  borderBottom: "1px solid white",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "115px",
                  marginBottom: "3px",
                }}
              >
                {etablissement &&
                etablissement.donnees.documentUnique?.isvalid ? (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                ) : null}
              </div>
              <div
                style={{
                  width: "2vh",
                  height: "100px",
                  borderBottom: "1px solid white",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "115px",
                  marginBottom: "3px",
                }}
              >
                {ArrowMesure &&
                !(
                  etablissement && etablissement.donnees.documentUnique?.isvalid
                ) ? (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                ) : null}
              </div>
              <div
                style={{
                  width: "2vh",
                  height: "100px",
                  borderBottom: "1px solid white",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "115px",
                  marginBottom: "3px",
                }}
              >
                {ArrowDescription ? (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                ) : null}
              </div>
              <div
                style={{
                  width: "2vh",
                  height: "50px",
                  borderBottom: "1px solid white",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "60px",
                  marginBottom: "3px",
                }}
              >
                {ArrowRisque ? (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                ) : null}
              </div>
              <div
                style={{
                  width: "2vh",
                  height: "50px",
                  borderBottom: "1px solid white",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "60px",
                  marginBottom: "3px",
                }}
              >
                {ArrowUT ? (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                ) : null}
              </div>
              <div
                style={{
                  width: "2vh",
                  height: "50px",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "60px",
                  marginBottom: "3px",
                }}
              >
                {ArrowWG ? (
                  <ArrowRightIcon style={{ fontSize: "3rem" }}></ArrowRightIcon>
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div style={{ width: "190px", height: "455px" }}>
              <div
                style={{
                  width: "190px",
                  height: "100px",
                  backgroundColor: "#92D050",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "100px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Document Unique Validé
              </div>
              <div
                style={{
                  width: "190px",
                  height: "100px",
                  backgroundColor: "#FDE9D9",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "100px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Mesures Préventives
              </div>
              <div
                style={{
                  width: "190px",
                  height: "100px",
                  backgroundColor: "#FCD5B4",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "100px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Description de Risques
              </div>
              <div
                style={{
                  width: "190px",
                  height: "50px",
                  backgroundColor: "#FAC090",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Risques
              </div>
              <div
                style={{
                  width: "190px",
                  height: "50px",
                  backgroundColor: "#E46D0A",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Unités de Travail
              </div>
              <div
                style={{
                  width: "190px",
                  height: "50px",
                  backgroundColor: "red",
                  textAlign: "center",
                  verticalAlign: "middle",
                  lineHeight: "50px",
                  marginBottom: "3px",
                  borderRadius: "10px",
                }}
              >
                Groupe de Travail
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <u>Mise à jour de mon DUERP</u>*
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              {etablissement &&
              etablissement.donnees.documentUnique?.dateedition
                ? formatDate(etablissement.donnees.documentUnique?.dateedition)
                : "E/A validation"}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <u>Mise à jour Modèle</u>*
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              {etablissement && etablissement.metier?.updatedAt
                ? formatDate(etablissement.metier?.updatedAt)
                : "Jamais modifié"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              align="right"
              style={{ marginTop: "15px" }}
            >
              <i>*Date de la dernière Mise à jour</i>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DocUUsers;
