import { useState } from "react";
import AntSwitch from "../../components/AntSwitch";
import { Link, useLocation } from "react-router-dom";

import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import "../../assets/css/clients.css";

import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../queries/users";
import client from "../../utils/http-common";
import { DELETE_USER, UPDATE_USER } from "../../queries/user";
import { formatDateYear } from "../../utils/formatDate";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import API_URL from "../../utils/api";

function getStats(data: any, gcID: string) {
  const gcUsers = data.utilisateurs.filter(
    (it: any) => it.gcID === gcID && it.role !== "ROLE_GC"
  );
  return gcUsers.map((it: any) => {
    var ret = {
      id: it.id,
      name: "Pas d'entreprise",
      actif: it.active,
      datefin: it.dateexpiration || "Pas d'abonnement",
      isGC: false,
      metierLibelle: it.etablissements[0].metier.libelle,
    };
    if (it.etablissements.length > 0) {
      ret.name = it.etablissements[0].raisonsociale;
    }
    return ret;
  });
}

function mutateActivation(userID: string, active: boolean) {
  return client.mutate({
    mutation: UPDATE_USER,
    variables: {
      id: userID,
      active: active,
    },
  });
}
const GCClients = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gcID = queryParams.get("gcid") || "";
  const [searchText, setSearchText] = useState("");

  const { data, loading, error } = useQuery(GET_USERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setRows(getStats(data, gcID));
    },
  });

  const [rows, setRows] = useState(
    [] as {
      id: string;
      name: string;
      actif: boolean;
      datefin: string;
      isGC: boolean;
      metierLibelle: string;
    }[]
  );

  if (loading) return <LoadingComponent />;
  if (error)
    return (
      <>
        <ErrorComponent error={error} /> {error.message}
      </>
    );
  const filteredRows = rows.filter((row) => {
    return (
      row.name && row.name.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const getLogo = (client: any) => {
    console.log("client :", client);
    if (client.donnees?.infosAffichage?.logo) {
      return client.donnees?.infosAffichage?.logo;
    } else {
      return "https://pbs.twimg.com/profile_images/578844000267816960/6cj6d4oZ_400x400.png";
    }
  };

  return (
    <div className="dashboard">
      <Navbar type="admin" />
      <div className="dashboard-content">
        <TopBar />
        <div style={{ display: "flex", width: "100%" }}>
          <h1>Liste de mes clients</h1>
          {filteredRows.length === 0 && (
            <button
              className="btn btn-danger"
              style={{ margin: "10px", marginLeft: "auto" }}
              onClick={async () => {
                if (
                  !window.confirm(
                    "Êtes-vous sûr de vouloir supprimer ce compte ?"
                  )
                )
                  return;
                client.mutate({
                  mutation: DELETE_USER,
                  variables: {
                    id: gcID,
                  },
                  errorPolicy: "ignore",
                });
              }}
            >
              Supprimer le compte
            </button>
          )}
        </div>
        <div className="pageLine"></div>

        <>
          <div
            className="tab-content"
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <div className="filter-box">
              Filtrer : &nbsp;
              <input
                type="text"
                className="filter-input"
                placeholder="Nom, entreprise"
                id="filter"
                onChange={handleSearch}
              />
            </div>
            {rows === null ? (
              <div className="loading-data">
                <p>Chargement des données...</p>
              </div>
            ) : (
              <>
                {filteredRows.map((item, index) => (
                  <div
                    key={index}
                    className="content-client"
                    style={{
                      borderBottom:
                        index === filteredRows.length - 1
                          ? "none"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    <img
                      src={getLogo(item)}
                      alt="clients"
                      height="50px"
                      width="50px"
                      className="content-client-icon"
                    />
                    <h5 className="content-title">{item.name}</h5>
                    <p className="content-text center">
                      {item.actif ? "Actif" : "Inactif"}
                    </p>
                    <p className="content-text center">{item.metierLibelle}</p>
                    <div className="content-connexion">
                      <p className="content-text2">Date d'expiration : </p>
                      <p className="content-text3">
                        {formatDateYear(item.datefin)}
                      </p>
                    </div>
                    <AntSwitch
                      checked={item.actif ? true : false}
                      inputProps={{ "aria-label": "ant design" }}
                      onChange={() => {
                        setRows(
                          rows.map((it) => {
                            if (it.id === item.id) {
                              it.actif = !it.actif;
                            }
                            return it;
                          })
                        );
                        mutateActivation(item.id, item.actif)
                          .then(() => {})
                          .catch((err) => {
                            console.log(err);
                            setRows(
                              rows.map((it) => {
                                if (it.id === item.id) {
                                  it.actif = !it.actif;
                                }
                                return it;
                              })
                            );
                          });

                        fetch(`${API_URL}/admin/users/${item.id}`, {
                          method: "POST",
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            enabled: item.actif,
                          }),
                        });
                      }}
                    />
                    <Link to={"/client?userid=" + item.id}>
                      <EditIcon className="edit-icon" />
                    </Link>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default GCClients;
