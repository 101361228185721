import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import styles from "../../assets/css/documents";

function ARElec(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  console.log("chargement du document");
  const { etablissementAffichages } = props;
  const months = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
  ];
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            VERIFICATION ANNUELLE DES INSTALLATIONS ELECTRIQUES
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>

        <View
          style={{
            ...styles.container,
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <View style={styles.table}>
            <View style={{ ...styles.tableRow }}>
              <View style={styles.tableHeaderCell}>
                <Text
                  style={{ color: props.textColor, fontWeight: "ultrabold" }}
                >
                  ANNEE
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  VERIF.
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  ETAT
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  NOTES
                </Text>
              </View>
            </View>
            {months.map((month, index) => (
              <View style={{ ...styles.tableRow }} key={index}>
                <View style={{ ...styles.tableHeaderCell, height: "50px" }}>
                  <Text style={{ color: props.textColor, height: "100%" }}>
                    {month}
                  </Text>
                </View>
                <View style={{ ...styles.tableHeaderCell, height: "50px" }}>
                  <Text style={{ color: props.textColor, height: "100%" }}>
                    OUI / NON
                  </Text>
                </View>
                <View style={{ ...styles.tableHeaderCell, height: "50px" }}>
                  <Text
                    style={{ color: props.textColor, height: "100%" }}
                  ></Text>
                </View>
                <View style={{ ...styles.tableHeaderCell, height: "50px" }}>
                  <Text
                    style={{ color: props.textColor, height: "100%" }}
                  ></Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ARElec;
