import { gql } from "@apollo/client";

const GET_FULL_DOCUMENT_UNIQUE = gql`
  query GetRisques($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        donnees {
          unitesTravail {
            id
            libelle
            risques {
              libelle
              description
              frequence
              gravite
              mesures {
                id
                libelle
                ponderation
                specificite
              }
              id
            }
          }
        }
      }
    }
  }
`;

const GET_FULL_DOCUMENT_UNIQUE_MODEL = gql`
  query GetRisques($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        metier {
          id
          unitesTravail {
            id
            risques {
              id
              gravite
              frequence
              mesures {
                id
                ponderation
              }
            }
          }
        }
        donnees {
          unitesTravail {
            id
            libelle
            risques {
              libelle
              description
              frequence
              gravite
              mesures {
                id
                libelle
                ponderation
                specificite
              }
              id
            }
          }
        }
      }
    }
  }
`;

const GET_AVANCEMENT_INFOS = gql`
  query GetAvancementInfos($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        donnees {
          documentUnique {
            id
            avancement
            updatedAt
          }
        }
      }
    }
  }
`;

const GET_DOCUMENT_UNIQUE_INFOS = gql`
  query GetDocumentUniqueInfos($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        donnees {
          documentUnique {
            id
            dateedition
            isvalid
            avancement
            updatedAt
          }
        }
        metier {
          updatedAt
        }
      }
    }
  }
`;

const UPDATE_DOCUMENT_UNIQUE = gql`
  mutation UpdateDocumentUnique(
    $etablissementID: String!
    $dateedition: String
    $isvalid: Boolean
    $id: String
    $avancement: Int
  ) {
    documentUnique(etablissementID: $etablissementID) {
      update(
        etablissementID: $etablissementID
        dateedition: $dateedition
        isvalid: $isvalid
        id: $id
        avancement: $avancement
      ) {
        id
      }
    }
  }
`;
export {
  GET_FULL_DOCUMENT_UNIQUE,
  GET_FULL_DOCUMENT_UNIQUE_MODEL,
  GET_DOCUMENT_UNIQUE_INFOS,
  UPDATE_DOCUMENT_UNIQUE,
  GET_AVANCEMENT_INFOS,
};
