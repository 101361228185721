import { gql } from "@apollo/client";

const GET_USER = gql`
  query GetUtilisateur($id: String!) {
    utilisateur(id: $id) {
      id
      lastlogin
      role
      gcID
      active
      dateexpiration
      etablissements {
        donnees {
          infosAffichage {
            logo
          }
        }
        metier {
          libelle
          id
        }
        id
        raisonsociale
        dirigeant
        nbemployes
        siret
        adresse
        ville
        cp
        telephone
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUtilisateur(
    $id: String!
    $customerID: String
    $role: String
    $active: Boolean
    $nbquestions: Int
    $lastlogin: String
    $gcType: String
    $gcID: String
    $dateexpiration: String
  ) {
    utilisateur {
      update(
        id: $id
        customerID: $customerID
        role: $role
        active: $active
        nbquestions: $nbquestions
        lastlogin: $lastlogin
        gcType: $gcType
        gcID: $gcID
        dateexpiration: $dateexpiration
      ) {
        id
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUtilisateur(
    $id: String!
    $customerID: String!
    $role: String!
    $active: Boolean!
    $nbquestions: Int!
    $lastlogin: String
    $gcType: String
    $gcID: String
    $dateexpiration: String
  ) {
    utilisateur {
      add(
        id: $id
        customerID: $customerID
        role: $role
        active: $active
        nbquestions: $nbquestions
        lastlogin: $lastlogin
        gcType: $gcType
        gcID: $gcID
        dateexpiration: $dateexpiration
      ) {
        id
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUtilisateur($id: String!) {
    utilisateur {
      delete(id: $id) {
        id
      }
    }
  }
`;

const CREATE_ETABLISSEMENT = gql`
  mutation CreateEtablissement(
    $dirigeant: String!
    $raisonsociale: String!
    $cp: String!
    $siret: String!
    $ville: String!
    $metierID: String!
    $adresse: String!
    $nbemployes: Int!
    $telephone: String!
    $tvacode: String
    $userId: String!
  ) {
    etablissement {
      add(
        dirigeant: $dirigeant
        raisonsociale: $raisonsociale
        cp: $cp
        siret: $siret
        ville: $ville
        metierID: $metierID
        adresse: $adresse
        nbemployes: $nbemployes
        telephone: $telephone
        tvacode: $tvacode
        userId: $userId
      ) {
        id
      }
    }
  }
`;

const UPDATE_ETABLISSEMENT = gql`
  mutation UpdateEtablissement(
    $id: String!
    $dirigeant: String
    $raisonsociale: String
    $cp: String
    $siret: String
    $ville: String
    $metierID: String
    $adresse: String
    $nbemployes: Int
    $telephone: String
    $tvacode: String
    $userId: String
  ) {
    etablissement {
      update(
        id: $id
        dirigeant: $dirigeant
        raisonsociale: $raisonsociale
        cp: $cp
        siret: $siret
        ville: $ville
        metierID: $metierID
        adresse: $adresse
        nbemployes: $nbemployes
        telephone: $telephone
        tvacode: $tvacode
        userId: $userId
      ) {
        id
      }
    }
  }
`;

export {
  GET_USER,
  UPDATE_USER,
  CREATE_USER,
  CREATE_ETABLISSEMENT,
  UPDATE_ETABLISSEMENT,
  DELETE_USER,
};
