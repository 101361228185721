import { Document, Page, View, Text, Image } from "@react-pdf/renderer";



//import css
import styles from "../../assets/css/documents";

function ASRestaurantListe2(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            AFFICHAGES OBLIGATOIRES - SUITE
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View
          style={{ ...styles.body, color: props.textColor, fontSize: "16px" }}
        >
          <Text>3. Protection des mineurs</Text>
          <Text> </Text>
          <Text>
            Afficher le panneau sur la protection des mineurs et la répression
            de l'ivresse publique
          </Text>
          <Text> </Text>
          <Text>4. Licence</Text>
          <Text> </Text>
          <Text>
            Affichez le type de licence à l'extérieur si vous en possédez une.
          </Text>
          <Text> </Text>
          <Text>5. Les allergènes</Text>
          <Text> </Text>
          <Text>
            Identifiez les allergènes présents dans vos préparations afin de les
            indiquer à tout client qui en fait la demande.
          </Text>
          <Text> </Text>
          <Text>Vous devez afficher vos prix TTC.</Text>
          <Text> </Text>
          <Text>
            Ils doivent être affichés à l’extérieur au minimum à partir de 11h30
            pour le déjeuner et 18h pour le dîner et à l’intérieur de votre
            restaurant pendant au moins toute la durée du service et contenir la
            mention « prix service compris ».
          </Text>
          <Text> </Text>
          <Text>
            Indiquer également le prix d’au moins 5 vins, ou de 5 boissons si
            vous ne servez pas de vin. Dans les établissements ne servant pas de
            vin, une carte comportant au minimum la nature des boissons et les
            prix de cinq boissons couramment servies doit être affichée.
          </Text>
          <Text> </Text>
          <Text>
            Les cartes et menus doivent comporter, pour chaque prestation, le
            prix ainsi que la mention " boisson comprise " ou " boisson non
            comprise " et, dans tous les cas, indiquer pour les boissons : la
            nature et la contenance.
          </Text>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ASRestaurantListe2;
