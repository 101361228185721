import { useEffect, useState } from "react";
import LoadingComponent from "./Loading";

const GestionPriorite = ({ priorites }: { priorites: any[] }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simuler un délai de chargement
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div style={{ marginLeft: "10px", marginTop: "25px" }}>
        {isLoading ? (
          <LoadingComponent /> // Afficher le module Attente pendant le chargement
        ) : (
          priorites &&
          priorites.map((prio: any, index: number) => {
            return (
              <div style={{ marginLeft: "10px", marginTop: "25px" }}>
                <p>{prio}</p>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default GestionPriorite;
