import { gql } from "@apollo/client";

const LIST_RISQUES_USER = gql`
  query GetRisques($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        metier {
          unitesTravail {
            id
            libelle
            risques {
              libelle
              id
            }
          }
        }
        donnees {
          unitesTravail {
            id
            libelle
            risques {
              libelle
              id
            }
          }
        }
      }
    }
  }
`;

const CREATE_RISQUE = gql`
  mutation CreateRisque(
    $libelle: String!
    $gravite: Int!
    $frequence: Int!
    $description: String!
  ) {
    risque {
      add(
        libelle: $libelle
        gravite: $gravite
        frequence: $frequence
        description: $description
        categorieRisqueID: ""
      ) {
        id
      }
    }
  }
`;

export { LIST_RISQUES_USER, CREATE_RISQUE };
