import OwlCarousel, { OwlCarouselProps } from "react-owl-carousel"; 
import Aguatif from "../../assets/images/Clients/Aguatif.png";
import Factodoms from "../../assets/images/Clients/factodoms.png";
import Lucelec from "../../assets/images/Clients/LUCELEC.png";
import R2E from "../../assets/images/Clients/R2E.png";
import SarlSergeSenegas from "../../assets/images/Clients/SarlSergeSenegas.png";
import CambonDeco from "../../assets/images/Clients/CambonDeco.png";
import G2E from "../../assets/images/Clients/G2E.png";
import NicolasFacades from "../../assets/images/Clients/NicolasFacades.png";
import Servigeco from "../../assets/images/Clients/Servigeco.png";
import CapitalConseil from "../../assets/images/Clients/CapitalConseil.png";
import Gan from "../../assets/images/Clients/Gan.png";
import Ondulia from "../../assets/images/Clients/Ondulia.png";
import Taldi from "../../assets/images/Clients/Taldi.png";
import ConceptHygiene from "../../assets/images/Clients/concepthygiene.png";
import ImmoDuGolf from "../../assets/images/Clients/ImmoDuGolf.png";
import Ortalici from "../../assets/images/Clients/Ortalici.png";
import ZenBelle from "../../assets/images/Clients/Zen&Belle.png";
import Creche from "../../assets/images/Clients/Creche.png";
import JaguarProtection from "../../assets/images/Clients/JaguarProtection.png";
import PsbLouane from "../../assets/images/Clients/PsbLouane.png";
import LMDF from "../../assets/images/Clients/LMDF.png";

const images = [
  Aguatif,
  Factodoms,
  Lucelec,
  R2E,
  SarlSergeSenegas,
  CambonDeco,
  G2E,
  NicolasFacades,
  Servigeco,
  CapitalConseil,
  Gan,
  Ondulia,
  Taldi,
  ConceptHygiene,
  ImmoDuGolf,
  Ortalici,
  ZenBelle,
  Creche,
  JaguarProtection,
  PsbLouane,
  LMDF,
];


// caroussel options
const options: Omit<OwlCarouselProps, "children"> = {
  loop: true,

  center: true,
  nav: false,
  dots: false,
  margin: 80,
  autoplayHoverPause: false,

  autoplay: true,
  autoplayTimeout: 2500, // délai en millisecondes
  autoplaySpeed: 2000,
  smartSpeed: 2000,
  responsive: {
    0: {
      items: 1,
    },
    502: {
      items: 1,
    },
    768: {
      items: 1,
    },
    1024: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};

const ClientCaroussel = () => {
  return (
    <section className="confiance">
      <h2 style={{ fontSize: "1.5rem", marginTop: "1.5rem" }}>
        Ils nous font confiance
      </h2>
      <OwlCarousel
        {...options}
        className="caroussel"
        loop
        autoplayTimeout={4000}
        autoplay
      >
        {images.map((image: any, index: number) => (
          <div key={index}>
            <img
              src={image}
              alt={`Client ${index}`}
              style={{ objectFit: "contain", height: "100px" }}
            />
          </div>
        ))}
      </OwlCarousel>
    </section>
  );
};

export default ClientCaroussel;
