/**
 * This module provides a custom hook for tracking analytics events using ReactGA.
 * It exports a function called useAnalyticsEventTracker, which returns an eventTracker function.
 * The eventTracker function can be used to track custom events by calling it with the appropriate parameters.
 *
 * @module useAnalyticsEventTracker
 */

import ReactGA from "react-ga";

/**
 * Creates an eventTracker function for tracking analytics events.
 *
 * @param {string} [category="Blog category"] - The category of the event. Defaults to "Blog category".
 * @returns {Function} The eventTracker function.
 */
const useAnalyticsEventTracker = (category = "Blog category") => {

  // ReactGA.initialize({
  //   trackingId: "UA-XXXXXXXXX-X",
  //   gaOptions: {
  //     siteSpeedSampleRate: 100,
  //   },
  
  // })
  /**
   * Tracks an analytics event using ReactGA.
   *
   * @param {string} [action="test action"] - The action of the event. Defaults to "test action".
   * @param {string} [label="test label"] - The label of the event. Defaults to "test label".
   */
  const eventTracker = (action = "test action", label = "test label") => {
    console.log({label})
    ReactGA.event({ category, action, label });
  };

  return eventTracker;
};

export default useAnalyticsEventTracker;