import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import "../../assets/css/affichageDU.css";
import { useMutation } from "@apollo/client";
import { UPDATE_DOCUMENT_UNIQUE } from "../../queries/documentUnique";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";

const DocumentUnique = () => {
  const [mutateDocUnique, docUniqueMutation] = useMutation(
    UPDATE_DOCUMENT_UNIQUE
  );

  if (docUniqueMutation.loading) return <LoadingComponent />;
  if (docUniqueMutation.error) return <ErrorComponent error={docUniqueMutation.error} />;

  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Mon Document Unique</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            <div
              className="affichages-content-tab"
              style={{
                marginTop: "10%",
                marginBottom: "20px",
              }}
            >
              <button
                className="hover_button boutonmiseajour"
                onClick={() => {
                  mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 0,
                    },
                  });
                  window.location.href = "/editer-mon-document-unique";
                }}
              >
                Editer mon document unique
              </button>

              <div className="profile-line"></div>
              <button
                className="hover_button boutonmiseajour"
                onClick={() => {
                  window.location.href = "/consulter-mon-document-unique";
                }}
              >
                Consulter mon document unique
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentUnique;
