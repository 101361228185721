import "../assets/css/error.css";

const MobileComponent = () => {
  return (
    <div className="errorPage">
      <h1>ERREUR</h1>
      <h2>Page erronée</h2>
      <p>
        La page que vous recherchez n'est pas disponible sur mobile. Veuillez
        consulter le site sur un ordinateur ou une tablette. Vous pouvez cliquer
        sur le bouton ci-dessous pour revenir à la page d'accueil. Si le
        problème persiste, veuillez contacter le support.
      </p>
      {/* reload window and back home  */}
      <a href="/">Retour à l'accueil</a>
    </div>
  );
};

export default MobileComponent;
