import { Document, Page, View, Text, Image } from "@react-pdf/renderer";


import styles from "../../assets/css/documents";

function ARCSE(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  console.log("chargement du document");
  const months = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2031",
    "2032",
    "2033",
  ];
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            MEMBRES DU CSE
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View>
          <View>
            <Text style={{ ...styles.article, color: props.textColor }}>
              Tout salarié peut à tout moment contacter l'un de ses
              représentants au CSE. Il peut notamment communiquer à l'instance
              ou l'une de ses commissions toute information qu'il jugerait
              nécessaire sur une situation individuelle ou collective.
            </Text>
          </View>
        </View>

        <View
          style={{
            ...styles.container,
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  NOM Prénom
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  POSTE
                </Text>
              </View>
              <View style={styles.tableHeaderCell}>
                <Text style={{ color: props.textColor, fontWeight: "bold" }}>
                  COMMISSIONS
                </Text>
              </View>
            </View>
            {months.map((month, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell2}></View>
                <View style={styles.tableCell2}></View>
                <View style={styles.tableCell2}></View>
              </View>
            ))}
          </View>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ARCSE;
