const colors = [
    "#fff",
    "#000",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#B0B0B0",
    "#607d8b",
    "#43425D",
  ];

  export default colors;