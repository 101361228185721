import { useState } from "react";

import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import "../../assets/css/affichagesreglementaires.css";

import { Tabs, Tab, Box, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import PDFGenerator from "../../components/PDFGenerator";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#F2F2F2",
    },
  },
});

const AffichagesReglementaires = () => {
  const [value, setValue] = useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Mes Affichages Réglementaires</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            <p>
              Les informations de base renseignées dans la partie{" "}
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="./mon-compte"
              >
                <i className="fa fa-cog"></i> Mon compte{" "}
              </a>
              seront directement implémentées dans vos affichages.
            </p>
          </div>
          <div className="affichages-content">
            <ThemeProvider theme={theme}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab value="one" label="AFFICHAGES REGLEMENTAIRES" />
                <Tab value="two" label="AFFICHAGES  SPECIFIQUES" />
                <Tab value="three" label="AFFICHAGES EXCEPTIONNELS" />
              </Tabs>
            </ThemeProvider>
            <Box
              sx={{ bgcolor: "none", p: 1, width: "70%" }}
              role="tabpanel"
              hidden={value !== "one"}
              id="one"
              aria-labelledby="one"
            >
              <PDFGenerator type="reglementaire" />
            </Box>
            <Box
              sx={{ bgcolor: "none", p: 1, width: "70%" }}
              role="tabpanel"
              hidden={value !== "two"}
              id="two"
              aria-labelledby="two"
            >
              <PDFGenerator type="specifique" />
            </Box>
            <Box
              sx={{ bgcolor: "none", p: 1, width: "70%" }}
              role="tabpanel"
              hidden={value !== "three"}
              id="three"
              aria-labelledby="three"
            >
              <PDFGenerator type="exceptionnel" />
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffichagesReglementaires;
