import { Document, Page } from "@react-pdf/renderer";

//import css
import styles from "../../assets/css/documents";

import ARDiscrim from "./AR_Discrim";
import ARHarcel from "./AR_Harcel";
import ARConges from "./AR_Conges";
import ARInfos from "./AR_Infos";
import ARCSE from "./AR_CSE";
import ARElec from "./AR_Elec";
import Arelecverif from "./AR_Elec_Verif";
import ARFumer from "./AR_Fumer";
import ARMarianne from "./AR_Marianne";

function Tousreglementaires(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  return (
    <>
      <Document style={styles.document}>
        <Page>
          <ARInfos
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></ARInfos>
        </Page>
        <Page>
          <ARDiscrim
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></ARDiscrim>
        </Page>

        <Page>
          <ARHarcel
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></ARHarcel>
        </Page>

        <Page>
          <ARConges
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></ARConges>
        </Page>

        <Page>
          <ARCSE
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></ARCSE>
        </Page>

        <Page>
          <Arelecverif
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></Arelecverif>
        </Page>

        <Page>
          <ARElec
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></ARElec>
        </Page>

        <Page>
          <ARFumer
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            radius={0}
            etablissementAffichages={props.etablissementAffichages}
            logo={props.logo}
          ></ARFumer>
        </Page>

        <Page>
          <ARMarianne
            contourColor={props.contourColor}
            textColor={props.textColor}
            backgroundColor={props.backgroundColor}
            logo={props.logo}
            radius={0}
          ></ARMarianne>
        </Page>
      </Document>
    </>
  );
}

export default Tousreglementaires;
