import { useContext, useEffect, useState } from "react";
import "../assets/css/navbar.css";
import { useMarqueBlanche } from "../context/MarqueBlanche";

// define props interface
interface INavbarProps {
  type: string;
}

const Navbar = ({ type }: INavbarProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);
  const [active, setActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { navBarColor, navBarTextColor } = useMarqueBlanche();

  const buttonMenu = () => {
    // if user is in mobile view
    if (window.innerWidth <= 768) {
      return (
        <div
          className={`${active ? "hide" : "navbar-header-menu"}`}
          onClick={() => setActive(!active)}
        >
          <i className="fa fa-bars"></i>
        </div>
      );
    } else {
      return null;
    }
  };

  const buttonMenu2 = () => {
    // if user is in mobile view
    if (window.innerWidth <= 768) {
      return (
        <div className="navbar-header-menu" onClick={() => setActive(!active)}>
          <i className="fa fa-bars"></i>
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
      return;
    }
    // admin links
    if (window.location.pathname === "/dashboard") {
      const links = document.querySelectorAll(".navbar-sections a");
      links[0].classList.add("active");
    } else if (
      window.location.pathname === "/clients" ||
      window.location.pathname === "/client" ||
      window.location.pathname === "/clientgc"
    ) {
      const links = document.querySelectorAll(".navbar-sections a");
      links[1].classList.add("active");
    } else if (
      window.location.pathname === "/metiers" ||
      window.location.pathname === "/metier" ||
      window.location.pathname === "/metier-view"
    ) {
      const links = document.querySelectorAll(".navbar-sections a");
      links[2].classList.add("active");
    }

  }, []);


  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const toggleSubMenu2 = () => {
    setIsSubMenuOpen2(!isSubMenuOpen2);
  };

  if (type === "grandcompte") {
    return (
      <>
        {buttonMenu()}
        <div
          className={`navbar${active ? "-active" : ""}`}
          style={{ backgroundColor: navBarColor, color: navBarTextColor }}
        >
          <div
            className="navbar-header"
            style={{ backgroundColor: navBarColor }}
          >
            <h3>Grand Compte</h3>
            {buttonMenu2()}
          </div>
          <div
            className="navbar-sections"
            style={{ backgroundColor: navBarColor }}
          >
            <a href="./dashboard">
              <i className="fa fa-home"></i>
              Accueil
            </a>
            <a href="/mes-clients">
              <i className="fa fa-users"></i>
              Mes clients
            </a>
            <a href="/gcfaq">
              <i className="fa fa-info-circle"></i>FAQ
            </a>
          </div>
        </div>
      </>
    );
  } else if (type === "admin") {
    return (
      <>
        {buttonMenu()}
        <div
          className={`navbar${active ? "-active" : ""}`}
          style={{ backgroundColor: navBarColor }}
        >
          <div
            className="navbar-header"
            style={{ backgroundColor: navBarColor }}
          >
            <h3>Administration</h3>
            {buttonMenu2()}
          </div>
          <div
            className="navbar-sections"
            style={{ backgroundColor: navBarColor }}
          >
            <a href="./dashboard">
              <i className="fa fa-home"></i>
              Accueil
            </a>
            <a href="./clients">
              <i className="fa fa-users"></i>
              Gestion des utilisateurs
            </a>
            <a href="./metiers">
              <i className="fa fa-cog"></i>
              Gestion des métiers
            </a>
          </div>
        </div>
      </>
    );
  } else if (type === "noUser") {
    return (
      <>
        {buttonMenu()}
        <div
          className={`navbar${active ? "-active" : ""}`}
          style={{ backgroundColor: navBarColor }}
        >
          <div
            className="navbar-header"
            style={{ backgroundColor: navBarColor }}
          >
            <h3>Accueil</h3>
            {buttonMenu2()}
          </div>
          <div
            className="navbar-sections"
            style={{ backgroundColor: navBarColor }}
          >
            <a href="./dashboard">
              <i className="fa fa-home"></i>
              Accueil
            </a>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {buttonMenu()}
        <div
          className={`navbar${active ? "-active" : ""}`}
          style={{ backgroundColor: navBarColor, color: navBarTextColor }}
        >
          <div className="navbar-header">
            <h3>MENU</h3>
            {buttonMenu2()}
          </div>
          <div className="navbar-sections">
            <a style={{ color: navBarTextColor }} href="./dashboard">
              <i className="fa fa-home"></i>
              Tableau de bord
            </a>
            <a style={{ color: navBarTextColor }} href="#" onClick={toggleSubMenu}>
              <i className="fab fa-readme"></i>
              Mes démarches de sécurité
              <span>
                {isSubMenuOpen ? (
                  <i className="fas fa-chevron-down"></i>
                ) : (
                  <i className="fas fa-chevron-up"></i>
                )}
              </span>
            </a>
            <div
              className={`sub-menu ${
                isSubMenuOpen ? "navbar-dropdown-active" : "navbar-dropdown"
              }`}
            >
              <a style={{ color: navBarTextColor }} href="/mon-document-unique">
                Mon Document Unique
              </a>
              <a style={{ color: navBarTextColor }} href="/mon-plan-dactions">
                Mon Plan d'Actions
              </a>
              <a
                style={{ color: navBarTextColor }}
                href="/mes-affichages-reglementaires"
              >
                Mes Affichages Réglementaires
              </a>
            </div>
            <a
              style={{ color: navBarTextColor }}
              href="#"
              onClick={toggleSubMenu2}
            >
              <i className="fas fa-tools"></i>
              Mes tutos
              <span>
                {isSubMenuOpen2 ? (
                  <i className="fas fa-chevron-down"></i>
                ) : (
                  <i className="fas fa-chevron-up"></i>
                )}
              </span>
            </a>
            <div
                className={`sub-menu ${
                    isSubMenuOpen2 ? "navbar-dropdown-active" : "navbar-dropdown"
                }`}
            >
              <a style={{color: navBarTextColor}} href="/demarche-generale">
                Démarche générale
              </a>
              <a style={{color: navBarTextColor}} href="/document-unique">
Document Unique
              </a>
              <a
                  style={{color: navBarTextColor}}
                  href="/plan-actions"
              >
                Plan d’actions
              </a>
              <a
                  style={{color: navBarTextColor}}
                  href="/affichages-reglementaires"
              >
                Affichages réglementaires
              </a>


            </div>
            <a style={{color: navBarTextColor}} href="/news-reglementaires">
              <i className="far fa-newspaper"></i>
              INRS News
            </a>
            <a style={{ color: navBarTextColor }} href="/faq-utilisateur">
              <i className="far fa-question-circle"></i>
              Aide & Support
            </a>
            <a style={{ color: navBarTextColor }} href="/mon-compte">
              <i className="fa fa-cog"></i>
              Mon compte
            </a>
          </div>
        </div>
      </>
    );
  }
};

export default Navbar;
