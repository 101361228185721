import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Airbus from '../../assets/images/Partenaires/Airbus.png';
import CrealiaOccitanie from '../../assets/images/Partenaires/CrealiaOccitanie.png';
import ReseauInitiative from '../../assets/images/Partenaires/ReseauInitiative.png';
import CompagnieFiduciaire from '../../assets/images/Partenaires/CompagnieFiduciaire.png';
import NextinnovBanquepop from '../../assets/images/Partenaires/NextinnovBanquepop.png';

const images = [
  Airbus,
  CrealiaOccitanie,
  ReseauInitiative,
  CompagnieFiduciaire,
  NextinnovBanquepop,
];

const PartCaroussel = () => {
  return (
    <section className="confiance py-2">
      <h2>Nos partenaires</h2>
      <div className="center d-flex space-between">
        {images.map((image: any, index: number) => (
          <div key={index}>
            <img
              src={`${image}`}
              alt={`Client ${index}`}
              style={{ objectFit: "contain", height: "100px" }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default PartCaroussel;
