import { gql } from "@apollo/client";

const GET_METIERS = gql`
  query {
    metiers {
      id
      libelle
      ape
      active
    }
  }
`;

const GET_FULL_METIER = gql`
  query GetFullMetier($id: String!) {
    metier(id: $id) {
      id
      libelle
      ape
      unitesTravail {
        id
        libelle
        risques {
          id
          libelle
          description
          gravite
          frequence
          mesures {
            id
            libelle
            ponderation
            specificite
          }
        }
      }
    }
  }
`;

const LINKS_METIER_MUTATION = gql`
  mutation AddLinksMetier($id: String!, $linksAdd: [LinkMetier!]) {
    links {
      metier(id: $id) {
        add(links: $linksAdd)
      }
    }
  }
`;

const LINKS_METIER_UPDATE_MUTATION = gql`
  mutation UpdateLinksMetier($id: String!, $linksUpdate: [LinkMetier!]) {
    links {
      metier(id: $id) {
        update(links: $linksUpdate)
      }
    }
  }
`;

const UPDATE_METIER = gql`
  mutation ModifyMetier($id: String!, $libelle: String!, $ape: String!, $active: Boolean) {
    metier {
      update(id: $id, libelle: $libelle, ape: $ape, active: $active) {
        id
      }
    }
  }
`;

const DELETE_METIER = gql`
  mutation DeleteMetier($id: String!) {
    metier {
      delete(id: $id) {
        id
      }
    }
  }
`;

const CREATE_METIER = gql`
  mutation CreateMetier($libelle: String!, $ape: String!) {
    metier {
      add(libelle: $libelle, ape: $ape) {
        id
      }
    }
  }
`;

export {
  GET_METIERS,
  GET_FULL_METIER,
  LINKS_METIER_MUTATION,
  UPDATE_METIER,
  CREATE_METIER,
  LINKS_METIER_UPDATE_MUTATION,
  DELETE_METIER,
};
