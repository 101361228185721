import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { useLocation } from "react-router";
import marquesblanches from "../assets/marqueBlanche.json";

// Créer le contexte
const MarqueBlancheContext = createContext<Partial<MarqueBlancheProps>>({});

const DEFAULT_MARQUE = {
  title: "DECOUVREZ LA SOLUTION SECURIZ BY AT CONSULTING",
  domain: "securiz.fr",
  navBarColor: "#08223d",
  logoPath: "/marques/logoLabel.png",
  logoHomePath: "/marques/logo.png",
  logoLoginPath: "/marques/logoLogin.png",

  topBarTextColor: "rgba(0, 0, 0, 0.54)",
  navBarTextColor: "#fff",
  topBarColor: "#fff",
}

export type MarqueBlancheProps = {
  domain: string;
  logoPath: string;
  title: string;

  logoLoginPath?: string;
  logoHomePath?: string;
  navBarColor?: string;
  navBarTextColor?: string;
  topBarColor?: string;
  topBarTextColor?: string;
};

// Créer le provider
export const MarqueBlancheProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const [marqueBlanche, setMarqueBlanche] = useState<
    MarqueBlancheProps | undefined
  >(undefined); // Chemin du logo par défaut

  useEffect(() => {
    // Obtenir l'URL du domaine
    const domain = window.location.hostname;

    for (const marqueBlanche of marquesblanches) {
      if (domain.includes(marqueBlanche.domain)) {
        setMarqueBlanche(marqueBlanche);
        break;
      }
    }
    // Définir le logo marque blanche
  }, [location]);

  return (
    <MarqueBlancheContext.Provider value={{ ...marqueBlanche }}>
      {children}
    </MarqueBlancheContext.Provider>
  );
};

// Créer le hook personnalisé pour utiliser le contexte
export const useMarqueBlanche = (): MarqueBlancheProps & {
  isMarqueBlanche: boolean;
} => {
  const context = useContext(MarqueBlancheContext);
  if (context === undefined) {
    throw new Error(
      "useMarqueBlanche doit être utilisé à l'intérieur d'un MarqueBlancheProvider"
    );
  }
  return {
   ...DEFAULT_MARQUE,
   isMarqueBlanche: context.domain !== undefined,
    ...context,
  };
};
