import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import Distance from "../../assets/images/distance.png";

// import queries
import styles from "../../assets/css/documents";
import { useState } from "react";

function AEDistance(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  const [entrepriseName] = useState<string>("");

  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            {entrepriseName}
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View
          style={{
            ...styles.bodyae,
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <View style={{ ...styles.paragraphe }}>
            <Text style={{ color: props.textColor }}>
              Pour se protéger et protéger les autres, conservez 1 m de distance
            </Text>
          </View>
          <View style={{ ...styles.dessin, color: props.textColor }}>
            <Image src={Distance} />
          </View>
        </View>
        <View style={styles.bottom}>
          <Text style={{ ...styles.copyright, color: props.textColor }}>
            Copyright 2023 - Tous droits réservés AT Consulting
          </Text>
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default AEDistance;
