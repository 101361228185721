import { useCallback, useRef, useState } from "react";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { debounce } from "lodash";
import { Modal, Button } from "@mui/material";
// import icon add from material ui
import { Add } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_FULL_METIER,
  LINKS_METIER_MUTATION,
  LINKS_METIER_UPDATE_MUTATION,
  UPDATE_METIER,
} from "../../queries/metier";
import { useLocation } from "react-router-dom";
import { deleteDuplicates } from "../../utils/formatDatas";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import client from "../../utils/http-common";
import { CREATE_UNITE_TRAVAIL } from "../../queries/uniteTravail";
import { CREATE_RISQUE } from "../../queries/risques";
import { CREATE_MESURE, UPDATE_MESURE } from "../../queries/mesures";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";

function formatDatas(datas: any) {
  var ret = [];
  for (let uniteTravail of datas.metier.unitesTravail) {
    var risques: any = deleteDuplicates(
      uniteTravail.risques,
      (item1: any, item2: any) => item1.libelle === item2.libelle
    );
    ret.push({
      ...uniteTravail,
      risques: risques,
    });
  }
  return ret;
}

async function addUnitetravail(metierID: string, uniteTravail: string) {
  const res = await client.mutate({
    mutation: CREATE_UNITE_TRAVAIL,
    variables: {
      libelle: uniteTravail,
    },
  });
  console.log("aa", res.data.uniteTravail.add.id, metierID);
  await client.mutate({
    mutation: LINKS_METIER_MUTATION,
    variables: {
      id: metierID,
      linksAdd: {
        uniteTravailID: res.data.uniteTravail.add.id,
      },
    },
  });
  return res;
}

async function addRisque(
  metierID: string,
  uniteTravailID: string,
  risque: string,
  description: string
) {
  const res = await client.mutate({
    mutation: CREATE_RISQUE,
    variables: {
      libelle: risque,
      description: description,
      frequence: 0,
      gravite: 0,
    },
  });
  await client.mutate({
    mutation: LINKS_METIER_MUTATION,
    variables: {
      id: metierID,
      linksAdd: {
        uniteTravailID: uniteTravailID,
        risque: { id: res.data.risque.add.id },
      },
    },
  });
  return res;
}

async function addMesure(
  metierID: string,
  uniteTravailID: string,
  risqueID: string,
  mesure: string,
  ponderation: number
) {
  const res = await client.mutate({
    mutation: CREATE_MESURE,
    variables: {
      libelle: mesure,
      ponderation: ponderation,
    },
  });
  await client.mutate({
    mutation: LINKS_METIER_MUTATION,
    variables: {
      id: metierID,
      linksAdd: {
        uniteTravailID: uniteTravailID,
        risque: { id: risqueID },
        mesure: { id: res.data.mesure.add.id },
      },
    },
  });
  return res;
}

const AdminMetier = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [open, setOpen] = useState(false);
  const [newUtStr, setNewUtStr] = useState("");
  const [open2, setOpen2] = useState(false);
  const [newRisqueStr, setNewRisqueStr] = useState("");
  const [open3, setOpen3] = useState(false);
  const [newDescriptionStr, setNewDescriptionStr] = useState("");
  const [open4, setOpen4] = useState(false);
  const [newMesureStr, setNewMesureStr] = useState("");
  const [newMesurePonderation, setNewMesurePonderation] = useState(0);
  const [metierStr, setMetierStr] = useState("");
  const [APEStr, setAPEStr] = useState("");
  const [uniteTravail, setUniteTravail] = useState("");
  const [risque, setRisque] = useState("");
  const [description, setDescription] = useState("");
  const [metier, setMetier] = useState<any>(undefined);
  const [mutateMetier, metierMutation] = useMutation(UPDATE_METIER);
  const [updateLink, linkMutation] = useMutation(LINKS_METIER_UPDATE_MUTATION);

  const debouncedFunctionsRef: any = useRef({});

const getDebouncedFunction = (id:string) => {
  if (!debouncedFunctionsRef.current[id]) {
    debouncedFunctionsRef.current[id] = debounce((variables) => {
      if (!linkMutation.loading) {
        updateLink({ variables });
      }
    }, 2000);
  }

  return debouncedFunctionsRef.current[id];
};

  const { /*data, */ loading, error, refetch } = useQuery(GET_FULL_METIER, {
    variables: { id: queryParams.get("id") },
    onCompleted(datas) {
      const _metier = formatDatas(datas);
      setMetier(_metier);
      setMetierStr(datas.metier.libelle);
      setAPEStr(datas.metier.ape);
      if (_metier.length === 0) return;
      setUniteTravail(_metier[0].id);
      if (_metier[0].risques.length === 0) return;
      setRisque(_metier[0].risques[0].id);
      setDescription(_metier[0].risques[0].id);
    },
    fetchPolicy:"no-cache"
  });

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;
  if (!metier) return <LoadingComponent />;

  // get metier id from url
  // const url = window.location.href;
  // const metierId = url.substring(url.lastIndexOf("/") + 1);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-content">
          <h2>Ajouter une unité de travail</h2>
          <TextField
            onChange={(e) => setNewUtStr(e.target.value)}
            value={newUtStr}
            label="Nom de l'unité de travail"
            style={{ width: "80%" }}
          />
          <div className="modal-buttons">
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              sx={{ margin: "10px" }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (newUtStr.trim() === "") return;
                addUnitetravail(queryParams.get("id")!, newUtStr).then(() => {
                  setOpen(false);
                  refetch();
                });
              }}
              sx={{ margin: "10px" }}
            >
              Ajouter
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={open2}
        onClose={() => setOpen2(false)}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-content">
          <h2>Ajouter un risque</h2>
          <TextField
            label="Nom du risque"
            value={newRisqueStr}
            onChange={(e) => setNewRisqueStr(e.target.value)}
            style={{ width: "80%" }}
          />
          <div className="modal-buttons">
            <Button
              variant="contained"
              onClick={() => setOpen2(false)}
              sx={{ margin: "10px" }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (newRisqueStr.trim() === "") return;
                addRisque(
                  queryParams.get("id")!,
                  uniteTravail,
                  newRisqueStr,
                  ""
                ).then(() => {
                  setOpen2(false);
                  refetch();
                });
              }}
              sx={{ margin: "10px" }}
            >
              Ajouter
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={open3}
        onClose={() => setOpen3(false)}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-content">
          <h2>Ajouter une description</h2>
          <TextField
            onChange={(e) => setNewDescriptionStr(e.target.value)}
            value={newDescriptionStr}
            label="Nom de la description"
            style={{ width: "80%" }}
          />
          <div className="modal-buttons">
            <Button
              variant="contained"
              onClick={() => setOpen3(false)}
              sx={{ margin: "10px" }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              sx={{ margin: "10px" }}
              onClick={() => {
                if (newDescriptionStr.trim() === "") return;
                const risqueLibelle = metier
                  .find((ut: any) => ut.id === uniteTravail)
                  ?.risques.find((r: any) => r.id === risque)?.libelle;
                if (!risqueLibelle) return;
                addRisque(
                  queryParams.get("id")!,
                  uniteTravail,
                  risqueLibelle,
                  newDescriptionStr
                ).then(() => {
                  setOpen3(false);
                  refetch();
                });
              }}
            >
              Ajouter
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={open4}
        onClose={() => setOpen4(false)}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-content">
          <h2>Ajouter une mesure préventive</h2>
          <TextField
            label="Nom de la mesure préventive"
            style={{ width: "80%" }}
            onChange={(e) => setNewMesureStr(e.target.value)}
            value={newMesureStr}
          />
          <TextField
            type="number"
            label="Ponderation"
            style={{ width: "80%", marginTop: "10px" }}
            onChange={(e) => setNewMesurePonderation(Number(e.target.value))}
            value={newMesurePonderation}
          />
          <div className="modal-buttons">
            <Button
              variant="contained"
              onClick={() => setOpen4(false)}
              sx={{ margin: "10px" }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (newMesureStr.trim() === "") return;
                addMesure(
                  queryParams.get("id")!,
                  uniteTravail,
                  description,
                  newMesureStr,
                  newMesurePonderation
                ).then(() => {
                  setOpen4(false);
                  refetch();
                });
                setOpen4(false);
              }}
              sx={{ margin: "10px" }}
            >
              Ajouter
            </Button>
          </div>
        </div>
      </Modal>
      <div className="dashboard">
        <Navbar type="admin" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Gestion des métiers</h1>
          <div className="pageLine"></div>
          <Button style={{width:"40%", margin:"5px"}} onClick={(e) => {
            if (!metierMutation.loading)
              mutateMetier({
                variables: {
                  id: queryParams.get("id")!,
                  ape:  APEStr,
                  libelle: metierStr,
                },
              });
          }}>Indiquer comme mis à jour </Button>
          <div className="inputs">
            <TextField
              label="Nom du métier"
              style={{ width: "40%" }}
              value={metierStr}
              onChange={(e) => {
                if (!metierMutation.loading)
                  mutateMetier({
                    variables: {
                      id: queryParams.get("id")!,
                      libelle: e.target.value,
                      ape: APEStr,
                    },
                  });
                setMetierStr(e.target.value);
              }}
            />
            <TextField
              label="Code APE"
              style={{ width: "40%" }}
              value={APEStr}
              onChange={(e) => {
                if (!metierMutation.loading)
                  mutateMetier({
                    variables: {
                      id: queryParams.get("id")!,
                      ape: e.target.value,
                      libelle: metierStr,
                    },
                  });
                setAPEStr(e.target.value);
              }}
            />
          </div>
          <div className="inputs">
            <Select
              labelId="select-label"
              id="select-label"
              style={{ width: "30%", marginLeft: "10px" }}
              value={uniteTravail}
              onChange={(e) => setUniteTravail(e.target.value)}
            >
              {metier.map((ut: any, i: number) => (
                <MenuItem key={i} value={ut.id}>
                  {ut.libelle}
                </MenuItem>
              ))}
            </Select>
            <button
              className="importButton"
              style={{
                border: "none",
                width: "5%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <Add />
            </button>
            <Select
              style={{ width: "30%" }}
              value={risque}
              onChange={(e) => setRisque(e.target.value)}
            >
              {(
                metier.find((ut: any) => ut.id === uniteTravail)?.risques || []
              ).map((r: any, i: number) => (
                <MenuItem key={i} value={r.id}>
                  {r.libelle}
                </MenuItem>
              ))}
            </Select>
            <button
              className="importButton"
              style={{
                border: "none",
                width: "5%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                setOpen2(true);
              }}
            >
              <Add />
            </button>
            <Select
              style={{ width: "30%" }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            >
              {metier
                .find((ut: any) => ut.id === uniteTravail)
                ?.risques.find((r: any) => r.id === risque)
                ?.duplicates?.map((d: any, i: number) => (
                  <MenuItem key={i} value={d.id}>
                    {d.description}
                  </MenuItem>
                )) || []}
            </Select>
            <button
              className="importButton"
              style={{
                border: "none",
                width: "5%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                setOpen3(true);
              }}
            >
              <Add />
            </button>
          </div>
          <div
            className="inputs"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{display:"flex", gap:"20px"}}>
            <TextField
                    type="number"
                    id="standard-name"
                    label="Fréquence"
                    variant="outlined"
                    size="small"
                    value={metier
                      .find((ut: any) => ut.id === uniteTravail)
                      ?.risques.find((r: any) => r.id === risque)
                      ?.duplicates.find((d: any) => d.id === description)?.frequence}
                    onChange={(e) => {
                      const debouncedUpdateLink = getDebouncedFunction(`${description}frequence`);
                      debouncedUpdateLink({
                        id: queryParams.get("id")!,
                        linksUpdate: [
                          {
                            uniteTravailID: uniteTravail,
                            risque: { id: description, frequence: Number(e.target.value) }, 
                          },
                        ],
                      });
                      setMetier((v: any) => {
                        const met = deepClone(v);
                        var nice = met
                          .find((ut: any) => ut.id === uniteTravail)
                          ?.risques.find((r: any) => r.id === risque)
                          ?.duplicates.find((d: any) => d.id === description);
                        nice.frequence = e.target.value;
                        return met;
                      });
                    }}
                  />
                           <TextField
                    type="number"
                    id="standard-name"
                    label="Gravité"
                    variant="outlined"
                    size="small"
                    value={metier
                      .find((ut: any) => ut.id === uniteTravail)
                      ?.risques.find((r: any) => r.id === risque)
                      ?.duplicates.find((d: any) => d.id === description)?.gravite}
                    onChange={(e) => {
                      const debouncedUpdateLink = getDebouncedFunction(`${description}gravite`);
                      debouncedUpdateLink({
                        id: queryParams.get("id")!,
                        linksUpdate: [
                          {
                            uniteTravailID: uniteTravail,
                            risque: { id: description, gravite: Number(e.target.value) }, 
                          },
                        ],
                      });
                      setMetier((v: any) => {
                        const met = deepClone(v);
                        var nice = met
                          .find((ut: any) => ut.id === uniteTravail)
                          ?.risques.find((r: any) => r.id === risque)
                          ?.duplicates.find((d: any) => d.id === description);
                        nice.gravite = e.target.value;
                        return met;
                      });
                    }}
                  />
                  </div>
            {(
              metier
                .find((ut: any) => ut.id === uniteTravail)
                ?.risques.find((r: any) => r.id === risque)
                ?.duplicates.find((d: any) => d.id === description)?.mesures ||
              []
            ).map((m: any, i: number) => (
              <div
                className="mesures"
                style={{
                  marginTop: "20px",
                }}
                key={i}
              >
                <p>Mesure préventive</p>
                <h2>{m.libelle}</h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <TextField
                    type="number"
                    id="standard-name"
                    label="Pondération"
                    variant="outlined"
                    size="small"
                    value={m.ponderation}
                    onChange={(e) => {
                      const debouncedUpdateLink = getDebouncedFunction(`${m.id}ponderation`);
                      debouncedUpdateLink({
                        id: queryParams.get("id")!,
                        linksUpdate: [
                          {
                            uniteTravailID: uniteTravail,
                            risque: { id: description },
                            mesure: {
                              id: m.id,
                              ponderation: Number(e.target.value),
                            },
                          },
                        ],
                      });
                      setMetier((v: any) => {
                        const met = deepClone(v);
                        var nice = met
                          .find((ut: any) => ut.id === uniteTravail)
                          ?.risques.find((r: any) => r.id === risque)
                          ?.duplicates.find((d: any) => d.id === description)
                          ?.mesures.find((me: any) => me.id === m.id);
                        nice.ponderation = e.target.value;
                        return met;
                      });
                    }}
                  />
                  <TextField
                    id="standard-name"
                    label="Spécificité métier"
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "10px" }}
                    value={m.specificite || ""}
                    onChange={(e) => {
                      const debouncedUpdateLink = getDebouncedFunction(`${m.id}specificite`);
                      debouncedUpdateLink({
                        id: queryParams.get("id")!,
                        linksUpdate: [
                          {
                            uniteTravailID: uniteTravail,
                            risque: { id: description },
                            mesure: {
                              id: m.id,
                              specificite: e.target.value,
                            },
                          },
                        ],
                      });

                      setMetier((v: any) => {
                        const met = deepClone(v);
                        var nice = met
                          .find((ut: any) => ut.id === uniteTravail)
                          ?.risques.find((r: any) => r.id === risque)
                          ?.duplicates.find((d: any) => d.id === description)
                          ?.mesures.find((me: any) => me.id === m.id);
                        nice.specificite = e.target.value;
                        return met;
                      });
                    }}
                  />
                </div>
              </div>
            ))}
            <button
              className="importButton"
              style={{
                border: "none",
                width: "30%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
              onClick={() => {
                setOpen4(true);
              }}
            >
              <Add />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMetier;
