import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options01 = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  smartSpeed: 600,
  autoplayTimeout: 2000,
  items: 1,
  number: 1,
  center: true,
};

const SentencesCaroussel = () => {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "2rem",
        fontSize: "2em",
      }}
    >
      <OwlCarousel {...options01} loop>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            {"Document Unique (DUERP)"}
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Plan d'actions
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Amélioration des conditions de travail
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Réduction des Accidents du Travail / Maladies Professionnelles
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Chasses aux Risques Pros
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Moyens de Prévention
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Affichages Réglementaires
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Santé et Sécurité au Travail
          </h1>
        </div>
        <div style={{ width: "100%" }}>
          <h1 style={{ color: "white", textAlign: "center", width: "100%" }}>
            Evaluation risques professionnels
          </h1>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default SentencesCaroussel;
