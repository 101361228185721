	/**
 * The entry point of the React application. This file is responsible for rendering the root component
 * of the application and providing the Apollo client for GraphQL operations.
 *
 * @module index
 */
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import client from "./utils/http-common";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

	/**
 * Renders the root component of the application.
 *
 * The root component is wrapped in the ApolloProvider to provide the Apollo client for GraphQL operations.
 */
root.render(
  // <React.StrictMode>
    <ApolloProvider client={client}>
    <App />
    </ApolloProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
