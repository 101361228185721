export default function formatDate(date: string) {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();
  console.log("formatDate : " + dateObject);

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function formatDateYear(date: string) {
  if (!date) return "Aucune";
  try {
    const _date = new Date(date);
    const formattedDate = _date.toLocaleDateString("en-GB");
    if (formattedDate === "Invalid Date") {
      return "Aucune";
    }
    return formattedDate;
  } catch {
    return "Aucune";
  }
}
