// gestionMetiers.tsx
/**
 * Ceci est la page d'accueil de la gestion métier côté Admin.
 * @module
 */

import { useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import "../../assets/css/metiers.css";

import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, CircularProgress, TextField } from "@mui/material";
import {
  CREATE_METIER,
  GET_METIERS,
  UPDATE_METIER,
  DELETE_METIER,
} from "../../queries/metier";
import { GET_USERS } from "../../queries/users";
import { useMutation, useQuery } from "@apollo/client";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import AntSwitch from "../../components/AntSwitch";
import API_URL from "../../utils/api";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMetierImport} from "./useMetierImport";

import LinearProgress from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";

/**
   * Cette fonction génère la page générale de gestion des métiers
   * Elle est appelée depuis {@link App | App.tsx}
   * 
   * @returns Elle retourne les composants MUI d'affichage de la page de gestion des métiers
   * @category Interface Administrateurs
   * @remarks
   * Ce code est une composante React fonctionnelle qui représente une page de gestion des métiers pour un administrateur.

Le composant AdminMetiers contient plusieurs éléments :

Il utilise le hook useState de React pour déclarer et gérer l'état local de la variable searchText. searchText est utilisée pour stocker le texte saisi dans la case de recherche.

La fonction QuickSearchToolbar est définie à l'intérieur du composant AdminMetiers et retourne un composant GridToolbarQuickFilter. Ce composant représente une case de recherche permettant de filtrer les métiers.

Les fonctions EditButton et ViewButton sont définies pour générer des boutons qui redirigent vers des liens spécifiques pour chaque métier. Ces boutons sont utilisés pour l'affichage et la modification des métiers dans la liste.

Les constantes columns et rows définissent les colonnes et les données des métiers à afficher dans le composant DataGrid de la bibliothèque Material-UI. Chaque métier a un ID et un nom.

La constante filteredRows filtre les lignes en fonction du texte tapé dans la case de recherche. Si searchText est vide, toutes les lignes sont affichées. Sinon, seules les lignes dont le nom de métier correspond au texte tapé en minuscules sont affichées.

Le rendu du composant AdminMetiers inclut des éléments HTML et des composants React pour afficher une interface utilisateur. Il y a une barre de navigation (Navbar), une barre supérieure (TopBar), des boutons d'ajout et d'importation de métiers, et un composant DataGrid pour afficher la liste des métiers.

Le composant AdminMetiers est exporté pour être utilisé dans d'autres parties de l'application.
   */


const  Loader = ({show,progress}:{progress: number, show:boolean}) => (
  <Backdrop sx={{ zIndex: 3 }} open={show}>
      <LinearProgress
          sx={{height:"20px", width:"50%", borderRadius:"10px"}}
      variant="determinate"
      value={Math.trunc(progress)}
    />
  </Backdrop>
)

const DeleteButton = (props: any) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (
      window.confirm(
        `Etes vous sur de vouloir supprimer le métier ${props.row.libelle}`
      )
    ) {
      setLoading(true);
      props
        .deleteMetier({ variables: { id: props.row.id } })()
        .then(() => {
          setLoading(false);
          window.location.reload();
        });
    }
  };

  return (
    <IconButton
      disabled={loading || !!props.row.nbUsers || props.row.active}
      onClick={handleClick}
    >
      {loading ? <CircularProgress size={24} /> : <DeleteIcon />}
    </IconButton>
  );
};

const AdminMetiers = () => {
  const [autoFocused, setAutoFocused] = useState<[boolean, boolean, boolean]>([
    false,
    false,
    false,
  ]);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [csv_libelle, setCsv_libelle] = useState("");
  const [csv_ape, setCsv_ape] = useState("");
  const {
    data: metierData,
    loading: metierLoading,
    error: metierError,
  } = useQuery(GET_METIERS);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USERS);
  const [mutateAddMetier, addMetierMutation] = useMutation(CREATE_METIER);
  const [mutateMetier] = useMutation(UPDATE_METIER);
  const [deleteMetier] = useMutation(DELETE_METIER);
  useEffect(() => {
    if (!metierData?.metiers) return;
    if (searchText === "") {
      setFilteredRows(metierData.metiers);
    } else {
      setFilteredRows(
        metierData.metiers.filter((row: any) =>
          row.libelle.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [searchText, metierData]);
  const {show, importMetier, progress} = useMetierImport();
  if (metierLoading || userLoading) return <LoadingComponent />;
  if (metierError || userError) return <ErrorComponent error={metierError} />;
  /**
   * Cette fonction génère la case de recherche de métiers
   * @module QuickSearchToolBar
   * @returns Elle retourne un composant de recherche GridToolBarQuickFilter
   */
  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <div onClick={() => setAutoFocused([true, false, false])}>
          <GridToolbarQuickFilter
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            placeholder="Rechercher un métier"
            style={{ width: "100%" }}
            autoFocus={autoFocused[0]}
          />
        </div>
      </Box>
    );
  }

  /**
   * Cette constante génère un bouton qui construit un lien de redirection
   * à partir de l'id du métier correspondant au numéro de la ligne
   */
  const EditButton = (params: any) => (
    <strong>
      <EditIcon
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = `/metier?id=${params.row.id}`;
        }}
      />
    </strong>
  );

  const ViewButton = (params: any) => (
    <strong>
      <VisibilityIcon
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = `/metier-view?id=${params.row.id}`;
        }}
      />
    </strong>
  );

  const columns = [
    {
      field: "edit",
      headerName: "Editer",
      width: 70,
      renderCell: EditButton,
    },
    {
      field: "view",
      headerName: "Voir",
      width: 70,
      renderCell: ViewButton,
    },
    { field: "libelle", headerName: "Métier", width: 300 },
    { field: "nbUsers", headerName: "Utilisateurs", width: 100 },
    {
      field: "active",
      headerName: "Actif",
      width: 100,
      renderCell: (args: any) => (
        <AntSwitch
          style={{ alignSelf: "right" }}
          defaultChecked={args.row.active}
          onChange={(event) => {
            mutateMetier({
              variables: {
                ...args.row,
                active: event.target.checked,
              },
            });
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params: any) => (
        <DeleteButton {...params} deleteMetier={deleteMetier} />
      ),
    },
  ];

  return (
    <div>
      <Loader progress={progress} show={show}/>
      <div className="dashboard">
        <Navbar type="admin" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Gestion des métiers</h1>
          <div className="pageLine"></div>
          <div className="buttons">
            <div
              className="importButton"
              style={{ cursor: addMetierMutation.loading ? "not-allowed" : "" }}
              onClick={() => {
                if (addMetierMutation.loading) return;
                if (csv_ape === "" || csv_libelle === "") {
                  alert("Veuillez les champs APE et libellé");
                  return;
                }
                mutateAddMetier({
                  variables: {
                    libelle: csv_libelle,
                    ape: csv_ape,
                  },
                }).then(() => window.location.reload());
              }}
            >
              Ajouter un métier
            </div>
            <div
              className="importButton"
              onClick={importMetier(csv_libelle, csv_ape)}
            >
              Importer au format CSV
            </div>
            <TextField
              id="outlined-basic"
              label="Libellé du métier"
              variant="outlined"
              value={csv_libelle}
              onChange={(e) => setCsv_libelle(e.target.value)}
              onClick={() => setAutoFocused([false, true, false])}
              autoFocus={autoFocused[1]}
            />
            <TextField
              id="outlined-basic"
              label="Code APE"
              variant="outlined"
              style={{ marginLeft: "20px" }}
              value={csv_ape}
              onChange={(e) => setCsv_ape(e.target.value)}
              onClick={() => setAutoFocused([false, false, true])}
              autoFocus={autoFocused[2]}
            />
          </div>
          <DataGrid
            rows={filteredRows.map((metier: any) => ({
              id: metier.id,
              libelle: metier.libelle,
              ape: metier.ape,
              active: metier.active,
              nbUsers: userData.utilisateurs.filter(
                (user: any) => user.etablissements[0]?.metier.id === metier.id
              ).length,
            }))}
            columns={columns}
            style={{ height: 400, width: "80%", margin: "auto" }}
            slots={{ toolbar: QuickSearchToolbar }}
            autoPageSize={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminMetiers;
