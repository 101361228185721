import React from "react";
import { useState } from "react";
// import { useParams } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import "../../assets/css/client.css";
import Card from "../../components/card";

import { Modal } from "@mui/material";
import PlanAction from "../../components/PlanAction";
import DocU from "../../components/docU";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_USER } from "../../queries/user";
import { GET_PLAN_ACTION } from "../../queries/planAction";
import { GET_FULL_DOCUMENT_UNIQUE_MODEL } from "../../queries/documentUnique";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";

function formatDate(date: string) {
  try {
    const dateObject = new Date(date);

    const formattedDate = dateObject
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .join(" / ");
    return formattedDate;
  } catch {
    return "None";
  }
}

const GCClient = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const userQuery = useQuery(GET_USER, {
    variables: {
      id: queryParams.get("id"),
    },
  });

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  const planActionRes = useQuery(GET_PLAN_ACTION, {
    variables: { id: queryParams.get("id") },
  });

  const { data, loading, error } = useQuery(GET_FULL_DOCUMENT_UNIQUE_MODEL, {
    variables: {
      id: queryParams.get("id"),
    },
  });

  // get client id from url
  //   const { id } = useParams<{ id: string }>();

  if (userQuery.loading) return <LoadingComponent />;
  if (userQuery.error) return <ErrorComponent error={userQuery.error} />;
  if (planActionRes.loading) return <LoadingComponent />;
  if (planActionRes.error)
    return <ErrorComponent error={planActionRes.error} />;
  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;

  const haveDocUnique =
    userQuery.data.utilisateur.etablissements.length === 0 ||
    !userQuery.data.utilisateur.etablissements[0].donnees?.documentUnique;

  if (userQuery.data.utilisateur.etablissements.length === 0)
    return <div>Ce client ne possède pas d'entreprise</div>;

  return (
    <>
      {haveDocUnique && (
        <React.Fragment>
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DocU userId={queryParams.get("id") || ""} />
          </Modal>
          <Modal open={open} onClose={handleClose}>
            <div className="modal-style">
              <h2 id="modal-modal-title">Plan d'action</h2>
              <p id="modal-modal-description">
                <PlanAction
                  etablissementID={
                    planActionRes.data.utilisateur.etablissements[0].id
                  }
                  actions={
                    planActionRes.data.utilisateur.etablissements[0].donnees
                      ?.documentUnique?.planaction?.actions
                  }
                  data={data}
                />
              </p>
            </div>
          </Modal>
        </React.Fragment>
      )}
      <div className="dashboard">
        <Navbar type="grandcompte" />
        <div className="dashboard-content">
          <TopBar />
          <h1>
            {userQuery.data.utilisateur.etablissements.length !== 0
              ? userQuery.data.utilisateur.etablissements[0].dirigeant
              : "Pas d'entreprise"}
          </h1>
          <div className="pageLine"></div>
          <div className="client-content">
            <div className="client-left">
              <div className="client-img">
                <img
                  src="https://www.w3schools.com/howto/img_avatar.png"
                  alt="client"
                />
              </div>
              <div className="client-info">
                <div className="client-info-name">
                  <h3>
                    {userQuery.data.utilisateur.etablissements.length !== 0
                      ? userQuery.data.utilisateur.etablissements[0]
                          .raisonsociale
                      : "Pas d'entreprise"}
                  </h3>
                  <li className="fa fa-edit"></li>
                </div>
                <h5>
                  {userQuery.data.utilisateur.etablissements.length !== 0 &&
                    userQuery.data.utilisateur.etablissements[0].adresse}
                  {userQuery.data.utilisateur.etablissements.length !== 0
                    ? userQuery.data.utilisateur.etablissements[0].telephone
                    : "Pas d'entreprise"}
                </h5>
                <p>
                  Derniere connexion :{" "}
                  {formatDate(userQuery.data.utilisateur.lastlogin)}
                </p>
              </div>
            </div>
            <div className="client-right">
              <button
                className="btn btn-danger"
                onClick={() => {
                  // disable confirm
                  if (
                    window.confirm(
                      "Voulez-vous vraiment désactiver cet utilisateur ?"
                    )
                  ) {
                    // disable user
                  }
                }}
              >
                Désactiver
              </button>
            </div>
          </div>
          <div className="client-buttons">
            <button onClick={() => setOpen2(true)}>
              AVANCEMENT DU DOCUMENT
            </button>
            {planActionRes.data.utilisateur.etablissements[0].donnees
              ?.documentUnique?.planaction?.actions && (
              <button onClick={() => setOpen(true)}>
                VOIR LE PLAN D'ACTION
              </button>
            )}
          </div>
          <div className="client-cards">
            <Card
              title="Actions"
              value={
                planActionRes.data.utilisateur.etablissements[0].length !== 0
                  ? planActionRes.data.utilisateur.etablissements[0].donnees
                      .documentUnique?.planaction?.actions.length || 0
                  : "Pas d'entreprise"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GCClient;
