import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import TopBar from "../../components/TopBar";

// define props interface
interface ITutosProps {
  title: string;
  url:string;
}

const TutosPages = ({ title, url }: ITutosProps) => {


  return (
    <div>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Tutoriel - {title}</h1>
          <div className="pageLine"></div>
          <div className="content">
            <iframe
              width="80%"
              height="600px"
              src={
                url +
                "?autoplay=1&amp;cc_load_policy=1&amp;controls=1&amp;disablekb=0&amp;enablejsapi=0&amp;fs=1&amp;iv_load_policy=1&amp;loop=0&amp;rel=0&amp;showinfo=1&amp;start=0&amp;wmode=transparent&amp;theme=dark&amp;mute=0"
              }
              allowFullScreen
              title="video"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutosPages;
