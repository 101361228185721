import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// imgs
import Logo from "../assets/images/logo.png";

// icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

// css personnalisés
import "../assets/css/accueil/accueil.css";
import "../assets/css/accueil/accueilAboutUs.css";
import "../assets/css/accueil/accueilNavbar.css";
import "../assets/css/accueil/accueilFooter.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const Confidentialite = () => {
  return (
    <div>
      {/* Navbar */}
      <nav className="navbarHome">
        <ul>
          <li>
            <a href="./">
              <img src={Logo} alt="Logo" />
            </a>
          </li>
          <li>
            <a href="#section-1">Accueil</a>
          </li>
          <li>
            <a href="#section-2">A Propos</a>
          </li>
          <li>
            <a href="#section-3">Nos Plus</a>
          </li>
          <li>
            <a href="#section-4">Solution</a>
          </li>
          <li>
            <a href="#section-5">Contact</a>
          </li>
          <li>
            <button
              className="loginButton"
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              SE CONNECTER
            </button>
          </li>
        </ul>
      </nav>

      {/* Section 2 */}
      <section id="section-2" className="section2">
        <div className="text-legal">
          <h1>Politique de confidentialité</h1>
          <div className="line-accueil"></div>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            1 . Charte de gestion des données personnelles
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Bienvenue sur le site www.securiz.org. Pour AT CONSULTING, la
            protection des données personnelles est essentielle et constitue un
            principe important de son Code d’Ethique.<br></br>
            <br></br>
            En vous connectant ou en consultant le site, vous reconnaissez avoir
            lu, compris, et accepté, sans limitation ni réserve, la charte sur
            la protection des données à caractère personnel (la « charte ») et
            nos conditions générales d’utilisation.<br></br>
            <br></br>
            La charte vise à vous informer sur les données personnelles que nous
            collectons, sur les droits que vous pouvez faire valoir à l’égard de
            notre utilisation de vos données personnelles et décrit les mesures
            que nous mettons en œuvre afin de les protéger.<br></br>
            <br></br>
            AT CONSULTING s’engage, en matière de protection des données
            personnelles, à être en conformité vis-à-vis de l’ensemble des lois
            et règlements français et européens, et plus particulièrement, du
            Règlement Général Européen de Protection des Données Personnelles
            2016/679 du 27 Avril 2016.<br></br>
            <br></br>
            AT CONSULTING se réserve le droit de modifier ou d’ajuster cette
            charte à tout moment si cela s’avère nécessaire.<br></br>
            <br></br>
            Responsable de traitement<br></br>
            <br></br>
            La société AT CONSULTING, inscrite au registre du commerce et des
            sociétés de Toulouse, sous le numéro 885 264 721, dont le siège
            social est situé 31, allée du camp de Catis – 31840 SEILH – France,
            est le responsable du traitement des données personnelles collectées
            via le site internet www.securiz.co<br></br>
            <br></br>
          </Typography>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            2. Nature des données collectées
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>Les données collectées</b>
            <br></br>
            <br></br>
            Vos données personnelles sont collectées et traitées sur les bases
            légales suivantes :<br></br>
            <br></br>– à des fins d’intérêt légitime ;<br></br>– sur le
            fondement de votre consentement ;<br></br>– dans le but d’assurer le
            respect de nos obligations légales et réglementaires.<br></br>
            <br></br>
            De manière générale, vos données sont donc collectées directement
            auprès de vous à l’occasion de :<br></br>
            <br></br>– votre inscription dans le formulaire de contact ;
            <br></br>– votre asouscription au service;<br></br>– votre
            navigation sur le site.<br></br>
            <br></br>
            Nous sommes amenés à collecter et traiter les données personnelles
            suivantes :<br></br>
            <br></br>– données d’identification (nom, prénom, email, adresse
            postale, téléphone) ;<br></br>– données de connexion (ex : adresse
            IP, logs de connexion) ;<br></br>– données commerciales (ex :
            données nécessaires à la réalisation d’actions de prospection,
            d’enquêtes de satisfaction et statistique).<br></br>
            <br></br>
            Des cookies et autres traceurs ou technologies similaires (« cookies
            ») peuvent être installés et/ou lus dans votre navigateur lors de
            votre visite sur le site.<br></br>
            <br></br>
            Seules les données personnelles qui sont strictement nécessaires au
            regard des finalités décrites ci-dessous et pour réaliser des
            statistiques sur la fréquentation du site, sont collectées.<br></br>
            <br></br>
            <b>Contenu embarqué depuis d’autres sites</b>
            <br></br>
            <br></br>
            Les articles de ce site peuvent inclure des contenus intégrés (par
            exemple des vidéos, images, articles…). Le contenu intégré depuis
            d’autres sites se comporte de la même manière que si le visiteur se
            rendait sur cet autre site. Ces sites web pourraient collecter des
            données sur vous, utiliser des cookies, embarquer des outils de
            suivis tiers, suivre vos interactions avec ces contenus embarqués si
            vous disposez d’un compte connecté sur leur site web.<br></br>
            <br></br>
          </Typography>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            3. Finalité des traitements des données collectées et durée de
            conservation
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>Finalités du traitement</b>
            <br></br>
            <br></br>
            Fourniture d’un service :<br></br>
            <br></br>– relation clients pour toute question ou réclamation
            <br></br>– envoi d’informations sur la modification ou l’évolution
            de nos produits et services<br></br>– gestion de l’exercice de vos
            droits sur vos données personnelles<br></br>
            <br></br>
            Marketing et prospection commerciale relative aux produits et
            services :<br></br>
            <br></br>– actions de prospection commerciales personnalisées
            <br></br>– recueil des avis clients<br></br>– élaboration de
            statistiques commerciales afin d’identifier les profils des clients
            de AT CONSULTING et d’améliorer l’offre produits et services qui
            leur est proposée<br></br>
            <br></br>
            Partenariats commerciaux :<br></br>
            <br></br>– envoi de messages marketing, publicitaires et
            promotionnels relatifs aux produits et services de partenaires
            commerciaux.<br></br>
            <br></br>A titre de règle générale, AT CONSULTING se refuse de
            vendre, louer ou commercialiser les données personnelles de
            l’utilisateur.
            <br></br>
            <br></br>
            <b>Durée de conservation des données</b>
            <br></br>
            <br></br>
            En cas de collecte, les données personnelles de l’utilisateur sont
            conservées uniquement pendant la durée nécessaire à la finalité de
            leur traitement.<br></br>
            <br></br>
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Finalité</TableCell>
                <TableCell>Base juridique</TableCell>
                <TableCell>Conservation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Répondre à vos demandes d’information (ex. : contacts presse,
                  demande d’informations générales)
                </TableCell>
                <TableCell>
                  La base juridique dépendra du sujet de votre demande
                </TableCell>
                <TableCell>
                  Les données sont gardées pendant trois (3) ans à compter du
                  dernier contact ou pour la durée de prescription applicable.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Création d’un compte</TableCell>
                <TableCell>L’exécution d’un contrat</TableCell>
                <TableCell>
                  Les données sont conservées pour la durée de la relation
                  contractuelle plus 5 ans en cas d’abonnement.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Statistiques</TableCell>
                <TableCell>Consentement de l’utilisateur</TableCell>
                <TableCell>
                  Les données sont conservées pendant une durée maximale de 13
                  mois.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            4. Transfert et destinataires des données
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Vos données personnelles sont susceptibles d’être communiquées :
            <br></br>
            <br></br>– en interne : à des départements ou services de AT
            CONSULTING responsables du traitement<br></br>– en externe : à
            certains partenaires et/ou sous-traitants, à des fins de gestion de
            vos demandes, d’analyses et d’enquêtes, aux plates- formes de
            réseaux sociaux pour interagir avec nos sites et applications.
            <br></br>
            <br></br>
            Tout transfert de données vers un pays tiers hors de l’Union
            Européenne (par exemple le Canada ou les Etats-Unis), est réalisé en
            conformité avec la réglementation applicable et de manière à assurer
            une protection adéquate des données.<br></br>
            <br></br>
          </Typography>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            5. Sécurité et confidentialité des données
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            Le responsable du traitement met en œuvre des mesures appropriées
            pour préserver la sécurité et la confidentialité des données
            personnelles et, notamment, pour empêcher qu’elles soient déformées,
            endommagées, ou que des tiers non autorisés y aient accès.<br></br>
            <br></br>
          </Typography>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            6. Exercice des droits
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>Exercice des droits</b>
            <br></br>
            <br></br>
            En application de cette charte, l’utilisateur peut, à tout moment,
            exercer ses droits suivants :<br></br>
            <br></br>– L’accès, la rectification et/ou la suppression de toute
            donnée personnelle qui pourrait être détenue par AT CONSULTING
            <br></br>– La limitation du traitement ou l’opposition au traitement
            de ses données personnelles<br></br>– La portabilité de ses données
            personnelles, c’est-à-dire de le transfert dans un format structuré
            et lisible<br></br>– L’opposition à l’exploitation commerciale de
            ses données<br></br>
            <br></br>
            Ces demandes doivent être adressées à AT CONSULTING par courrier à
            l’adresse 31, allée du camp de Catis – 31840 SEILH – France<br></br>
            <br></br>
            Toute demande doit être accompagnée de la photocopie d’un titre
            d’identité en cours de validité, signé et faire mention de l’adresse
            à laquelle AT CONSULTING pourra contacter le demandeur.<br></br>
            <br></br>
            La réponse sera adressée dans le mois suivant la réception de la
            demande. Ce délai d’un mois peut être prolongé de deux mois, si la
            complexité de la demande et/ou le nombre de demandes l’exigent.
            <br></br>
            <br></br>
          </Typography>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: "20px" }}
          >
            7. Les cookies
          </Typography>
          <Typography variant="body1" style={{ marginTop: "20px" }}>
            <b>7.1 – Principes</b>
            <br></br>
            <br></br>
            Les cookies désignent des fichiers textes qui sont placés et stockés
            sur le terminal utilisé (ordinateur, smartphone, tablette ou tout
            autre appareil permettant de naviguer sur Internet) ou des
            technologies similaires qui permettent de lire ou écrire des
            informations sur le terminal d’un utilisateur et de suivre son
            activité sur Internet ou dans une application.<br></br>
            <br></br>
            Certains de ces cookies sont nécessaires voire indispensables pour
            accéder aux fonctionnalités du site. D’autres permettent par exemple
            d’établir des statistiques et volumes de fréquentation et des
            statistiques sur la consultation et l’utilisation des divers
            éléments composant le site, en optimisant et en personnalisant les
            contenus affichés. Ils permettent aussi d’apporter des services
            additionnels tels que le partage de contenus présents sur un site
            Internet via les réseaux sociaux.<br></br>
            <br></br>
            <b>7.2 – Les différents types de cookies et leur finalité</b>
            <br></br>
            <br></br>
            Les différents types de cookies<br></br>
            <br></br>
            Les cookies qui peuvent être installés sur votre terminal lorsque
            vous naviguez sur le site, peuvent avoir les finalités suivantes : –
            Les <b>cookies techniques</b> rendent possible votre navigation sur
            le Site, assurent la sécurité du Site et vous permettent d’accéder
            aux différents services et contenus du Site.<br></br>
            <br></br>– Les <b>cookies d’analyse d’audience</b> mesurent la
            fréquentation des différents contenus et rubriques de notre Site
            afin de les évaluer et de mieux les organiser.<br></br>
            <br></br>– Les <b>cookies de partage avec les réseaux sociaux</b>{" "}
            (Facebook, Twitter, LinkedIn, Viadeo, YouTube) sont destinés à
            améliorer l’interactivité du site en partageant des contenus de
            notre site avec d’autres personnes.<br></br>
            <br></br>
            Vous pouvez aussi, à tout moment, refuser l’utilisation des cookies,
            en modifiant la configuration de votre navigateur Internet. Les
            liens suivants vous permettront de savoir comment modifier vos
            souhaits en matière de cookies :<br></br>
            <br></br>– Pour Microsoft™ : http://windows.microsoft.com<br></br>–
            Pour Safari™ : http://support.apple.com<br></br>– Pour Chrome™ :
            http://support.google.com<br></br>– Pour Firefox™ :
            http://support.mozilla.org<br></br>– Pour Opera™ :
            http://help.opera.com<br></br>
            <br></br>
            Veuillez noter que si vous désinstallez des «cookies» ou si vous
            vous opposez à leur installation, vous risquez de ne pas être en
            mesure de bénéficier de certains des services du site.<br></br>
            <br></br>
            <b>7.3 – Plugins et outils émanant de tiers</b>
            <br></br>
            <br></br>
            Nous sommes susceptibles d’inclure sur notre site, des plugins ou
            outils informatiques émanant de tiers, pour les flux RSS par
            exemple, ou pour vous permettre de partager des contenus de notre
            site avec d’autres personnes. Le site utilise notamment les plugins
            de « YouTube ».<br></br>
            <br></br>
            L’utilisation de cookies par des tiers est soumise aux politiques de
            protection des données personnelles de ces tiers.<br></br>
            <br></br>
          </Typography>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footerLogo">
          <img src={Logo} alt="Logo" />
          <p>Ⓒ 2023 , AT Consulting, Tous droits réservés</p>
        </div>
        <div className="footerRight">
          <div className="footerLinks">
            <a href="./mentions-legales">Mentions légales</a>
            <a href="./confidentialite">Confidentialité</a>
            <a href="./cgvu">CGVU</a>
          </div>
          <div className="footerIcons">
            <a href="https://www.linkedin.com/">
              <LinkedInIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
            <a href="https://www.facebook.com/">
              <FacebookIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
            <a href="https://www.youtube.com/">
              <YouTubeIcon style={{ color: "#fff", fontSize: "35px" }} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Confidentialite;
