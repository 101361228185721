// import { useParams } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import "../../assets/css/client.css";
import Card from "../../components/card";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER, UPDATE_USER } from "../../queries/user";
import { useLocation } from "react-router-dom";
import { formatDateYear } from "../../utils/formatDate";
import { GET_USERS } from "../../queries/users";
import { useEffect, useState } from "react";
import { Modal, TextField, Button } from "@mui/material";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import { computeDate } from "./expirationDateUtil";
import API_URL from "../../utils/api";

const ClientGC = () => {
  const [open, setOpen] = useState(false);
  // get client id from url
  //   const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [usersNumber, setUsersNumber] = useState(0);

  const [joursAbonnement, setJoursAbonnement] = useState(0);
  const [mutateUser, userMutation] = useMutation(UPDATE_USER);

  const UserID = queryParams.get("userid");
  const [Mail, setMail] = useState("");

  const userQuery = useQuery(GET_USER, {
    variables: {
      id: queryParams.get("id"),
    },
    onCompleted() {
      usersQuery.refetch();
    },
  });

  const usersQuery = useQuery(GET_USERS, {
    skip: true,
    onCompleted(data) {
      if (!userQuery.data.utilisateur.gcID) {
        return;
      }
      setUsersNumber(
        data.utilisateurs.filter((user: any) => {
          return (
            user.gcID === userQuery.data.utilisateur.gcID &&
            user.id !== userQuery.data.utilisateur.id
          );
        }).length
      );
    },
  });

  useEffect(() =>{
    fetch(`${API_URL}/admin/users/${UserID}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => setMail(data.UserAttributes[2].Value));
  
  } , [UserID]);

  if (userQuery.loading) return <LoadingComponent />;
  if (userQuery.error) return <ErrorComponent error={userQuery.error} />;
  if (usersQuery.loading) return <LoadingComponent />;
  if (usersQuery.error) return <ErrorComponent error={usersQuery.error} />;

  if (userQuery.data.utilisateur.etablissements.length === 0)
    return <div>Ce client ne possède pas d'entreprise</div>;

  const addJoursAbonnement = async (jours: number) => {
    const date = computeDate({
      days: jours,
      previousDate: userQuery.data.utilisateur.dateexpiration,
    });
    await mutateUser({
      variables: {
        id: userQuery.data.utilisateur.id,
        dateexpiration: date,
      },
    });
    setJoursAbonnement(jours);
  };
 

  
 

  return (
    <div className="dashboard">
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-content">
          <h2>Modifier la raison sociale</h2>
          <TextField
            label="Raison sociale"
            value={userQuery.data.utilisateur.etablissements[0].raisonsociale}
            onChange={(e) => {
              // mutation update raison sociale
            }}
            style={{ width: "80%" }}
          />
          <div className="modal-buttons">
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              sx={{ margin: "10px" }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => {}}
              sx={{ margin: "10px" }}
            >
              Modifier
            </Button>
          </div>
        </div>
      </Modal>
      <Navbar type="admin" />
      <div className="dashboard-content">
        <TopBar />
        <h1>{userQuery.data.utilisateur.etablissements[0].dirigeant}</h1>
        <div className="pageLine"></div>
        <div className="client-content">
          <div className="client-left">
            <div className="client-img">
              <img
                src="https://www.w3schools.com/howto/img_avatar.png"
                alt="client"
              />
            </div>
            <div className="client-info">
              <div className="client-info-name">
                <h3>
                  {userQuery.data.utilisateur.etablissements[0].raisonsociale}
                </h3>
                <li
                  className="fa fa-edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                ></li>
              </div>
              <h5>
                {Mail}
                {"  "}
                {userQuery.data.utilisateur.etablissements[0].telephone}
              </h5>
              <p>
                Derniere connexion :{" "}
                {formatDateYear(userQuery.data.utilisateur.lastlogin)}
              </p>
            </div>
          </div>
          <div className="client-right">
            <p
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Ajouter des jours sur l'abonnement
            </p>
            <input
              type="number"
              placeholder="Ex : 360 (pour 1 an)"
              style={{
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "5px",
              }}
              value={joursAbonnement}
              onChange={(e) => setJoursAbonnement(parseInt(e.target.value))}
            />
            <button
              disabled={userMutation.loading}
              className="btn btn-primary"
              onClick={() => {
                addJoursAbonnement(joursAbonnement).then(() => {
                  setJoursAbonnement(0);
                });
              }}
            >
              Ajouter
            </button>
          </div>
        </div>
        <div className="client-buttons">
          <button
            onClick={() => {
              window.location.href = `/clientsgclist?gcid=${userQuery.data.utilisateur.gcID}`;
            }}
          >
            LISTE DE CLIENTS
          </button>
        </div>
        <div className="client-cards">
          <Card title="Clients Actifs" value={usersNumber.toString()} />
        </div>
      </div>
    </div>
  );
};

export default ClientGC;
