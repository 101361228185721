import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import React, { useEffect } from "react";

import AR_Discrim from "../../assets/pdf/AR_Discrim.json";
import styles from "../../assets/css/documents";

function ARDiscrim(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: string;

}) {

  const model = AR_Discrim;
  const { etablissementAffichages } = props;
  
  useEffect(() => {
    // regenerate PDFViewer
  }, [props]);
  
  return (
    // <PDFViewer>
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* Header */}
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            Lutte contre la discrimination
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>

        <View
          style={{
            ...styles.body,
            backgroundColor: props.backgroundColor,
            borderRadius: props.radius,
          }}
        >
          <Text style={{ ...styles.articleTitle1, color: props.textColor }}>
            {model.title}
          </Text>
          {model.articles.map((article: any) => (
            <React.Fragment key={article}>
              <Text
                style={{ ...styles.articleTitle, color: props.textColor }}
                key={article}
              >
                {article.title}
              </Text>
              <Text
                style={{
                  ...styles.articleSubtitle,
                  color: props.textColor,
                }}
              >
                {article.subtitle}
              </Text>
              <Text style={{ ...styles.articleText, color: props.textColor }}>
                {article.text}
              </Text>
            </React.Fragment>
          ))}
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
    // </PDFViewer>
  );
}

export default ARDiscrim;

