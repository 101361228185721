/**
 * This module exports a functional component called A4PagePaysage, which represents an A4 page in landscape orientation.
 * It takes in optional children as ReactNode, which will be rendered inside the page.
 */
import React, { ReactNode } from "react";

/**
 * Props interface for the A4PagePaysage component.
 */
interface A4PageProps {
  children?: ReactNode;
}

/**
 * A functional component that represents an A4 page in landscape orientation.
 *
 * @param props - The props for the A4PagePaysage component.
 * @returns The rendered A4PagePaysage component.
 */
const A4PagePaysage: React.FC<A4PageProps> = ({ children }) => {
  /**
   * The style object for the div element representing the A4 page.
   */
  const divStyle = {
    width: "297mm",
    height: "210mm",
    backgroundColor: "white",
  };

  /**
   * Renders the A4PagePaysage component.
   *
   * @returns The rendered A4PagePaysage component.
   */
  return <div style={divStyle}>{children}</div>;
};

export default A4PagePaysage;