import { useState } from "react";
import AntSwitch from "../../components/AntSwitch";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";

import "../../assets/css/clients.css";

import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../queries/users";
import { USER_ID_LS_KEY } from "../../queries/uniteTravail";
import { formatDateYear } from "../../utils/formatDate";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";

function getStats(data: any, myID: any) {
  const me = data.utilisateurs.find((user: any) => user.id === myID);
  if (!me) return;

  console.log("me", me);
  if (!me.gcID) return [];
  const gcUsers = data.utilisateurs.filter(
    (it: any) => it.gcID === me.gcID && it.id !== me.id
  );

  return gcUsers.map((it: any) => {
    var ret = {
      id: it.id,
      name: "Pas d'entreprise",
      actif: it.active,
      datefin: it.dateexpiration || "Pas d'abonnement",
      isGC: false,
    };
    if (it.etablissements.length > 0) {
      ret.name = it.etablissements[0].raisonsociale;
    }
    return ret;
  });
}

const GCClients = () => {
  const [searchText, setSearchText] = useState("");

  const {  loading, error } = useQuery(GET_USERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setRows(getStats(data, localStorage.getItem(USER_ID_LS_KEY)));
    },
  });

  const [rows, setRows] = useState(
    [] as {
      id: string;
      name: string;
      actif: boolean;
      datefin: string;
      isGC: boolean;
    }[]
  );

  if (loading) return <LoadingComponent />;
  if (error)
    return (
      <>
        <ErrorComponent error={error} />
        {error.message}
      </>
    );
  const filteredRows = rows.filter((row) => {
    return (
      row.name && row.name.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="dashboard">
      <Navbar type="grandcompte" />
      <div className="dashboard-content">
        <TopBar />
        <h1>Liste de mes clients</h1>
        <div className="pageLine"></div>
        <>
          <div
            className="tab-content"
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <div className="filter-box">
              Filtrer : &nbsp;
              <input
                type="text"
                className="filter-input"
                placeholder="Nom, entreprise"
                id="filter"
                onChange={handleSearch}
              />
            </div>
            {rows === null ? (
              <div className="loading-data">
                <p>Chargement des données...</p>
              </div>
            ) : (
              <>
                {filteredRows.map((item, index) => (
                  <div
                    key={index}
                    className="content-client"
                    style={{
                      borderBottom:
                        index === filteredRows.length - 1
                          ? "none"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    <img
                      src="https://yt3.ggpht.com/ytc/AMLnZu-cJtmScMlHrawyHuHgDOY8w1G-j-IyHBSNcDJ8=s176-c-k-c0x00ffffff-no-rj"
                      alt="clients"
                      className="content-client-icon"
                    />
                    <h5 className="content-title">{item.name}</h5>
                    <p className="content-text">
                      {item.actif ? "Actif" : "Inactif"}
                    </p>
                    <div className="content-connexion">
                      <p className="content-text2">Date d'expiration : </p>
                      <p className="content-text3">
                        {formatDateYear(item.datefin)}
                      </p>
                    </div>
                    <AntSwitch
                    disabled={true}
                      checked={item.actif ? true : false}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                    <Link to={"/mon-client?id=" + item.id}>
                      <EditIcon className="edit-icon" />
                    </Link>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default GCClients;
