import { useState, useEffect } from "react";
import { GET_PLAN_ACTION_DATE } from "../../queries/planAction";
import { useQuery } from "@apollo/client";
import RisqueCalcul from "../../utils/RisqueCalcul";

function useActionDatas(priorites: any) {
  const { data, error, loading } = useQuery(GET_PLAN_ACTION_DATE, {
    variables: {
      idEtablissement: localStorage.getItem("idEntreprise"),
    },
    errorPolicy: "ignore",
  });

  const [actions, setActions] = useState<any>(null);

  useEffect(() => {
    if (priorites && data?.etablissement?.documentUnique?.planaction?.actions) {
      console.log({priorites})
      setActions(
        data?.etablissement?.documentUnique?.planaction?.actions.map(
          (action: any) => ({
            ...action,
            delai: RisqueCalcul.getEcheance(action, action.dateouverture, priorites)
          })
        )
      );
    }
  }, [data, priorites]);
  return { actions, error, loading: loading, dateedition: data?.etablissement?.documentUnique?.planaction?.dateedition};
}

export default useActionDatas;
