function deleteDuplicates<T>(
  array: T[],
  predicate: (item1: T, item2: T) => boolean
) {
  const result: any[] = [];

  array.forEach((item: any) => {
    var found: any = result.find((existingItem) =>
      predicate(item, existingItem)
    );
    if (!found) {
      result.push({
        ...item,
        duplicates: [item],
      });
    } else {
      found.duplicates.push(item);
    }
  });
  return result;
}

const getRisquesFromData = (data: any) => {
  // getting uts of the user
  const user = data?.utilisateur.etablissements[0].donnees.unitesTravail;

  // mapping over uts type
  var uts = data?.utilisateur.etablissements[0].metier.unitesTravail
    .map((item: any) => {
      // getting the user ut, if it does not exists, don't show it
      const userUt = user.find((userItem: any) => userItem.id === item.id);
      if (userUt === undefined) {
        return null;
      }

      // Delete duplicates on risques.libelle
      var risques: any = deleteDuplicates(
        item.risques,
        (item1: any, item2: any) => item1.libelle === item2.libelle
      );

      // mapping over risques type to add the checked property (verifying all duplicates)
      risques = risques.map((risque: any) => {
        const userRisque = userUt.risques.map((userRisque: any) => {
          var ret = false;

          for (let duplicate of risque.duplicates) {
            if (duplicate.id === userRisque.id) {
              if (userRisque.haveDesc !== false) duplicate.checked = true;
              duplicate.haveDesc = userRisque.haveDesc;
              if (userRisque.gravite !== undefined) {
                duplicate.gravite = userRisque.gravite;
              }
              if (userRisque.frequence !== undefined) {
                duplicate.frequence = userRisque.frequence;
              }
              ret = true;
            } else if (duplicate.checked !== true) {
              duplicate.checked = false;
            }
          }
          risque.duplicates.sort((a: any, b: any) => (a.id < b.id ? -1 : 1));
          return ret;
        });
        return {
          ...risque,
          checked:
            userRisque.find((it: boolean | undefined) => it === true) !==
            undefined,
        };
      });

      var _delete = false;
      for (let [index, risque] of risques.entries()) {
        if (_delete) {
          risques.splice(index - 1, 1);
          _delete = false;
        }
        for (let [index2, risque2] of risques.entries()) {
          if (risque.libelle === risque2.libelle && index !== index2) {
            _delete = true;
          }
        }
      }

      risques.sort((a: any, b: any) => (a.libelle < b.libelle ? -1 : 1));

      return {
        ...item,
        risques: risques,
      };
    })
    .filter((item: any) => item !== null);

  uts.sort((a: any, b: any) => (a.libelle < b.libelle ? -1 : 1));
  return uts;
};

function reformatDatas(data: any) {
  console.log(data);
  let res = [];
  for (let ut of data.utilisateur.etablissements[0].donnees.unitesTravail) {
    let uniqRisques: any[] = [];
    var _risques = [...ut.risques];
    _risques.sort((a: any, b: any) => {
      if (a.libelle < b.libelle) {
        return -1;
      } else if (a.libelle > b.libelle) {
        return 1;
      } else {
        return a.description < b.description ? -1 : 1;
      }
    });
    for (let risque of _risques) {
      const _mesures = [...risque.mesures];
      _mesures.sort((a: any, b: any) => (a.libelle < b.libelle ? -1 : 1));
      let found = uniqRisques.find((r) => r.libelle === risque.libelle);
      if (found) {
        found.descriptions.push({
          ...risque,
          libelle: undefined,
          mesures: _mesures,
        });
      } else {
        uniqRisques.push({
          libelle: risque.libelle,
          descriptions: [{ ...risque, libelle: undefined, mesures: _mesures }],
          decription: undefined,
        });
      }
    }
    uniqRisques.sort((a: any, b: any) => (a.libelle < b.libelle ? -1 : 1));
    let newUt = { ...ut, risques: uniqRisques };
    res.push(newUt);
  }
  res.sort((a: any, b: any) => (a.libelle < b.libelle ? -1 : 1));
  return res;
}

export { getRisquesFromData, reformatDatas, deleteDuplicates };
