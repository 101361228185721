/**
 * This module exports a customized switch component, `AntSwitch`, which is a styled version of the `Switch` component
 * from the Material-UI library. The `AntSwitch` component applies custom styles to the `Switch` component to achieve a
 * specific visual appearance.
 *
 * @module AntSwitch
 * @see {@link https://mui.com/|Material-UI}
 */

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

/**
 * The `AntSwitch` component is a styled version of the `Switch` component from the Material-UI library.
 * It applies custom styles to the `Switch` component to achieve a specific visual appearance.
 *
 * @component
 * @example
 * import AntSwitch from './AntSwitch';
 *
 * function App() {
 *   const [checked, setChecked] = useState(false);
 *
 *   const handleChange = (event) => {
 *     setChecked(event.target.checked);
 *   };
 *
 *   return (
 *     <AntSwitch checked={checked} onChange={handleChange} />
 *   );
 * }
 */
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default AntSwitch;