import useAnalyticsEventTracker from "./analytics";

interface PropsType {
  freq: number;
  grav: number;
  onChange: (freq: number, grav: number) => void;
}

function FreqGrav({ freq, grav, onChange }: PropsType) {
  const gaEventTracker = useAnalyticsEventTracker("Mesures");

  const handleChangeFrequence = (event: any) => {
    onChange(parseInt(event.target.value), grav);
    gaEventTracker("Fréquence modification");
  };

  const handleChangeGravite = (event: any) => {
    onChange(freq, parseInt(event.target.value));
    gaEventTracker("Gravité modification");
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", marginLeft: "25px" }}>
      <label htmlFor="frequence">Fréquence:</label>
      <select
        id="frequence"
        defaultValue={freq}
        onChange={handleChangeFrequence}
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
      </select>

      <label htmlFor="gravite" style={{ marginLeft: "15px" }}>
        Gravité:
      </label>
      <select id="gravite" defaultValue={grav} onChange={handleChangeGravite}>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
      </select>
    </div>
  );
}

export default FreqGrav;
