import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import styles from "../../assets/css/documents";
import Consignes from "../../assets/images/securite.png";

function ARSecurite(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  etablissementAffichages: any;
  logo: any;
}) {
  const { etablissementAffichages } = props;
  return (
    <Document>
      <Page
        size="A4"
        orientation="landscape"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        <View style={styles.header}>
          {etablissementAffichages?.donnees.infosAffichage.logo !== "" ? (
            <Image
              style={styles.logo}
              src={etablissementAffichages?.donnees.infosAffichage.logo}
            />
          ) : null}
          <Text style={{ ...styles.title, color: props.textColor }}>
            SECURITE
          </Text>
          <Image style={styles.logo} src={props.logo} />
        </View>
        <View
          style={{ ...styles.body, backgroundColor: props.backgroundColor }}
        >
          <Image src={Consignes} fixed={true} />
        </View>
        <Text
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            textAlign: "right",
            color: props.textColor,
          }}
        >
          Tous droits réservés AT Consulting
        </Text>
      </Page>
    </Document>
  );
}

export default ARSecurite;
