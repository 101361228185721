import { useEffect, useState } from "react";
import "../assets/css/topbar.css";
import { useQuery } from "@apollo/client";

import IEtablissementsData from "../types/entreprises";

// import userPool
import userPool from "../utils/userPool";
import LoadingComponent from "./Loading";
import { GET_USER } from "../queries/user";
import { useMarqueBlanche } from "../context/MarqueBlanche";

const TopBar = () => {
  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      id: userPool.getCurrentUser()?.getUsername(),
    },
  });
  const [entreprises, setEntreprises] = useState<any>([]);
  const [selectedEntreprise, setSelectedEntreprise] =
    useState<IEtablissementsData>();
  const [mail, setMail] = useState("");

  const { topBarColor, topBarTextColor } = useMarqueBlanche();

  const setEntreprise = () => {
    if (data && data.utilisateur) {
      // data.utilisateurs.map((user: any) => {
      //   if (userPool.getCurrentUser()?.getUsername() === user.id) {
      //     setEntreprises(user.etablissements);
      //     setSelectedEntreprise(user.etablissements[0]);
      //     return user.etablissements;
      //   }
      //   return null;
      // });
      setEntreprises(data.utilisateur.etablissements);
    } else {
      console.log("No data yet");
    }
  };

  const getMailOfUser = () => {
    const currentUser = userPool.getCurrentUser();

    if (currentUser != null) {
      currentUser.getSession((err: any, session: any) => {
        if (err) {
          console.error("Erreur lors de la récupération de la session :", err);
          return;
        }

        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            console.error(
              "Erreur lors de la récupération des attributs :",
              err
            );
            return;
          }

          const emailAttribute = attributes?.find(
            (attribute) => attribute.getName() === "email"
          );

          if (emailAttribute) {
            const email = emailAttribute.getValue();
            setMail(email);
          } else {
            console.warn("L'utilisateur actuel n'a pas d'adresse email.");
          }
        });
      });
    }
  };

  useEffect(() => {
    setEntreprise();
    getMailOfUser();
  }, [data]);

  useEffect(() => {
    if (entreprises.length > 0) {
      setSelectedEntreprise(entreprises[0]);
      localStorage.setItem("idEntreprise", entreprises[0].id);
    }
  }, [entreprises]);

  if (loading) return <LoadingComponent type="topbar" />;
  if (error)
    return (
      <p>
        Error :(
        {error.message})
      </p>
    );

  const handleEntreprise = (e: IEtablissementsData) => {
    setSelectedEntreprise(e);
    // add id of entreprise to local storage
    localStorage.setItem("idEntreprise", e.id);
  };

  const handleLogout = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.signOut();
    }
    // rediriger vers la page accueil
    window.location.href = "/";
  };

  // get and parse url
  const url = window.location.href;
  const page = url.substring(url.lastIndexOf("/") + 1);
  const urlWithoutHttp = url.replace("http://", "");
  const urlWithoutHttps = urlWithoutHttp.replace("https://", "");
  const urlWithoutWww = urlWithoutHttps.replace("www.", "");
  const urlWithoutSlash = urlWithoutWww.substring(
    0,
    urlWithoutWww.lastIndexOf("/")
  );

  // if url starts with www.securiz then page is securiz
  const defineSite = () => {
    if (urlWithoutSlash === "securiz.fr" || urlWithoutSlash === "securiz.co") {
      return "Securiz - ";
    } else {
      return "";
    }
  };

  const definePage = () => {
    if (page === "dashboard") {
      return "Tableau de bord";
    } else if (page === "faq") {
      return "FAQ";
    } else if (page === "gcfaq") {
      return "FAQ";
    } else if (page === "metiers") {
      return "Gestion des métiers";
    } else if (page.startsWith("metier")) {
      return "Modifier un métier";
    } else if (page.startsWith("metier-view")) {
      return "Modifier un métier";
    } else if (page === "clients") {
      return "Gestion des clients";
    } else if (page.startsWith("client")) {
      return "Modifier un client";
    } else if (page.startsWith("clientgc")) {
      return "Modifier un client";
    } else if (page === "mes-clients") {
      return "Mes clients";
    } else if (page.startsWith("mon-client")) {
      return "Modifier un client";
    } else if (page === "demarches-generales") {
      return "Démarches générales";
    } else if (page === "groupes-et-unites-de-travail") {
      return "Groupes et unités de travail";
    } else if (page === "risques-evaluations-et-mesures-preventives") {
      return "Risques, évaluations et mesures préventives";
    } else if (page === "document-unique-et-plan-dactions") {
      return "Document unique et plan d'actions";
    } else if (page === "mes-affichages-reglementaires") {
      return "Mes affichages réglementaires";
    } else if (page === "news-reglementaires") {
      return "INRS News";
    } else if (page === "faq-utilisateur") {
      return "FAQ";
    } else if (page === "aide-et-support") {
      return "Aide & support";
    } else if (page === "mon-document-unique") {
      return "Mon document unique";
    } else if (page === "editer-mon-document-unique") {
      return "Editer mon document unique";
    } else if (page === "unites-de-travail") {
      return "Unités de travail";
    } else if (page === "selection-des-risques") {
      return "Sélection des risques";
    } else if (page === "description-des-risques") {
      return "Description des risques";
    } else if (page === "mesures-preventives") {
      return "Mesures préventives";
    } else if (page === "consulter-mon-document-unique") {
      return "Consulter mon document unique";
    } else if (page === "mon-plan-dactions") {
      return "Mon plan d'actions";
    } else if (page === "mes-affichages-reglementaires") {
      return "Mes affichages réglementaires";
    } else if (page === "selection-des-risques") {
      return "Sélection des risques";
    } else if (page === "mon-compte") {
      return "Mon compte";
    } else {
      return "Page inconnue";
    }
  };

  return (
    <div
      className="header"
      style={{ backgroundColor: topBarColor, color: topBarTextColor }}
    >
      <div style={{ color: topBarTextColor }} className="header-title">
        {defineSite()}
        {definePage()}
      </div>
      <div className="user-dropdown">
        <button
          className="user-dropdown-button"
          style={{ color: topBarTextColor }}
          onClick={() => {
            const dropdown = document.querySelector(
              ".entreprise-dropdown-content"
            );
            if (dropdown) {
              dropdown.classList.toggle("show");
            }
          }}
        >
          {selectedEntreprise?.raisonsociale}
          <i className="fas fa-angle-down"></i>
        </button>
        <div className="entreprise-dropdown-content">
          {entreprises &&
            entreprises.map(
              (entreprise: IEtablissementsData, index: number) => (
                <a
                  key={index}
                  href="#"
                  onClick={() => handleEntreprise(entreprise)}
                >
                  {entreprise.raisonsociale}
                </a>
              )
            )}
        </div>
      </div>
      <div className="user-dropdown">
        <button
          style={{ color: topBarTextColor }}
          className="user-dropdown-button"
          onClick={() => {
            const dropdown = document.querySelector(".user-dropdown-content");
            if (dropdown) {
              dropdown.classList.toggle("show");
            }
          }}
        >
          {mail}
          <i className="fas fa-angle-down"></i>
        </button>
        <div className="user-dropdown-content">
          <a href="/" onClick={handleLogout}>
            Déconnexion
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
