import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import userPool from "./userPool";
import LoadingComponent from "../components/Loading";

const PrivateRoute = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((error: any, session: any) => {
        if (error) {
          setIsAuthenticated(false);
          setLoading(false);
        } else {
          setIsAuthenticated(true);
          setLoading(false);
        }
      });
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
