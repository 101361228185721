import { Modal } from "@mui/material";
import PlanAction from "../../components/PlanAction";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";
import { useState, useEffect, useRef } from "react";

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  MUTE_PLAN_ACTION_PREPARE,
  CREATE_DOCUMENT_UNIQUE,
  LINKS_PLANACTION_MUTATION,
  GET_PLAN_ACTION,
  GET_DOCUMENT_UNIQUE_VALID,
  PLAN_ACTION_UPDATE_DATE,
} from "../../queries/planAction";
import { USER_ID_LS_KEY } from "../../queries/uniteTravail";
import muteActions from "../../utils/muteActions";
import LoadingComponent from "../../components/Loading";
import ErrorComponent from "../../components/ErrorComponent";
import client from "../../utils/http-common";

const PlanActions = () => {
  const [noValid, setNoValid] = useState(false);

  const save = useRef<any>(null);

  const [open, setOpen] = useState(false);

  const [createDocumentUniqueMut, mutationCreateDocUnique] = useMutation(
    CREATE_DOCUMENT_UNIQUE
  );
  const [saving, setSaving] = useState(false);
  const [linkPlanActionMut, mutationLinkPlanAction] = useMutation(
    LINKS_PLANACTION_MUTATION,
    {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    }
  );

  const { data, loading, error } = useQuery(MUTE_PLAN_ACTION_PREPARE, {
    fetchPolicy: "no-cache",
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
  });

  const [getPlanAction, planActionRes] = useLazyQuery(GET_PLAN_ACTION);

  const docUniqueRes = useQuery(GET_DOCUMENT_UNIQUE_VALID, {
    variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
    errorPolicy: "ignore",
  });
  useEffect(() => {
    if (data) {
      muteActions(data, createDocumentUniqueMut, linkPlanActionMut).then(
        async () => {
          await getPlanAction({
            variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
          });
        }
      );
    }
  }, [data, createDocumentUniqueMut, linkPlanActionMut, getPlanAction]);

  useEffect(() => {
    if (
      docUniqueRes.data &&
      docUniqueRes.data.utilisateur.etablissements[0].donnees.documentUnique
        .isvalid == true
    ) {
      setNoValid(true);
    }
    if (
      docUniqueRes.data &&
      docUniqueRes.data?.utilisateur.etablissements[0].donnees.documentUnique
        .planaction == null
    ) {
      client.mutate({
        mutation: PLAN_ACTION_UPDATE_DATE,
        variables: {
          etablissementID: localStorage.getItem("idEntreprise"),
          dateedition: new Date().toISOString(),
        },
      });
    }
  }, [planActionRes, docUniqueRes]);

  if (loading) return <LoadingComponent />;
  if (error) {
    return <ErrorComponent error={error} />;
  }

  if (mutationCreateDocUnique.loading || mutationLinkPlanAction.loading)
    return <LoadingComponent />;
  if (mutationCreateDocUnique.error || mutationLinkPlanAction.error)
    return <ErrorComponent error={mutationCreateDocUnique.error || mutationLinkPlanAction.error} />;
  if (planActionRes.loading) {
    return <LoadingComponent />;
  }
  if (planActionRes.error) return <ErrorComponent error={planActionRes.error} />;
  if (!planActionRes.data) {
    return <LoadingComponent />;
  }
  if (saving) return <LoadingComponent />;

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setSaving(true);
          save.current.save().then(() => {
            planActionRes.refetch().then((e) => {
              setSaving(false);
            });
          });
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="modal-style"
          style={{
            width: "90%",
          }}
        >
          <h2 id="modal-modal-title">Plan d'action</h2>
          <p id="modal-modal-description">
            <PlanAction
              etablissementID={
                planActionRes.data.utilisateur.etablissements[0].id
              }
              actions={
                planActionRes.data.utilisateur.etablissements[0].donnees
                  .documentUnique.planaction.actions
              }
              data={data}
              save={save}
            />
          </p>
        </div>
      </Modal>

      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Mon Plan d'Actions</h1>
          <div className="pageLine"></div>
          <div
            className="content"
            style={{
              marginTop: "50px",
            }}
          >
            {noValid ? (
              <button
                style={{
                  width: "30%",
                  height: "50px",
                  backgroundColor: "#43425d",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#5e5c7d";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#43425d";
                }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Consulter mon plan d'actions
              </button>
            ) : (
              <p>
                Vous devez valider votre document unique pour accéder au plan
                d'actions
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanActions;
