import iconClient from "../assets/icons/client.png";
import "../assets/css/card.css";

interface BoxKpiProps {
  title: string;
  value: string;
}

const BoxKpi = (props: BoxKpiProps) => {
  const defineIcon = () => {
    switch (props.title) {
      case "Clients Actifs":
        return (
          <img
            src={iconClient}
            style={{ width: "35px", height: "35px", marginRight: "10px" }}
            alt="Client icon"
          />
        );
      default:
        return (
          <img
            src={iconClient}
            style={{ width: "35px", height: "35px", marginRight: "10px" }}
            alt="Client icon"
          />
        );
    }
  };

  return (
    <div className="box-kpi">
      <div className="box-left">
        {defineIcon()}
        <h4>{props.title}</h4>
      </div>
      <h2>{props.value}</h2>
    </div>
  );
};

export default BoxKpi;
