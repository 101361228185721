import { Document, Page, Image } from "@react-pdf/renderer";

import styles from "../../assets/css/documents";

import Marianne1 from "../../assets/images/marianne1.jpg";

function ARMarianne(props: {
  contourColor: any;
  textColor: any;
  backgroundColor: any;
  radius: number;
  logo: any;
}) {
  return (
    <Document>
      <Page
        size="A4"
        style={{ ...styles.page, backgroundColor: props.contourColor }}
      >
        {/* <View style={styles.header}>
          <Image style={styles.logo} src={Image100} />
          <Text style={{ ...styles.title, color: props.textColor }}>
            {entrepriseName}
          </Text>
          <Image style={styles.logo} src={LogoSecuriz} />
        </View> */}

        <Image
          style={{
            width: "100%",
            height: "auto",
            alignSelf: "center",
            backgroundColor: props.backgroundColor,
          }}
          src={Marianne1}
        />
      </Page>
    </Document>
  );
}

export default ARMarianne;
