type Mesure = {
  ponderation: number;
  id: string;
};

type Description = {
  mesures: Mesure[];
  gravite: number;
  frequence: number;
  id: string;
};
export  default  class RisqueCalcul {
  public static getPriorite(description: Description): number {
    const g = description.gravite;
    const f = description.frequence;
    const c: number = g * f * RisqueCalcul.getMaitrise(description);
    const M = RisqueCalcul.getMaitrise(description);
    let priorite: number = 0;

    if (M == null) {
      priorite = 1;
    }

    if (M !== 1) {
      if (c === 27) {
        priorite = 1;
      } else if (c >= 12 && c <= 27) {
        priorite = 2;
      } else if (c >= 5 && c < 12) {
        priorite = 3;
      } else if (c < 5) {
        priorite = 4;
      }
    } else {
      priorite = 4;
    }

    return priorite;
  }

  public static getTotalPonderations(description: Description): number {
    const totalPonderations = description.mesures.reduce(
      (sum: number, mesure: Mesure) => sum + mesure.ponderation,
      0
    );
    return totalPonderations;
  }

  public static getMaitrise(description: Description): number {
    const P = RisqueCalcul.getTotalPonderations(description);
    let M: number = 3;
    if (P >= 50 && P < 80) {
      M = 2;
    } else if (P >= 80) {
      M = 1;
    }
    return M;
  }

  public static getCriticite(description: Description): number {
    const g = description.gravite;
    const f = description.frequence;
    const c: number = g * f * RisqueCalcul.getMaitrise(description);

    return c;
  }

  public static getPriorites(data: any[]) {
    let priorites = {} as { [key: string]: number };
  
    for (let ut of data) {
      for (let risque of ut.risques) {
        for (let desc of risque.descriptions) {
          // if (desc.id === "24086c6e-edcb-11ed-a811-8cc6812c99cb")
          priorites[ut.id + desc.id] = RisqueCalcul.getPriorite(desc);
        }
      }
    }
  
    return priorites;
  }

  public static  getEcheance(
    action: any,
    dateDouverture: Date,
    priorites?: { [key: string]: number }
  ) {
    const PRIO_MONTH_TO_ADD = [1, 3, 6, 12];
    const delai = new Date(dateDouverture);
    if (!priorites) {
      return delai;
    }
    const prio: number =
      priorites[action.uniteTravail.id + action.risque.id] ?? 4;
    const monthToAdd = PRIO_MONTH_TO_ADD[prio - 1];
    delai.setMonth(delai.getMonth() + monthToAdd);
    return delai;
  }
}
