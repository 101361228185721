import { Card } from "@mui/material";
import fetch from "node-fetch";
import { useEffect, useState } from "react";
import OwlCarousel, { OwlCarouselProps } from "react-owl-carousel";
import "../../assets/css/news.css";
import API_URL from "../../utils/api";


type News = {
  title: string;
  description: string;
  link: string;
  pubDate: string;
  guid: string;
};
const options: Omit<OwlCarouselProps, "children"> = {
  loop: true,

  center: true,
  nav: false,
  dots: false,
  margin: 80,
  autoplayHoverPause: false,

  autoplay: true,
  autoplayTimeout: 5000, // délai en millisecondes
  autoplaySpeed: 4000,
  smartSpeed: 4000,
  responsive: {
    0: {
      items: 1,
    },
    502: {
      items: 1,
    },
    768: {
      items: 1,
    },
    1024: {
      items: 2,
    },
    1200: {
      items: 2,
    },
  },
};
const INRSNews = () => {
  const [news, setNews] = useState([] as News[]);

  useEffect(() => {
    let url = `${API_URL}/rss`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/xml",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res: { text: () => any }) => res.text())
      .then((xmlString: string) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlString, "text/xml");
        const news = xml.querySelectorAll("item");
        const newsArray:
          | ((prevState: never[]) => never[])
          | {
              title: string;
              description: string;
              link: string;
              pubDate: string;
              guid: string;
            }[] = [];
        news.forEach((item: any) => {
          newsArray.push({
            title: item.querySelector("title").innerHTML,
            description: item.querySelector("description").innerHTML,
            link: item.querySelector("link").innerHTML,
            pubDate: item.querySelector("pubDate").innerHTML,
            guid: item.querySelector("guid").innerHTML,
          });
        });
        console.log(newsArray);
        setNews(newsArray);
      })
      .catch((err: string) => console.error("error:" + err));
  }, []);

  return (
    <OwlCarousel {...options}>
      {news.slice(6).map((item: any) => {
        return (
          <Card className="news-item" key={item} style={{width:'auto', padding:30, height:250}}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <p style={{ fontSize: "0.8rem", color: "grey" }}>{item.pubDate}</p>
            <a href={item.link}>{item.link}</a>
          </Card>
        );
      })}
    </OwlCarousel>
  );
};

export default INRSNews;
