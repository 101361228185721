import { gql } from "@apollo/client";

const GET_AFFICHAGES = gql`
  query ($id: String!) {
    utilisateur(id: $id) {
      etablissements {
        id
        donnees {
          infosAffichage {
            adresseinspection
            adressemedecine
            convention
            derogation
            couleurs {
              background
              borders
              text
              radius
            }
            entrepriseID
            horairedimanchedebut
            horairedimanchefin
            horairejeudidebut
            horairejeudifin
            horairelundidebut
            horairelundifin
            horairemardidebut
            horairemardifin
            horairemercredidebut
            horairemercredifin
            horairesamedidebut
            horairesamedifin
            horairevendredidebut
            horairevendredifin
            joursrepos
            logo
            nominspection
            numeroinspection
            numeromedecine
            updatedAt
          }
        }
      }
    }
  }
`;

// mutations :
const UPDATE_AFFICHAGE = gql`
  mutation UpdateAffichage(
    $adresseinspection: String
    $adressemedecine: String
    $convention: String
    $derogation: String
    $couleurs: CouleursInput
    $entrepriseID: String!
    $horairedimanchedebut: String
    $horairedimanchefin: String
    $horairejeudidebut: String
    $horairejeudifin: String
    $horairelundidebut: String
    $horairelundifin: String
    $horairemardidebut: String
    $horairemardifin: String
    $horairemercredidebut: String
    $horairemercredifin: String
    $horairesamedidebut: String
    $horairesamedifin: String
    $horairevendredidebut: String
    $horairevendredifin: String
    $joursrepos: String
    $logo: String
    $nominspection: String
    $numeroinspection: String
    $numeromedecine: String
  ) {
    infosAffichage {
      update(
        adresseinspection: $adresseinspection
        adressemedecine: $adressemedecine
        convention: $convention
        derogation: $derogation
        couleurs: $couleurs
        entrepriseID: $entrepriseID
        horairedimanchedebut: $horairedimanchedebut
        horairedimanchefin: $horairedimanchefin
        horairejeudidebut: $horairejeudidebut
        horairejeudifin: $horairejeudifin
        horairelundidebut: $horairelundidebut
        horairelundifin: $horairelundifin
        horairemardidebut: $horairemardidebut
        horairemardifin: $horairemardifin
        horairemercredidebut: $horairemercredidebut
        horairemercredifin: $horairemercredifin
        horairesamedidebut: $horairesamedidebut
        horairesamedifin: $horairesamedifin
        horairevendredidebut: $horairevendredidebut
        horairevendredifin: $horairevendredifin
        joursrepos: $joursrepos
        logo: $logo
        nominspection: $nominspection
        numeroinspection: $numeroinspection
        numeromedecine: $numeromedecine
      ) {
        id
      }
    }
  }
`;

const ADD_AFFICHAGE = gql`
  mutation AddAffichage(
    $entrepriseID: String!
    $adresseinspection: String!
    $adressemedecine: String!
    $convention: String!
    $derogation: String!
    $horairedimanchedebut: String!
    $horairedimanchefin: String!
    $horairejeudidebut: String!
    $horairejeudifin: String!
    $horairelundidebut: String!
    $horairelundifin: String!
    $horairemardidebut: String!
    $horairemardifin: String!
    $horairemercredidebut: String!
    $horairemercredifin: String!
    $horairesamedidebut: String!
    $horairesamedifin: String!
    $horairevendredidebut: String!
    $horairevendredifin: String!
    $joursrepos: String!
    $logo: String!
    $nominspection: String!
    $numeroinspection: String!
    $numeromedecine: String!
    $couleurs: CouleursInput
  ) {
    infosAffichage {
      add(
        entrepriseID: $entrepriseID
        adresseinspection: $adresseinspection
        adressemedecine: $adressemedecine
        convention: $convention
        derogation: $derogation
        horairedimanchedebut: $horairedimanchedebut
        horairedimanchefin: $horairedimanchefin
        horairejeudidebut: $horairejeudidebut
        horairejeudifin: $horairejeudifin
        horairelundidebut: $horairelundidebut
        horairelundifin: $horairelundifin
        horairemardidebut: $horairemardidebut
        horairemardifin: $horairemardifin
        horairemercredidebut: $horairemercredidebut
        horairemercredifin: $horairemercredifin
        horairesamedidebut: $horairesamedidebut
        horairesamedifin: $horairesamedifin
        horairevendredidebut: $horairevendredidebut
        horairevendredifin: $horairevendredifin
        joursrepos: $joursrepos
        logo: $logo
        nominspection: $nominspection
        numeroinspection: $numeroinspection
        numeromedecine: $numeromedecine
        couleurs: $couleurs
      ) {
        id
      }
    }
  }
`;

const UPDATE_LOGO = gql`
  mutation UpdateAffichage($entrepriseID: String!, $logo: String) {
    infosAffichage {
      update(entrepriseID: $entrepriseID, logo: $logo) {
        id
      }
    }
  }
`;

export { GET_AFFICHAGES, UPDATE_AFFICHAGE, ADD_AFFICHAGE, UPDATE_LOGO };
