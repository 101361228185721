import TopBar from "../../../components/TopBar";
import Navbar from "../../../components/navbar";
import "../../../assets/css/docu.css";

import { useMutation } from "@apollo/client";
import {
  LINKS_ETABLISSMENT_MUTATION,
  USER_ID_LS_KEY,
} from "../../../queries/uniteTravail";
// import IUniteTravail from "../../../types/uniteTravail";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LoadingComponent from "../../../components/Loading";
import { UPDATE_DOCUMENT_UNIQUE } from "../../../queries/documentUnique";
import ErrorComponent from "../../../components/ErrorComponent";
import API_URL from "../../../utils/api";

// const getUnitesTravailFromData = (data: any) => {
//   const user = data?.utilisateur.etablissements[0].donnees.unitesTravail;

//   const uts = data?.utilisateur.etablissements[0].metier.unitesTravail.map(
//     (item: IUniteTravail) => {
//       const userUt = user.find(
//         (userItem: IUniteTravail) => userItem.id === item.id
//       );
//       return {
//         ...item,
//         checked: userUt !== undefined,
//         nbsalaries: userUt?.nbsalaries || 0,
//       };
//     }
//   );

//   return uts;
// };

// const getMutationParams = (uts: any, data: any) => {
//   const oldUts = getUnitesTravailFromData(data);

//   const utsToAdd = uts.filter(
//     (item: any) =>
//       item.checked &&
//       (!oldUts.find((oldItem: any) => oldItem.id === item.id).checked ||
//         oldUts.find((oldItem: any) => oldItem.id === item.id).nbsalaries !==
//           item.nbsalaries)
//   );
//   const utsToRemove = uts.filter(
//     (item: any) =>
//       !item.checked &&
//       oldUts.find((oldItem: any) => oldItem.id === item.id).checked
//   );

//   return {
//     linksAdd: utsToAdd.map((item: any) => ({
//       uniteTravailID: item.id,
//       nbsalaries: Number(item.nbsalaries || "0"),
//     })),
//     linksDelete: utsToRemove.map((item: any) => ({
//       uniteTravailID: item.id,
//     })),
//   };
// };

const UniteTravail = () => {
  // const { data, loading, error } = useQuery(LIST_UNITE_TRAVAIL_USER, {
  //   variables: { id: localStorage.getItem(USER_ID_LS_KEY) },
  // });

  const oldModel = useRef([] as any);
  const model = useRef([] as any);
  const [loading, setLoading] = useState(true);
  const [pageToSwitch, setPageToSwitch] = useState("");

  useEffect(() => {
    fetch(
      `${API_URL}/rest/user/model/${localStorage.getItem(
        USER_ID_LS_KEY
      )}/${localStorage.getItem("idEntreprise")}`
    ).then((res) => {
      res.json().then((data) => {
        model.current = data;
        oldModel.current = data;
        setLoading(false);
      });
    });
  }, []);

  const mutationQueue = useRef(undefined as any);
  const [uniteTravailMutation, mutation] = useMutation(
    LINKS_ETABLISSMENT_MUTATION
  );
  const [mutateDocUnique, docUniqueMutation] = useMutation(
    UPDATE_DOCUMENT_UNIQUE
  );
  const mutate = (data: any) => {
    if (mutation.loading) {
      mutationQueue.current = {
        linksAdd: [
          ...(mutationQueue.current?.linksAdd.filter(
            (item: any) =>
              !data.linksDelete.find(
                (item2: any) => item2.uniteTravailID === item.uniteTravailID
              ) &&
              !data.linksAdd.find(
                (item2: any) => item2.uniteTravailID === item.uniteTravailID
              )
          ) ?? []),
          ...data.linksAdd,
        ],
        linksDelete: [
          ...(mutationQueue.current?.linksDelete.filter(
            (item: any) =>
              !data.linksAdd.find(
                (item2: any) => item2.uniteTravailID === item.uniteTravailID
              ) &&
              !data.linksDelete.find(
                (item2: any) => item2.uniteTravailID === item.uniteTravailID
              )
          ) ?? []),
          ...data.linksDelete,
        ],
      };
    } else {
      uniteTravailMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: data.linksAdd,
          linksDelete: data.linksDelete,
        },
      });
    }
  };

  useEffect(() => {
    if (!mutation.loading && mutationQueue.current !== undefined) {
      let toMutate = mutationQueue.current;
      mutationQueue.current = undefined;
      uniteTravailMutation({
        variables: {
          id: localStorage.getItem("idEntreprise"),
          linksAdd: toMutate.linksAdd,
          linksDelete: toMutate.linksDelete,
        },
      }).then(async () => {
        if (pageToSwitch !== "" && mutationQueue.current === undefined) {
          window.location.href = pageToSwitch;
        }
      });
    } else {
      if (pageToSwitch !== "") {
        window.location.href = pageToSwitch;
      }
    }
  }, [mutation]);

  if (mutation.loading && pageToSwitch !== "") return <LoadingComponent />;
  if (mutation.error) {
    return <p>Erreur...</p>;
  }

  //if (uts === null) {
  //  setUts(getUnitesTravailFromData(data));
  //  return <LoadingComponent />;
  //}

  if (docUniqueMutation.loading) return <LoadingComponent />;
  if (docUniqueMutation.error) return <ErrorComponent error={docUniqueMutation.error} />;
  if (loading) return <LoadingComponent />;
  return (
    <>
      <div className="dashboard">
        <Navbar type="user" />
        <div className="dashboard-content">
          <TopBar />
          <h1>Unités de travail</h1>
          <div className="pageLine"></div>
          <div className="affichages">
            <p>
              Voici les unités de travail les plus fréquemment utilisées dans
              votre activité.
            </p>
            <p>
              Cochez la case si l'unité de travail existe dans votre entreprise.
            </p>
            <p>
              Si vous estimez qu'il manque une unité de travail, n'hésitez pas,
              posez-nous la question.
            </p>
            {/* <button
              onClick={() => {
                setUts(
                  uts.map((item: any) => {
                      return {
                        ...item,
                        checked: !item.checked,
                        nbsalaries: 1,
                      };
                    return item;
                  })
                );
              }}
            >Selectionner tout</button> */}
            <TableContainer component={Paper} style={{ marginTop: "50px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Unités de travail</TableCell>
                    <TableCell align="right">
                      Nombre de salariés concernés
                    </TableCell>
                    {/* <TableCell align="right">Date de mise à jour</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {model.current.map((utType: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Grid container>
                          <Grid item xs={3}>
                            <Checkbox
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              value={utType.id}
                              defaultChecked={utType.checked}
                              onChange={(event) => {
                                utType.checked = event.target.checked;
                                mutate({
                                  linksAdd: event.target.checked
                                    ? [
                                        {
                                          uniteTravailID: utType.id,
                                          nbsalaries: utType.nbsalarie,
                                        },
                                      ]
                                    : [],
                                  linksDelete: !event.target.checked
                                    ? [{ uniteTravailID: utType.id }]
                                    : [],
                                });
                              }}
                              //   checked={state.checked.includes(utType.id)}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              variant="body1"
                              style={{ marginTop: "10px" }}
                            >
                              {utType.libelle}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          variant="outlined"
                          name={utType.id}
                          inputProps={{
                            min: 0,
                            style: {
                              padding: 15,
                            },
                          }}
                          onChange={(e) => {
                            utType.nbsalarie = e.target.value;
                            if (utType.checked)
                              mutate({
                                linksAdd: [
                                  {
                                    uniteTravailID: utType.id,
                                    nbsalaries: Number(e.target.value),
                                  },
                                ],
                                linksDelete: [],
                              });
                          }}
                          type="number"
                          //   value={state.values[utType.id] || 0}
                          defaultValue={utType.nbsalarie || 0}
                        />
                      </TableCell>
                      {/* <TableCell align="right">
                        {formatDate(utType.updatedAt)}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="button-style-doc"
                onClick={async () => {
                  // uniteTravailMutation({
                  //   variables: {
                  //     id: data.utilisateur.etablissements[0].id,
                  //     ...getMutationParams(uts, data),
                  //   },
                  // }).then(async () => {
                  await mutateDocUnique({
                    variables: {
                      etablissementID: localStorage.getItem("idEntreprise"),
                      isvalid: false,
                      avancement: 0,
                    },
                  });
                  setPageToSwitch("/editer-mon-document-unique");
                  //window.location.href = "/editer-mon-document-unique";
                  // });
                }}
              >
                Précédent
              </button>
              <button
                className="button-style-doc"
                onClick={() => {
                  // check if TextField have a value 1 min
                  if (
                    model.current.filter(
                      (item: any) => item.checked && (item.nbsalarie ?? 0) === 0
                    ).length > 0
                  ) {
                    alert(
                      "Veuillez renseigner le nombre de salariés concernés pour chaque unité de travail sélectionnée."
                    );
                    return;
                  }
                  setPageToSwitch("/selection-des-risques");
                }}
              >
                Suivant
              </button>
              {mutation.loading && "sauvegarde en cours..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UniteTravail;
