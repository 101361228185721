/**
 * Represents an error component in a React application.
 * This component is responsible for rendering an error page
 * with a message and options for the user to reload the page
 * or go back to the home page.
 *
 * @component
 * @example
 * return (
 *   <ErrorComponent />
 * )
 */
import "../assets/css/error.css";


type ErrorComponentProps = {
error?: any;
path?: string;
  
}
/**
 * Functional component that renders the error page.
 *
 * @returns {JSX.Element} The JSX element representing the error page.
 */
const ErrorComponent = ({error, path}: ErrorComponentProps) => {
  return (
    <div className="errorPage">
      <h1>Reconnexion nécessaire</h1>
      {/* <h2>Page erronée</h2> */}
      <p>
       Le temps de connexion a expiré. Veuillez vous reconnecter.
      </p>
      {/* <p>{error.toString()}</p>
      <p>{path?.toString()}</p> */}
      {/* reload window and back home  */}
      <a href="/">Retour à l'accueil</a>
      {/* <div
        onClick={() => {
          window.location.reload();
        }}
        style={{ cursor: "pointer" }}
      >
        <i className="fas fa-sync-alt"></i>
      </div> */}
    </div>
  );
};

export default ErrorComponent;
