import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../assets/css/contact.css";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import API_URL from "../utils/api";

const Contact = () => {
  // random calculation for check if is human
  const random = Math.floor(Math.random() * 10) + 1;
  const random2 = Math.floor(Math.random() * 10) + 1;
  const result = random + random2;

  const [finished, setFinished] = React.useState(false);

  const printConfirmation = () => {
    setFinished(true);
  };

 

  const handleHuman = (value: any) => {
    if (value.human === result) {
      handleSubmit(value);
    }
  };

  const handleSubmit = async (value: any) => {
    fetch(`${API_URL}/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nom: value.nom,
        prenom: value.prenom,
        entreprise: value.entreprise,
        email: value.email,
        message: value.message,
      }),
    }).then((res) => {
      res.json().then((data) => {
        if (data.success) {
          printConfirmation();
        }
      });
    });
  };

  const validationSchema = Yup.object({
    nom: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    message: Yup.string().min(2, "Too Short!").max(500, "Too Long!"),
  });

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>
        Contactez-nous via le formulaire ci-dessous.
      </h3>
      <div className="contactSection">
        <div className="contactInfo">
          <h3>NOS LOCAUX</h3>
          <p>35 rue Gambetta</p>
          <p>31330 Grenade</p>
          <p>07 88 29 44 59</p>
          <p>05 36 09 07 00</p>
        </div>
        <div className="contact">
         {!finished ? <Formik
            initialValues={{
              nom: "",
              prenom: "",
              email: "",
              message: "",
              human: undefined,
            }}
            validationSchema={validationSchema}
            onSubmit={handleHuman}
          >
            {({ values, errors }) => (
              <Form>
                <div className="contactFields1">
                  <div className="contactItem">
                    <label htmlFor="nom">Votre nom</label>
                    <Field name="nom" type="text" />
                    <ErrorMessage name="nom" />
                  </div>
                  <div className="contactItem">
                    <label htmlFor="prenom">Votre prénom</label>
                    <Field name="prenom" type="text" />
                    <ErrorMessage name="prenom" />
                  </div>
                </div>
                <div className="contactFields2">
                  <div className="contactItem">
                    <label htmlFor="entreprise">Votre entreprise</label>
                    <Field name="entreprise" type="text" />
                    <ErrorMessage name="entreprise" />
                  </div>
                  <div className="contactItem">
                    <label htmlFor="email">Votre adresse courriel</label>
                    <Field name="email" type="text" />
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div className="contactItem">
                  <label htmlFor="message">Votre message</label>
                  <Field
                    name="message"
                    as="textarea"
                    style={{ width: "100%" }}
                  />
                  <ErrorMessage name="message" />
                </div>
                <div className="contactItem">
                  <label htmlFor="human">
                    Combien font {random} + {random2} ?
                  </label>
                  <Field name="human" type="number" />
                  <ErrorMessage name="human" />
                  <Button type="submit">ENVOYER</Button>
                </div>
              </Form>
            )}
          </Formik>:

            <Alert
              style={{ marginBottom: "10px" , marginLeft: "10px"}}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              Vos coordonnées viennent d'etre transmises à notre équipe.
            </Alert>}

        </div>
      </div>
    </div>
  );
};

export default Contact;
