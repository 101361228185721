import { useState } from "react";
import TopBar from "../../components/TopBar";
import Navbar from "../../components/navbar";
import { useNavigate } from "react-router-dom";
import "../../assets/css/client.css";
import Card from "../../components/card";

import { Button, Modal, TextField } from "@mui/material";
import PlanAction from "../../components/PlanAction";
import DocU from "../../components/docU";

//import query
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_USER,
  GET_USER,
  UPDATE_ETABLISSEMENT,
  UPDATE_USER,
} from "../../queries/user";
import LoadingComponent from "../../components/Loading";
import { useLocation } from "react-router-dom";
import { GET_PLAN_ACTION } from "../../queries/planAction";
import client from "../../utils/http-common";
import ErrorComponent from "../../components/ErrorComponent";
import { GET_FULL_DOCUMENT_UNIQUE_MODEL } from "../../queries/documentUnique";
import { format } from "date-fns";
import API_URL from "../../utils/api";
import { computeDate } from "./expirationDateUtil";

function formatDate(date: string) {
  if (date === null) return "Jamais";
  try {
    const dateObject = new Date(date);

    const formattedDate = dateObject
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .join(" / ");
    return formattedDate;
  } catch {
    return "Jamais";
  }
}

const Client = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const userID = queryParams.get("userid");
  const entrepriseID = queryParams.get("id");
  const [raisonSociale, setRaisonSociale] = useState("");

  const userQuery = useQuery(GET_USER, {
    variables: {
      id: userID,
    },
    onCompleted(data) {
      if (data.utilisateur.etablissements.length !== 0)
        setRaisonSociale(
          (
            data.utilisateur.etablissements.find(
              (e: any) => e.id === entrepriseID
            ) ?? data.utilisateur.etablissements[0]
          ).raisonsociale
        );
      if (MailClient === "") handleClients();
    },
  });
  const planActionRes = useQuery(GET_PLAN_ACTION, {
    variables: { id: userID },
  });

  const { data, loading, error } = useQuery(GET_FULL_DOCUMENT_UNIQUE_MODEL, {
    variables: {
      id: userID,
    },
  });

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  const [MailClient, setMailClient] = useState("");
  const [joursAbonnement, setJoursAbonnement] = useState(0);
  const [mutateUser, userMutation] = useMutation(UPDATE_USER);

  const addJoursAbonnement = async (jours: number) => {
    const date = computeDate({
      days: jours,
      previousDate: userQuery.data.utilisateur.dateexpiration,
    });
    await mutateUser({
      variables: {
        id: userQuery.data.utilisateur.id,
        dateexpiration: date,
      },
    });
    // Retournez la nouvelle date d'expiration
    return new Date(date);
  };

  const handleClient = (attr: any) => {
    const mail = attr.find((a: any) => a.Name === "email")?.Value;
    if (mail) setMailClient(mail);
  };
  const handleClients = async () => {
    const response = await fetch(`${API_URL}/admin/users/${userID}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const data = await response.json();
    handleClient(data.UserAttributes);
  };

  if (userQuery.loading) return <LoadingComponent />;
  if (userQuery.error) return <ErrorComponent error={userQuery.error} />;
  if (planActionRes.loading) return <LoadingComponent />;
  if (planActionRes.error)
    return <ErrorComponent error={planActionRes.error} />;
  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent error={error} />;

  console.log("userQuery", userQuery.data);
  const entreprise =
    userQuery.data.utilisateur.etablissements.length !== 0
      ? userQuery.data.utilisateur.etablissements.find(
          (etablissement: any) => etablissement.id === entrepriseID
        ) || userQuery.data.utilisateur.etablissements[0]
      : undefined;

  return (
    <>
      <Modal
        open={open3}
        onClose={() => setOpen3(false)}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal-content">
          <h2>Modifier la raison sociale</h2>
          <TextField
            label="Raison sociale"
            value={raisonSociale}
            onChange={(e) => {
              setRaisonSociale(e.target.value);
              // mutation update raison sociale
            }}
            style={{ width: "80%" }}
          />
          <div className="modal-buttons">
            <Button
              variant="contained"
              onClick={() => setOpen3(false)}
              sx={{ margin: "10px" }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                client
                  .mutate({
                    mutation: UPDATE_ETABLISSEMENT,
                    variables: {
                      id: entreprise.id,
                      raisonsociale: raisonSociale,
                      userId: userQuery.data.utilisateur.id,
                    },
                  })
                  .then(() => {
                    userQuery.refetch();
                    setOpen3(false);
                  });
              }}
              sx={{ margin: "10px" }}
            >
              Modifier
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DocU userId={userID || ""} />
      </Modal>
      <Modal open={open} onClose={handleClose}>
        <div className="modal-style">
          <h2 id="modal-modal-title">Plan d'action</h2>
          <p id="modal-modal-description">
            <PlanAction
              etablissementID={entreprise.id}
              actions={
                planActionRes.data.utilisateur.etablissements.find(
                  ({ id }: any) => entreprise.id === id
                ).donnees?.documentUnique?.planaction?.actions || []
              }
              data={data}
            />
          </p>
        </div>
      </Modal>
      <div className="dashboard">
        <Navbar type="admin" />
        <div className="dashboard-content">
          <TopBar />
          <h1>{entreprise?.dirigeant ?? "Pas d'entreprise"}</h1>
          <div className="pageLine"></div>
          {MailClient && (
            <div className="client-content">
              <div className="client-left">
                <div className="client-img">
                  {entreprise?.donnees?.infosAffichage?.logo ? (
                    <img
                      src={entreprise?.donnees?.infosAffichage?.logo}
                      alt="client"
                    />
                  ) : (
                    <img
                      src="https://pbs.twimg.com/profile_images/578844000267816960/6cj6d4oZ_400x400.png"
                      alt="client"
                    />
                  )}
                </div>
                <div className="client-info">
                  <div className="client-info-name">
                    <h3>{entreprise?.raisonsociale ?? "Pas d'entreprise"}</h3>
                    <li
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpen3(true)}
                    ></li>
                  </div>
                  <h5>
                    {MailClient} / {entreprise.telephone}
                  </h5>
                  <p>
                    Derniere connexion :{" "}
                    {formatDate(userQuery.data.utilisateur.lastlogin)}
                  </p>
                </div>
              </div>
              <div className="client-right">
                <button
                  className="btn btn-danger"
                  onClick={async () => {
                    if (
                      !window.confirm(
                        "Êtes-vous sûr de vouloir supprimer ce compte ?"
                      )
                    ) {
                      return;
                    }
                    client.mutate({
                      mutation: DELETE_USER,
                      variables: {
                        id: userQuery.data.utilisateur.id,
                      },
                      errorPolicy: "ignore",
                    });
                    navigate("/clients");
                  }}
                >
                  Supprimer le compte
                </button>
                {userQuery?.data?.utilisateur?.gcID == null && (
                  <button
                    className="btn btn-primary"
                    onClick={async () => {
                      if (
                        !window.confirm(
                          "Êtes-vous sûr de vouloir faire de ce client un Grand Compte ?"
                        )
                      )
                        {return;}

                      await client.mutate({
                        mutation: UPDATE_USER,
                        variables: {
                          id: userQuery.data.utilisateur.id,
                          role: "ROLE_GC",
                          gcID: userQuery.data.utilisateur.id,
                        },
                      });
                      await fetch(`${API_URL}/admin/users/${userID}`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify({
                          isGC: true,
                          enabled: true,
                        }),
                      });
                    }}
                  >
                    Définir Grand Compte
                  </button>
                )}
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Ajouter des jours sur l'abonnement
                </p>
                <input
                  type="number"
                  placeholder="Ex : 360 (pour 1 an)"
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    padding: "5px",
                  }}
                  value={joursAbonnement}
                  onChange={(e) => setJoursAbonnement(parseInt(e.target.value))}
                />
                {joursAbonnement > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    <p>
                      Date cible :{" "}
                      {format(
                        new Date(
                          computeDate({
                            days: joursAbonnement,
                            previousDate:
                              userQuery.data.utilisateur.dateexpiration,
                          })
                        ),
                        "dd/MM/yyyy"
                      )}
                    </p>
                  </div>
                )}
                <button
                  disabled={!(joursAbonnement > 0) || userMutation.loading}
                  className="btn btn-primary"
                  onClick={async () => {
                    // Appelez la fonction et obtenez la nouvelle date d'expiration
                    const nouvelleDateExpiration = await addJoursAbonnement(
                      joursAbonnement
                    );
                    setJoursAbonnement(0);
                    // Afficher une alerte avec la nouvelle date d'expiration
                    window.alert(
                      `Nouvelle date d'expiration : ${format(
                        nouvelleDateExpiration,
                        "dd/MM/yyyy"
                      )}`
                    );
                  }}
                >
                  Ajouter
                </button>
              </div>
            </div>
          )}
          {userQuery?.data?.utilisateur?.role !== "ROLE_GC" && (
            <div className="client-buttons">
              <button onClick={() => setOpen2(true)}>
                AVANCEMENT DU DOCUMENT
              </button>
              <button onClick={() => setOpen(true)}>
                VOIR LE PLAN D'ACTION
              </button>
            </div>
          )}
          <div className="client-cards">
            <Card
              title="Actions"
              value={
                planActionRes.data.utilisateur.etablissements.find(
                  ({ id }: any) => entreprise.id === id
                )?.donnees?.documentUnique?.planaction?.actions.length || 0
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;
